import React, { useEffect, useState, useMemo, memo } from 'react'
import styled from '@emotion/styled'
import { Theme, FormControl, InputLabel, MenuItem, Select, Link, Box, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { ReactComponent as CheckListIcon } from '../../../../icons/checklist-response.svg'
import { ReactComponent as ExternalOpen } from '../../../../icons/open-external.svg'
import ReportInaccruateResponseDialogue from '../inaccurate-response-dialogue/report-response-dialogue'
import ReportMissingResponseDialogue from '../missing-response-dialogue/report-missing-response-dialogue'
import TaskListAccordion from './tasklist-accordions'
import FailureModesData from '../../../../shared/types/strategyAPITypes'
import DiagnosisResponseType, { UIInternalDiagnosisStateType } from '../../../../shared/types/diagnosis'
import HelpTooltip from '../../../../shared/components/tooltip-help'
import { useParamQuery } from '../../../../shared/utility'
import { getISSOWPlantNameFromFloc } from '../../../../shared/types/plants'
import { PaddedCard } from '../../../../shared/components/padded-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(0),
      width: '40%',
    },
  })
)

const SelectResponseContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  #DropDownGroup {
    display: flex;
    align-items: center;
    #questionSection {
      svg {
        position: relative;
        top: 0.2rem;
        margin-right: 0.2rem;
      }
      margin-left: 1rem;
      font-size: 0.8rem;
      text-align: center;
      color: #0064CE;
    }
    #questionSection:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  margin-bottom: 2rem;
`

const PromptDiv = styled(Box)`
  opacity: 0.6;
`

const InlinePrompt = styled(Box)`
  #default {
    opacity: 0.6;
  }
`

const StyledLink = styled(Link)`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`

const SubTitle = styled(Box)`
  display: flex;
  flex: 1 0 100%;
`

interface ResponseCardProps {
  diagnosisState: UIInternalDiagnosisStateType;
  stateChangeCallback: (newData: UIInternalDiagnosisStateType) => void;
  failureModesResponses: FailureModesData[]
}

const ResponseCard = memo(({ diagnosisState, stateChangeCallback, failureModesResponses }: ResponseCardProps) => {
  const classes = useStyles()
  const query = useParamQuery()
  const floc = query.get('floc')
  const [responseCandidates, setResponseCandidates] = useState([] as FailureModesData[])
  const [recommendResponse, setRecommendResponse] = useState<string[]>()
  const [displayTaskListURL, setDisplayTasklistURL] = useState('')
  const [taskListCode, setTaskListCode] = useState(diagnosisState.diagnosisResponseType.taskListCode)
  const [displayTaskListCostURL, setDisplayTasklistCostURL] = useState('')
  const [ openReportInaccruateResponseDialogue, setOpenReportInaccruateResponseDialogue ] = useState(false)
  const [ openReportMissingResponseDialogue, setOpenReportMissingResponseDialogue ] = useState(false)

  useMemo(() => {
    if (taskListCode) {
      const splitedCode = taskListCode.split(' /')
      const tasklistURL = `${process.env.REACT_APP_SAP_WEBGUI}?~transaction=*IA07%20%20RC271-PLNNR=${splitedCode[0]};RC271-PLNAL=${splitedCode[1]}`
      setDisplayTasklistURL(tasklistURL)
      const tasklistCostURL = `${process.env.REACT_APP_SAP_WEBGUI}?~transaction=*IA16%20PN_PLNNR-LOW=${splitedCode[0]};PN_PLNAL-LOW=${splitedCode[1]};AUART_P=PMRC;PN_IHAN=X;DYNP_OKCODE=SHOW`
      setDisplayTasklistCostURL(tasklistCostURL)
    }
  }, [taskListCode])

  useEffect(() => {
    if (
      diagnosisState.diagnosisResponseType.failureMode &&
      failureModesResponses
    ) {
      const recommendResponses = failureModesResponses.filter(
        (row) =>
          row.component ===
            diagnosisState.diagnosisResponseType.component &&
          row.failureMode ===
            diagnosisState.diagnosisResponseType.failureMode &&
          row.equipmentType ===
            diagnosisState.diagnosisResponseType.equipmentType
      )

      if (!recommendResponses.length) {
        throw 'Response is not found, this could because the data has been removed since last selected the response'
      }
      const flags = new Set()
      const candidates = failureModesResponses.filter((entry) => {
        if (flags.has(entry.response)) {
          return false
        }
        flags.add(entry.response)
        return true
      })
      setRecommendResponse(recommendResponses.map((r) => r.response))
      setResponseCandidates(candidates)
    } else if (!diagnosisState.diagnosisResponseType.failureMode) {
      setResponseCandidates([])
    }
  }, [
    diagnosisState.diagnosisResponseType.component,
    diagnosisState.diagnosisResponseType.failureMode,
    diagnosisState.diagnosisResponseType.equipmentType,
    diagnosisState.diagnosisResponseType.response,
    failureModesResponses,
  ])

  const handleSelectResponse = (e: any) => {
    const selectedResponse = e.target.value
    const newFailureModeResponse = responseCandidates!.filter(
      (row) => row.response === selectedResponse
    )
    const tasklistcode = newFailureModeResponse[0].taskListCode
    setTaskListCode(tasklistcode)
    const matchedFailureMode = newFailureModeResponse[0]
    stateChangeCallback({
      ...diagnosisState,
      diagnosisResponseType: new DiagnosisResponseType({
        ...diagnosisState.diagnosisResponseType,
        maintenancePlanCode: matchedFailureMode.maintenancePlanCode,
        measuringPointId: matchedFailureMode.measuringPointId,
        response: matchedFailureMode.response,
        masterRelationshipTemplatesToCreate:
          matchedFailureMode.masterRelationshipTemplatesToCreate,
        templateToApply: matchedFailureMode.templateToApply,
      }),
    })
  }

  return (
    <PaddedCard>
      <Typography variant='h2'>
        <CheckListIcon style={{ marginRight: '.5rem', marginBottom: '.5rem', height: '1rem' }} />Maintenance Response
      </Typography>
      <SelectResponseContainer>
        <SubTitle>
          <Typography variant='subtitle1'>
            Select a Maintenance Response
          </Typography>
          <HelpTooltip title="A number of pre-defined maintenance responses are available based on the maintenance strategy for this equipment. Your selection will be used to resource the work order and permit." />
        </SubTitle>
        <Box id="DropDownGroup">
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="select-outlined-label-response"></InputLabel>
            <Select
              labelId="select-outlined-label-failure-response"
              id="select-outlined-failure-response"
              displayEmpty={true}
              renderValue={(value: string) => {
                if (!value) {
                  return <PromptDiv>Click to select a response...</PromptDiv>
                }
                if (recommendResponse && recommendResponse.includes(value)) {
                  return (
                    <InlinePrompt>
                      {value}
                      <Box component='span' id="default"> (Default)</Box>
                    </InlinePrompt>
                  )
                }
                return value
              }}
              value={diagnosisState.diagnosisResponseType.response}
              onChange={handleSelectResponse}
              variant="standard"
            >
              {responseCandidates.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.response}>
                    {recommendResponse?.includes(value.response)
                      ? value.response + ' (Default)'
                      : value.response}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </SelectResponseContainer>
      <Typography variant='subtitle1'>Maintenance Response Details:</Typography>
      {diagnosisState.diagnosisResponseType.response ? (
        <TaskListAccordion
          measuringPointId={
            diagnosisState.diagnosisResponseType.measuringPointId
          }
          permitTemp={
            diagnosisState.diagnosisResponseType.templateToApply!
          }
          isoTemp={
            diagnosisState.diagnosisResponseType
              .masterRelationshipTemplatesToCreate!
          }
          plantId={getISSOWPlantNameFromFloc(floc)}
        />
      ) : (
        <PromptDiv>
          Please select a response to view the response details.
        </PromptDiv>
      )}
      <ReportInaccruateResponseDialogue
        open={openReportInaccruateResponseDialogue}
        onClose={() => setOpenReportInaccruateResponseDialogue(false)}
      />
      <ReportMissingResponseDialogue
        open={openReportMissingResponseDialogue}
        onClose={() => setOpenReportMissingResponseDialogue(false)}
      />
      {diagnosisState.diagnosisResponseType.response && taskListCode && (
        <>
          <StyledLink href={displayTaskListURL} target="_blank">
            Click to view task list details in SAP <ExternalOpen />
          </StyledLink>
          <StyledLink href={displayTaskListCostURL} target="_blank">
            Click to view task list costing details in SAP <ExternalOpen />
          </StyledLink>
        </>
      )}
    </PaddedCard>
  )
})

export default ResponseCard
