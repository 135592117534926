import React, { useState, useRef, ReactNode, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'

import useEquipmentHierarchy from '../../../../services/hooks/useEquipmentHierarchyData'
import useEquipmentMaintenanceData from '../hooks/useEquipmentMaintenanceData'
import { walkFlocTree } from '../../../../shared/utility'
import SingleAccordion from './single_accordion'
import { useWindowSize } from './useWindowResize'
import MaintenanceJob from '../../../../shared/types/maintenanceJob'

interface FlocPanelProps {
  children?: ReactNode;
  currentfloc: string;
  value: number; // current selection, should rename this maybe?
  index: number;
}

const WaitingProgressComp = styled(CircularProgress)`
  margin: 1rem;
`

const ErrorP = styled.p`
  color: #f44336;
  margin: 1rem;
`

const FlocPanel = (state: FlocPanelProps) => {
  const { children, value, index, ...other } = state
  const [flocs, setFLocs] = useState([] as string[])
  const panel1 = useRef<HTMLDivElement | null>(null)
  const panel2 = useRef<HTMLDivElement | null>(null)
  const panel3 = useRef<HTMLDivElement | null>(null)
  const panel4 = useRef<HTMLDivElement | null>(null)
  const panelHolder = useRef<HTMLDivElement | null>(null)
  const [panelDataInOrder, setPanelDataInOrder] = useState<
    | [MaintenanceJob[], MaintenanceJob[], MaintenanceJob[], MaintenanceJob[]]
    | null
  >(null)
  const [currentOpenPanel, setCurrentOpenPanel] = useState('panel1')
  const [contentHeight, setContentHeight] = useState(200)
  const size = useWindowSize()
  // get SA data from the hierarchy
  const {
    data: flocHierarchyData,
  } = useEquipmentHierarchy(
    state.currentfloc
  )

  const {
    data: equipmentContextResponse,
    error: equipmentContextError,
    isLoading: equipmentContextIsLoading,
  } = useEquipmentMaintenanceData(flocs, 12, flocHierarchyData ? true : false)
  // obtain all flocs after hierachy is obtained
  useMemo(() => {
    if (Boolean(flocHierarchyData) && flocHierarchyData?.children) {
      setFLocs(walkFlocTree(flocHierarchyData, []))
    }
  }, [flocHierarchyData])

  useMemo(() => {
    if (equipmentContextResponse) {
      const equipmentContextResponseDeduped = equipmentContextResponse.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.workorderId === thing.workorderId &&
              t.notificationId === thing.notificationId
          )
      )
      const currentResposnes = equipmentContextResponseDeduped
        .filter((row) => row.isCurrent)
        .sort(
          (a, b) =>
            a.StartDateTimeStamp.getTime() - b.StartDateTimeStamp.getTime()
        )
      // set corrective
      const correctiveResponse = currentResposnes.filter(
        (row) => row.isCorrective
      )
      const recurringResponse = currentResposnes.filter(
        (row) => !row.isCorrective && row.isWorkOrder
      )
      const notiResponse = currentResposnes.filter((row) => !row.isWorkOrder)
      const historicalResponse = equipmentContextResponseDeduped
        .filter((row) => !row.isCurrent && !row.isCancelled)
        .sort((a, b) => {
          if (a.closedDateTimeStamp && b.closedDateTimeStamp) {
            return (
              b.closedDateTimeStamp.getTime() - a.closedDateTimeStamp.getTime()
            )
          } else {
            return -1
          }
        })

      setPanelDataInOrder([
        correctiveResponse,
        recurringResponse,
        notiResponse,
        historicalResponse,
      ])
    }
  }, [equipmentContextResponse])

  // Dynamic Heights
  useEffect(() => {
    if (panel1.current && panel2.current && panel3.current && panel4.current) {
      const innerHeight = window.innerHeight
      const topPanel = panel1.current.getBoundingClientRect()
      const totalHeight =
        panel2.current.getBoundingClientRect().height +
        panel3.current.getBoundingClientRect().height +
        panel4.current.getBoundingClientRect().height
      const contentHeight = innerHeight - topPanel.bottom - totalHeight
      setContentHeight(contentHeight)
    }
  }, [
    panel1,
    panel2,
    panel3,
    panel4,
    equipmentContextIsLoading,
    size,
    panelHolder.current?.offsetHeight,
  ])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ overflow: 'hidden' }}
      ref={panelHolder}
    >
      {panelDataInOrder ? (
        <>
          <SingleAccordion
            ref={panel1}
            expand={currentOpenPanel === 'panel1'}
            accordionName="active-pmco"
            panelHeight={contentHeight}
            handlePanalChange={(name: string) => {
              setCurrentOpenPanel('panel1')
            }}
            noDataMessage={
              'We weren’t able to find any active corrective activities against this equipment. If you think this is incorrect, please contact IT support.'
            }
            accordionHeading={'Corrective Work Orders'}
            equipmentContextData={panelDataInOrder[0]}
          />
          <SingleAccordion
            ref={panel2}
            expand={currentOpenPanel === 'panel2'}
            accordionName="active-pmrc"
            panelHeight={contentHeight}
            handlePanalChange={(name: string) => {
              setCurrentOpenPanel('panel2')
            }}
            noDataMessage={
              'We weren’t able to find any active recurring activities against this equipment. If you think this is incorrect, please contact IT support.'
            }
            accordionHeading={'Recurring Work Orders'}
            equipmentContextData={panelDataInOrder[1]}
          />
          <SingleAccordion
            expand={currentOpenPanel === 'panel3'}
            ref={panel3}
            accordionName="active-noti"
            panelHeight={contentHeight}
            handlePanalChange={(name: string) => {
              setCurrentOpenPanel('panel3')
            }}
            noDataMessage={
              'We weren’t able to find any created Z1 Notifications against this equipment. If you think this is incorrect, please contact IT support.'
            }
            accordionHeading={'Unplanned Z1 Notifications'}
            equipmentContextData={panelDataInOrder[2]}
          />
          <SingleAccordion
            expand={currentOpenPanel === 'panel4'}
            ref={panel4}
            accordionName="history"
            panelHeight={contentHeight}
            handlePanalChange={(name: string) => {
              setCurrentOpenPanel('panel4')
            }}
            noDataMessage={
              'We weren’t able to find any maintenance history against this equipment. If you think this is incorrect, please contact IT support.'
            }
            accordionHeading={'Maintenance History'}
            accordionSubheading={'Last 12 Months'}
            equipmentContextData={panelDataInOrder[3]}
          />
        </>
      ) : !equipmentContextError ? (
        <WaitingProgressComp />
      ) : (
        <ErrorP>
          Sorry, An error occured while fetching equipment hitsory data, Error:{' '}
          {(equipmentContextError as [{ message: string }])[0].message}
        </ErrorP>
      )}
    </div>
  )
}

export default FlocPanel
