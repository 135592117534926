import styled from '@emotion/styled'
import { SearchOutlined } from '@ant-design/icons'
import Table from 'antd/lib/table'
import { AutoComplete, Button, Space } from 'antd'
import React, { useRef, useState, useMemo } from 'react'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { Box, Link } from '@mui/material'

import AlertTypeMappingRecord from '../../../../shared/components/alert-metadata'
import { DiagnosedAlert } from '../../../../shared/types/alert'
import useIdentityProfileData from '../../../../services/hooks/useIdentityProfileV2Data'
import { compareAlphaNumeric, getISSOWEnvBasedonFloc } from '../../../../shared/utility'
import { ISSOWStrategyTypes, ParseStrategyDataRules } from '../../../../shared/types/strategyRule'
import WOStatus from './wo-status'

interface ActionedTableProps {
  diagnosedAlerts: DiagnosedAlert[];
}

const TitleIcon = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`

const TooltipSpan = styled(Box)`
  position: relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom: 1px dashed #000; /* little indicater to indicate it's hoverable */
  &:before {
    content: attr(data-text); /* here's the magic */
    position: absolute;

    /* vertically center */
    top: 50%;
    transform: translateY(-50%);

    /* move to right */
    left: 100%;
    margin-left: 10px; /* and add a small left margin */

    /* basic styles */
    width: 200px;
    padding: 0.5rem;
    border-radius: 10px;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 0.7rem;
    display: none;
  }
  &:hover:before {
    display: block;
    z-index: 1000;
  }
`

const ActionedBy = ({ wopid }: { wopid: string }) => {
  const {
    data: identityData,
    error,
  } = useIdentityProfileData(wopid)
  return (
    <Box component="span">
      {identityData && identityData.length && !error
        ? `${identityData[0].givenName} ${identityData[0].lastName} (${wopid})`
        : `${wopid}`}
    </Box>
  )
}

const getIso = (isoCerts: string | null, floc: string) => {
  if (!isoCerts) {
    return (
      <TooltipSpan component="span" data-text="A likely ISSoW connection issue prevented this item from being generated automatically. You can check if it was addressed manually through ISSoW.">
        Not generated
      </TooltipSpan>
    )
  } else {
    return isoCerts.split(',').map((oneIso, index) => (
      <Box key={index}>
        {process.env.REACT_APP_ECC_DECOMMISSIONING === 'false' && (
          <Link underline="none"
            href={`https://${getISSOWEnvBasedonFloc(
              floc
            )}.wde.woodside.com.au/Permit/General/Default.aspx?PermitID=${
              oneIso.split('|')[0]
            }`}
            target="_blank" rel="noreferrer"
          >
            <Box>{oneIso.split('|')[1]}</Box>
          </Link>
        )}
        {process.env.REACT_APP_ECC_DECOMMISSIONING === 'true' && (
          <Box>{oneIso.split('|')[1]}</Box>
        )}
      </Box>
    ))
  }
}

const ActionedTable = ({ diagnosedAlerts }: ActionedTableProps) => {
  const [filterFlocs, setFilterFlocs] = useState<
    {
      text: string;
      value: string;
    }[]
  >()
  const [filterAlertType, setFilterAlertType] = useState<
    {
      text: string;
      value: string;
    }[]
  >()
  const [filterOutcomes, setFilterOutcomes] = useState<
    {
      text: string;
      value: string;
    }[]
  >()
  const [searchState, setSearchState] = useState<{
    searchText: string;
    searchedColumn: string;
  }>()
  const searchInput = useRef<any>(null)

  useMemo(() => {
    if (diagnosedAlerts.length) {
      const flocs = diagnosedAlerts
        .map((row) => {
          return { value: row.floc, text: row.floc }
        })
        .filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        )
      setFilterFlocs(
        flocs.sort((a, b) => compareAlphaNumeric(a.value, b.value))
      )
      const alertTypes = diagnosedAlerts
        .map((row) => {
          return { value: row.alertType, text: row.alertTypeNiceName }
        })
        .filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        )
      setFilterAlertType(
        alertTypes.sort((a, b) => compareAlphaNumeric(a.value, b.value))
      )
      const outcomes = diagnosedAlerts
        .map((row) => {
          return { value: row.failureModeSelectedResponse, text: row.failureModeSelectedResponse }
        })
        .filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        )
      setFilterOutcomes(
        outcomes.sort((a, b) => compareAlphaNumeric(a.value, b.value))
      )
    }
  }, [diagnosedAlerts])

  const getColumnSearchProps = (dataIndex: string, prettyName: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <Box style={{ padding: 8 }}>
        <AutoComplete
          ref={searchInput}
          placeholder={`Search ${prettyName}`}
          onChange={(e: string) => {
            setSelectedKeys(e ? [e] : [])
          }}
          filterOption={true}
          options={diagnosedAlerts
            .map((alert: DiagnosedAlert) => {
              return { value: alert[dataIndex] }
            })
            .filter(
              (thing, index, self) =>
                index === self.findIndex((t) => t.value === thing.value)
            )}
          // use onSearch to customise search result include highlight key words
          // onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </Box>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: DiagnosedAlert) => {
      const res = record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : ''
      return res
    },
    render: (text: string) =>
      searchState && searchState.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchState!.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm()
    setSearchState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchState({ searchText: '', searchedColumn: '' })
  }

  const columns = [
    {
      title: 'Actioned on',
      dataIndex: 'dateResolved',
      key: '1',
      sorter: (a: DiagnosedAlert, b: DiagnosedAlert) =>
        a.originalTimeStampResolvedDate.getTime() -
        b.originalTimeStampResolvedDate.getTime(),
    },
    {
      title: 'FLOC',
      dataIndex: 'floc',
      key: '2',
      width: 150,
      sorter: (a: DiagnosedAlert, b: DiagnosedAlert) => {
        return compareAlphaNumeric(a.floc, b.floc)
      },
      filters: filterFlocs,
      onFilter: (value: string, record: DiagnosedAlert) =>
        record.floc.includes(value),
    },
    {
      title: 'Alert Type',
      dataIndex: 'alertTypeNiceName',
      key: '3',
      filters: filterAlertType,
      width: 150,
      onFilter: (value: string, record: DiagnosedAlert) =>
        record.alertType.includes(value),
      render: (text: string, record: DiagnosedAlert) => (
        <TitleIcon>
          {AlertTypeMappingRecord[record.alertType].displayText}
        </TitleIcon>
      ),
    },
    {
      title: 'Outcome',
      dataIndex: 'failureModeSelectedResponse',
      key: '4',
      filters: filterOutcomes,
      onFilter: (value: string, record: DiagnosedAlert) => 
        record.failureModeSelectedResponse.includes(value),
    },
    {
      title: 'Work Order',
      dataIndex: 'workOrderNumber',
      key: '5',
      render: (text: string, record: DiagnosedAlert) => (
        <>
          { text 
            ? (
              <>
                {process.env.REACT_APP_ECC_DECOMMISSIONING === 'false' && (
                  <Link underline="none"
                    href={`${process.env.REACT_APP_SAP_WEBGUI}?~transaction=*IW33%20CAUFVD-AUFNR=${record.workOrderNumber}`}
                    target="_blank" rel="noreferrer"
                    >
                      <Box component="span">{record.workOrderNumber}</Box>
                  </Link>
                )}
                {process.env.REACT_APP_ECC_DECOMMISSIONING === 'true' && (
                  <Box component="span">{record.workOrderNumber}</Box>
                )}
              </>
            ) 
            : moment().diff(moment(record.originalTimeStampResolvedDate), 'hour') >= 1 
              ? (
                <TooltipSpan component="span" data-text="A likely SAP connection issue prevented this item from being generated automatically. You can check if it was addressed manually through SAP.">
                  Not generated
                </TooltipSpan>
              ) 
              : (
                <TooltipSpan component="span" data-text="This item is in the process of being created. You should receive an email shortly once complete.">
                  Pending...
                </TooltipSpan>
              )}
        </>
      ),
    },
    {
      title: 'Notification',
      dataIndex: 'notificationNumber',
      key: '6',
      render: (text: string, record: DiagnosedAlert) => (
        <>
          {text 
            ? (
              <>
                {process.env.REACT_APP_ECC_DECOMMISSIONING === 'false' ? (
                  <Link underline="none"
                    href={`${process.env.REACT_APP_SAP_WEBGUI}?~transaction=*IW33%20CAUFVD-AUFNR=${record.notificationNumber}`}
                    target="_blank" rel="noreferrer"
                  >
                    <Box component="span">{record.notificationNumber}</Box>
                  </Link>
                ) : (
                  <Box component="span">{record.notificationNumber}</Box>
                )}
              </>
            ) 
            : moment().diff(moment(record.originalTimeStampResolvedDate), 'hour') >= 1 
              ? (
                <TooltipSpan component="span" data-text="A likely SAP connection issue prevented this item from being generated automatically. You can check if it was addressed manually through SAP.">
                  Not generated
                </TooltipSpan>
              ) 
              : (
                <TooltipSpan component="span" data-text="This item is in the process of being created. You should receive an email shortly once complete.">
                  Pending...
                </TooltipSpan>
              )
          }
        </>
      ),
    },
    {
      title: 'Permit',
      dataIndex: 'permitName',
      key: '7',
      render: (text: string, record: DiagnosedAlert) => (
        <>
          {text ? (
            <>
              {process.env.REACT_APP_ECC_DECOMMISSIONING === 'false' && (
                <Link underline="none"
                  href={`https://${getISSOWEnvBasedonFloc(
                    record.floc
                  )}.wde.woodside.com.au/Permit/General/Default.aspx?PermitID=${
                    record.permitID
                  }`}
                    target="_blank" rel="noreferrer"
                  >
                  <Box component="span">{record.permitName}</Box>
                </Link> 
              )}
              {process.env.REACT_APP_ECC_DECOMMISSIONING === 'true' && (
                <Box component="span">{record.permitName}</Box>
              )}
            </>  
          ) : (
            <>
              {record.templateToApply &&
              ParseStrategyDataRules(record.templateToApply) ===
                ISSOWStrategyTypes.LRP ? (
                <Box component="span">NA - Under LRP</Box>
              ) : (
                <TooltipSpan component="span" data-text="A likely ISSoW connection issue prevented this item from being generated automatically. You can check if it was addressed manually through ISSoW.">
                  Not generated
                </TooltipSpan>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: 'Isolation',
      dataIndex: 'isolationCerts',
      key: '8',
      render: (text: string, record: DiagnosedAlert) =>
        record.ownIsolation ? (
          <Box component="span">NA - Own isolations</Box>
        ) : (
          getIso(text, record.floc)
        ),
    },
    {
      title: 'Actioned By',
      dataIndex: 'resolvedBy',
      key: '9',
      ...getColumnSearchProps('resolvedBy', 'Wop ID'),
      render: (text: string, record: DiagnosedAlert) => (
        <ActionedBy wopid={text}></ActionedBy>
      ),
    },
    {
      title: 'WO Sys Status',
      dataIndex: 'WOStatus',
      key: '10',
      render: (text: string, record: DiagnosedAlert) => (
        <WOStatus
          floc={record.floc}
          WONumber={record.workOrderNumber}
        ></WOStatus>
      ),
    },
  ]

  return (
    <Table
      data-testid="diagnoseTable"
      rowKey={(record) => {
        if (!record.id)
          record.id = record.originalTimeStampResolvedDate.toString()
        return record.id
      }}
      columns={columns}
      dataSource={diagnosedAlerts}
      pagination={{ hideOnSinglePage: true, size: 'small' }}
    />
  )
}

export default ActionedTable
