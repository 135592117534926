import { useQuery } from 'react-query'
import {
  getMultiEquipmentMaintenanceQuery,
} from '../../../../services/graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../../../shared/auth'
import MaintenanceJob, {
  convertToMaintenanceJob,
  MaintenanceDataResponse,
} from '../../../../shared/types/maintenanceJob'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'

export async function fetchEquipmentMaintenanceData(
  msalInstance: IPublicClientApplication,
  flocs: string[],
  lastXMonth: number
): Promise<MaintenanceJob[]> {
  const transaction = apm.startTransaction(
    'useEquipmentMaintenanceData',
    'EQ360'
  )!
  const httpSpan = transaction.startSpan(
    `POST ${process.env.REACT_APP_EQ360_HOST!}`,
    'app'
  )!
  transaction.addLabels({
    flocs: flocs.toString(),
    lastXMonth: lastXMonth,
  })
  return new Promise((resolve, reject) =>
    Eq360GraphFetch(
      msalInstance,
      process.env.REACT_APP_EQ360_ID!,
      fetch,
      process.env.REACT_APP_EQ360_HOST!,
      getMultiEquipmentMaintenanceQuery(flocs)
    )
      .then((results) => {
        let resultCombined = []
        if (results && results['q0']) {
          const listOfResponse = flocs.map((floc, index) => {
            return results[`q${index}`].map((r: MaintenanceDataResponse) => {
              return { ...r, floc: floc }
            })
          })
          resultCombined = [].concat.apply([], listOfResponse)
          
          resolve(
            convertToMaintenanceJob(
              resultCombined as MaintenanceDataResponse[],
              lastXMonth
            )
          )
        } else {
          apm.captureError(
            new Error(`'Maintenance data failed for flocs ${flocs.toString()}'`)
          )
          reject('Maintenance data is currently not available.')
        }
      })
      .catch((e) => {
        apm.captureError(
          new Error(`Equipment Maintenance data fetch failed with status ${e}`)
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  )
}

const useEquipmentMaintenanceData = (
  flocs: string[],
  lastXMonth: number,
  enabled: boolean
) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['EquipmentMaintenanceHistoryData', flocs, lastXMonth],
    () => fetchEquipmentMaintenanceData(msalInstance, flocs, lastXMonth),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 60 * 1000 * 60, // 60min stale time,
      enabled: Boolean(flocs.length >= 1),
    }
  )
}
export default useEquipmentMaintenanceData
