import { AuthenticationResult } from '@azure/msal-browser'
import axios from 'axios'
import { useQuery } from 'react-query'

import { useUserTokenContext } from '../../../../shared/providers/user-token-provider'

export interface ESDTSTagResponse {
  cycleTag: string;
  floc: string;
  n2Tag: string;
  n2_thresld: number;
  tempTag: string;
  temp_thresld: number;
}

async function fetchESDTSTag(
  token: AuthenticationResult,
  floc: string
): Promise<ESDTSTagResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_CBM_ENDPOINT + '/esdTSTag?' + `floc=${floc}`, {
        headers: {
          Authorization: `Bearer ${token?.accessToken}`,
        },
      })
      .then((response) => {
        if (response) {
          resolve(response.data as ESDTSTagResponse)
        } else {
          reject('Floc not found')
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const useESDTSTag = (floc?: string) => {
  const token = useUserTokenContext()
  return useQuery(
    ['use ESD TS TAG', floc],
    () => fetchESDTSTag(token!, floc!),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 15 * 1000 * 60, // 15min stale time,
      enabled: Boolean(floc) && Boolean(token),
    }
  )
}

export default useESDTSTag
