import { useQuery } from 'react-query'
import { getEquipmentHistoryQuerySmall } from '../../../../../services/graphql/EQ360Queries'

import { Eq360GraphFetch } from '../../../../../shared/auth'
import {
  MaintenanceDataResponse,
} from '../../../../../shared/types/maintenanceJob'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'

export async function fetchEquipmentMaintenanceData(
  msalInstance: IPublicClientApplication,
  floc: string,
  WONumber: string
): Promise<MaintenanceDataResponse | null> {
  const transaction = apm.startTransaction('ussCBMWOStatus', 'EQ360')!
  const httpSpan = transaction.startSpan(
    `POST ${process.env.REACT_APP_EQ360_HOST!}`,
    'app'
  )!
  transaction.addLabels({
    floc: floc.toString(),
    WONumber: WONumber,
  })
  return new Promise((resolve, reject) =>
    Eq360GraphFetch(
      msalInstance,
      process.env.REACT_APP_EQ360_ID!,
      fetch,
      process.env.REACT_APP_EQ360_HOST!,
      getEquipmentHistoryQuerySmall(floc)
    )
      .then((results) => {
        if (results && results['getMaintenanceData']) {
          const listOfResponse = results['getMaintenanceData'].filter(
            (response: MaintenanceDataResponse, index: number) => {
              return response.workOrderNumber === WONumber
            }
          )
          if (listOfResponse.length) {
            resolve(listOfResponse[0])
          } else {
            resolve(null)
          }
        } else {
          apm.captureError(
            new Error(`'CBM WO Order data failed for flocs ${floc}'`)
          )
          reject('CBM WO Order data is currently not available.')
        }
      })
      .catch((e) => {
        apm.captureError(
          new Error(
            `CBM WO Order, Equipment Maintenance data fetch failed with status ${e}`
          )
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  )
}

const useCBMWOStatus = (floc: string, WONumber?: string) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['CBMWOStatus', floc, WONumber],
    () => fetchEquipmentMaintenanceData(msalInstance, floc, WONumber!),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 60 * 1000 * 60, // 60min stale time,
      enabled: Boolean(floc) && Boolean(WONumber),
    }
  )
}
export default useCBMWOStatus
