import React from 'react'
import axios from 'axios'
import { FormControl, Button, Dialog, IconButton, TextField, Theme, Typography, CircularProgress, Box } from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'
import { useMutation, useQueryClient } from 'react-query'

import { ReactComponent as WarningIcon } from '../../../../icons/exclamation-triangle.svg'
import InaccurateResponseCheckboxesGroup from './checkboxgroup'
import AlertNotification from '../../../../shared/components/alert-notification'
import { useUserTokenContext } from '../../../../shared/providers/user-token-provider'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    fontSize: '13px',
    fontWeight: 500,
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

const TextFieldStyled = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
}))(TextField)

const Hint = styled(Box)`
  margin-left: 1rem;
  font-size: 1rem;
  color: #ff9800;
  margin-top: 2rem;
  margin-bottom: 2rem;
  svg {
    margin-right: 0.5rem;
  }
`

const WaitingProgressComp = styled(CircularProgress)`
  margin-right: 0.5rem;
`

export interface ReportInaccruateResponseDialogueProps {
  open: boolean;
  onClose: () => void;
}

const ReportInaccruateResponseDialogue = (
  props: ReportInaccruateResponseDialogueProps
) => {
  const queryClient = useQueryClient()
  const tokenContext = useUserTokenContext()
  const [submitting, setSubmitting] = React.useState(false)
  const [submitError, setSubmitError] = React.useState('')
  const [submitResponse, setSubmitResponse] = React.useState(null)
  const [formValue, setFormValue] = React.useState({
    operationsErrorObserved: false,
    materialsErrorObserved: false,
    permitErrorObserved: false,
    description: '',
  })

  const handleCheckBox = (values: any) => {
    setFormValue({ ...formValue, ...values })
  }
  const handleCommentInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormValue({
      ...formValue,
      description: event.currentTarget.value as string,
    })
  }
  const {
    mutate: mutatePostDiagnosis,
  } = useMutation(
    (inputToAPICall: any) => {
      return axios(
        process.env.REACT_APP_CBM_ENDPOINT! + `something/${inputToAPICall.key}`,
        {
          method: 'POST',
          data: { ...inputToAPICall.body },
          headers: {
            Authorization: `Bearer ${tokenContext!.accessToken}`,
          },
        }
      )
    },
    {
      onSuccess: async (resp: any) => {
        await queryClient.invalidateQueries('Active')
        queryClient.invalidateQueries('Diagnosed')
        setSubmitResponse(resp)
        setSubmitting(false)
      },
      onError: (error: Error) => {
        setSubmitting(false)
        setSubmitError(
          'Sorry, An error happend, Please try again later. Error Message: ' +
            error.message
        )
      },
      onSettled: () => {},
    }
  )
  const handleSubmit = () => {
    setSubmitting(true)
    mutatePostDiagnosis({ body: formValue, key: '????' })
  }

  return (
    <>
      {submitError && (
        <AlertNotification
          type="error"
          message={submitError}
          onHide={() => {
            setSubmitError('')
          }}
        />
      )}
      <DialogStyled
        open={props.open && !submitResponse}
        aria-labelledby="form-dialog-title"
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return
          }
          props.onClose()
          setFormValue({
            operationsErrorObserved: false,
            materialsErrorObserved: false,
            permitErrorObserved: false,
            description: '',
          })
        }}
      >
        <DialogTitle
          onClose={() => {
            props.onClose()
            setFormValue({
              operationsErrorObserved: false,
              materialsErrorObserved: false,
              permitErrorObserved: false,
              description: '',
            })
          }}
          id="form-dialog-title"
        >
          Report Inaccurate Response
        </DialogTitle>
        <FormControl variant="standard">
          <DialogContent>
            Where did you find inaccurate information?
          </DialogContent>
          <InaccurateResponseCheckboxesGroup
            checkBoxCallback={handleCheckBox}
          />
          <DialogContent>What inaccuracies did you find?</DialogContent>
          <TextFieldStyled
            autoFocus
            size="small"
            multiline={true}
            minRows={3}
            id="name"
            label=""
            type="text"
            fullWidth
            onChange={handleCommentInput}
            inputProps={{ maxLength: 1500 }}
          />
        </FormControl>
        <Hint>
          <WarningIcon style={{ height: '0.8rem' }} />
          Submitting this report will cancel this maintenance request. You won’t
          be able to proceed further with CBM. Instructions will follow on the
          next screen.
        </Hint>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleSubmit()
            }}
            color="secondary"
            disabled={formValue.description.length < 5 || true}
          >
            {submitting && <WaitingProgressComp size={'1rem'} />}
            Report {'&'} Cancel Response
          </Button>
        </DialogActions>
      </DialogStyled>
    </>
  )
}

export default ReportInaccruateResponseDialogue
