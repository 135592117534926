import { useMsal } from '@azure/msal-react'
import React, { createContext, ReactNode, useContext } from 'react'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FuzzyMatching = require('fuzzy-matching')

import { fetchEquipmentHierarchyByFloc } from '../../services/hooks/useEquipmentHierarchyData'
import EquipmentOverview from '../types/getOverviewResponse'
import { getEq360PlantFromFloc } from '../types/plants'
import { compareAlphaNumeric } from '../utility'

export const EquipmentOverviewContext = React.createContext<
  EquipmentOverview | undefined
>(undefined)
type FlocContextType = {
  getSurroundingEquipments(
    floc: string,
    description: string
  ): Promise<string[]>;
};

const FlocContext = createContext({
  getSurroundingEquipments: (f, d) => {},
} as FlocContextType)

export function useFlocContext() {
  return useContext(FlocContext)
}

type PropsType = {
  children: ReactNode;
};

// this provider provide floc level context data such as surrounding equipments for reusability
// can change it be stateful - so that other consumer don't have to initiate the APIs and wait for response.
// and merge with Ex360 context
export default function FlocContextProvider({ children }: PropsType) {
  const { instance: msalInstance } = useMsal()

  const getSurroundingEquipments = async (
    currentFloc: string,
    description: string
  ): Promise<string[]> => {
    const flocHierarchyData = await fetchEquipmentHierarchyByFloc(
      msalInstance,
      currentFloc
    )
    let asset = undefined
    if (flocHierarchyData.parentFloc.startsWith('AU')) {
      // if it has plant knowledge
      // Pump's parent equipment tag is NOT plant aware, hence need to get plant knowledge from floc
      asset = getEq360PlantFromFloc(flocHierarchyData.parentFloc)
    }
    const parentFlocData = await fetchEquipmentHierarchyByFloc(
      msalInstance,
      flocHierarchyData.parentEquipmentTagname,
      asset
    )
    if (
      parentFlocData &&
      parentFlocData.children &&
      parentFlocData.children.length
    ) {
      const fm = new FuzzyMatching([description])

      const flocs = parentFlocData.children.filter((row) => {
        return fm.get(row.attributes.service).distance > 0.95
      })

      flocs.sort((a, b) => {
        return compareAlphaNumeric(a.floc, b.floc)
      })
      return flocs.map((row) => row.floc)
    } else {
      return []
    }
  }

  const contextValue = { getSurroundingEquipments }
  return (
    <FlocContext.Provider value={contextValue}>{children}</FlocContext.Provider>
  )
}
