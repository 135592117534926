import React from 'react'
import { FormControl, Select, MenuItem, Button, Dialog, IconButton, TextField, Theme, Typography, SelectChangeEvent, Box } from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'

import FeedbackCheckboxesGroup from './checkboxgroup'
import { ReactComponent as HintIcon } from '../../../icons/exclamation-triangle.svg'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
    },
    body1: {
      fontSize: '25px',
    },
    questionText: {
      fontSize: '20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const OptionalSpan = styled.span`
  font-weight: 400;
  opacity: 0.6;
  font-size: 11px;
`

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '5px',
    marginTop: '15px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

const TextFieldStyled = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}))(TextField)

export interface DialogueProps {
  handleClose: () => void;
  handleSubmit: ({}: any) => void;
  open: boolean;
}

export interface DialogueState {
  handleClose: () => void;
  handleSubmit: ({}: any) => void;
  open: boolean;
  feedbackReasonIndex: number;
  comments: string;
  otherReasons: string;
  checkedItems: string[];
}

export const FeedbackOptions = [
  'Select Reason',
  'No defect found on inspection',
  'Handled outside of CBM',
  'Alert retriggered by existing defect',
  'Too early to act on defect',
  'Other',
]
// MUST CHANGE BELOW TO CORRECTLY TRIGGER OTHER TEXTBOX
// const OTHERSINDEX = 5;
class CancelDialogue extends React.Component<DialogueProps, DialogueState> {
  constructor(props: DialogueProps) {
    super(props)
    this.state = {
      handleClose: props.handleClose,
      handleSubmit: props.handleSubmit,
      open: props.open,
      feedbackReasonIndex: 0,
      comments: '',
      otherReasons: '',
      checkedItems: [''],
    }
  }

  handleReasonsInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      ...this.state,
      otherReasons: event.target.value as string,
    })
  }

  handleCommentInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      ...this.state,
      comments: event.target.value as string,
    })
  }

  handleChange = (event: SelectChangeEvent<number>) => {
    this.setState({
      ...this.state,
      feedbackReasonIndex: event.target.value as number,
      otherReasons: '', // clear other reasons
    })
  }

  handdleCheckbox = (values: any) => {
    this.setState({
      ...this.state,
      checkedItems: values,
    })
  }

  // return true if valid
  handleFormValidation = (): boolean => {
    if (this.state.feedbackReasonIndex === 0) {
      return false
    }
    if (this.state.comments.trim().length <= 1) {
      return false
    }
    return true
  }

  HintText = styled(Box)`
    margin: 1em;
    color: #0064CE;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2rem;
  `

  render() {
    return (
      <DialogStyled
        open={this.state.open}
        onClose={this.state.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle onClose={this.state.handleClose} id="form-dialog-title">
          Remove Alert
        </DialogTitle>
        <DialogContent>Why are you removing this alert?</DialogContent>
        <form>
          <FormControl fullWidth={true} variant="standard">
            <div style={{ marginLeft: '1em', marginRight: '1em' }}>
              <Select
                labelId="feedback-select-label"
                id="feedback-select"
                value={this.state.feedbackReasonIndex}
                onChange={this.handleChange}
                variant="standard"
              >
                {FeedbackOptions.map((value, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {value}
                    </MenuItem>
                  )
                })}
              </Select>
            </div>
            <DialogContent>
              Additional comments:{' '}
            </DialogContent>
            <TextFieldStyled
              autoFocus
              size="small"
              multiline={true}
              minRows={4}
              id="name"
              label=""
              type="text"
              fullWidth
              onChange={this.handleCommentInput}
              inputProps={{ maxLength: 1500 }}
            />
            <DialogContent>
              Were you aware of any of the following conditions?{' '}
              <OptionalSpan>(Optional)</OptionalSpan>
            </DialogContent>
            <FeedbackCheckboxesGroup checkBoxCallback={this.handdleCheckbox} />
          </FormControl>
        </form>
        <this.HintText>
          <HintIcon style={{ height: '0.7rem', marginRight: '2px' }} />
          Alerts will be re-triggered if the equipment condition degrades any
          further.
        </this.HintText>
        <DialogActions>
          <Button
            variant="contained"
            onClick={(e) => {
              this.state.handleSubmit(this.state)
            }}
            color="secondary"
            disabled={!this.handleFormValidation()}
          >
            Remove Alert
          </Button>
        </DialogActions>
      </DialogStyled>
    )
  }
}

export default CancelDialogue
