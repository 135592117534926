import styled from '@emotion/styled'
import { CircularProgress, Box, Select } from '@mui/material'
import React from 'react'
import { LineChart, CartesianGrid, XAxis, YAxis, Line, Legend, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts'
import { makeStyles } from '@mui/styles'

import { GlobalDateFormatter, GlobalTimeFormatter, useParamQuery } from '../../../../shared/utility'
import useTSData from '../hooks/useTSData'
import { AlertTypes } from '../../../../shared/types/alert'
import useESDTSTag from '../hooks/useESDTSTag'

export interface ESDGraphProps {
  alertType:
    | AlertTypes.ESDCycleModel
    | AlertTypes.ESDN2Model
    | AlertTypes.ESDTempModel;
}

const AlertFriendlyName = {
  ESDCycleModel: 'ESD Weekly Recharge Cycles',
  ESDN2Model: 'ESD N2 Pressure',
  ESDTempModel: 'ESD Average Temperature',
}

const useStyles = makeStyles({
  chartWrapper: {
    position: 'relative',
    marginTop: '1rem',
    alignSelf: 'center',
  },
  chartOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    marginTop: '1rem',
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0.5rem',
    margin: '0.5rem 0',
  },
  timeRangeLabel: {
    color: '#666666',
    marginRight: '1rem',
  },
  modalContainer: {
    position: 'relative',
    display: 'inline-block',
    marginTop: '1rem',
  },
})

const CustomThresholdLabel = styled.span`
  font-style: italic;
  opacity: 0.6;
  width: 100%;
`

type TimeRange = {
  displayLabel: string;
  aggregate: number;
  aggregateUnit: 'DAY' | 'HOUR' | 'MIN';
  timeRangeID: number;
  startTimestamp: number;
  endTimestamp: number;
};

const StyledDiv = styled(Box)`
  padding: 1.2rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const NoData = styled(Box)`
  opacity: 0.6;
`

const today = new Date()
const timeRangeCandidates: TimeRange[] = [
  {
    timeRangeID: 1,
    displayLabel: '24 Hour',
    aggregate: 30,
    aggregateUnit: 'MIN',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setDate(today.getDate() - 1) / 1000
    ),
  },
  {
    timeRangeID: 2,
    displayLabel: '1 Week',
    aggregate: 120,
    aggregateUnit: 'MIN',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setDate(today.getDate() - 7) / 1000
    ),
  },
  {
    timeRangeID: 3,
    displayLabel: '1 Month',
    aggregate: 120,
    aggregateUnit: 'MIN',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setMonth(today.getMonth() - 1) / 1000
    ),
  },
  {
    timeRangeID: 4,
    displayLabel: '3 Month',
    aggregate: 1,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setMonth(today.getMonth() - 3) / 1000
    ),
  },
  {
    timeRangeID: 5,
    displayLabel: '6 Month',
    aggregate: 1,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setMonth(today.getMonth() - 6) / 1000
    ),
  },
  {
    timeRangeID: 6,
    displayLabel: '12 Month',
    aggregate: 1,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setMonth(today.getMonth() - 12) / 1000
    ),
  },
  {
    timeRangeID: 7,
    displayLabel: '24 Month',
    aggregate: 1,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setMonth(today.getMonth() - 24) / 1000
    ),
  },
]
const ESDGraph = React.memo((props: ESDGraphProps) => {
  const classes = useStyles()
  const mySearchQuery = useParamQuery()
  const [timeRange, setTimeRange] = React.useState<TimeRange>(
    timeRangeCandidates[1]
  )
  const [tsTagName, setTSTagName] = React.useState('')
  const [tagUnit, setTagUnit] = React.useState('')
  const floc = mySearchQuery.get('floc')!
  
  const {
    data: esdTagData,
    isLoading: esdTagLoading,
  } = useESDTSTag(floc)
  const {
    data: tsData,
    isLoading: TSDataLoading,
  } = useTSData(
    tsTagName,
    timeRange.aggregateUnit,
    timeRange.aggregate,
    Math.floor(timeRange.startTimestamp),
    Math.floor(timeRange.endTimestamp),
    floc
  )

  /**
   * Initialise the chart data with start and end timestamp so the grid gets rendered even if no data is being loaded yet
   */
  const [chartData, setChartData] = React.useState<any[]>([{
    date: timeRange.startTimestamp,
  }, {
    date: timeRange.endTimestamp,
  }])
  const noData = () => {
    return !esdTagLoading && !TSDataLoading && (!tsData || !tsData.length)
  }

  // change below to set tsDataName
  React.useMemo(() => {
    if (esdTagData) {
      if (props.alertType === AlertTypes.ESDTempModel && esdTagData.tempTag) {
        setTSTagName(esdTagData.tempTag)
        setTagUnit('°C')
      } else if (
        props.alertType === AlertTypes.ESDCycleModel &&
        esdTagData.cycleTag
      ) {
        setTagUnit('charge/week')
        setTSTagName(esdTagData.cycleTag)
      } else if (
        props.alertType === AlertTypes.ESDN2Model &&
        esdTagData.n2Tag
      ) {
        setTSTagName(esdTagData.n2Tag)
        setTagUnit('Mpa')
      }
    }
  }, [esdTagData])

  /**
   * Used by the chart X-axis to format the timestamp into a human readable date
   * @param timestamp
   */
  function formatDate(timestamp: number) {
    return `${GlobalDateFormatter.format(
      timestamp
    )} ${GlobalTimeFormatter.format(timestamp)}`
  }
  React.useMemo(() => {
    if (tsData && tsData.length) {
      const currentTimeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000
      const newData = tsData.map((x) => ({
        date: new Date(x.time).getTime() - currentTimeZoneOffset,
        [props.alertType]: x.value.toPrecision(5),
      }))
      setChartData(newData)
    }
  }, [tsData])

  return (
    <StyledDiv>
      {noData() && (
        <NoData>
          Could not obtain data, please let our CBM team members know or contact
          IT Support. we can help investigate further.
        </NoData>
      )}
      <div hidden={noData()}>
        <div className={classes.chartWrapper}>
          {(TSDataLoading || esdTagLoading) && (
            <div className={classes.chartOverlay}>
              <CircularProgress />
            </div>
          )}
          {chartData.length && (
            <ResponsiveContainer width="99%" aspect={5} minWidth="50vw">
              <LineChart
                data={chartData}
                width={100}
                margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
              >
                <CartesianGrid
                  fill="#FCFCFC"
                  vertical={false}
                  strokeDasharray="1 3"
                />
                <XAxis
                  type="number"
                  dataKey="date"
                  tickMargin={10}
                  reversed={false}
                  minTickGap={20}
                  tick={{ fontSize: 11 }} // dx: 10 if we want to move the tick text to the right
                  tickFormatter={formatDate}
                  domain={[
                    timeRange.startTimestamp * 1000,
                    timeRange.endTimestamp * 1000,
                  ]}
                  axisLine={{ stroke: '#C8C8C8' }}
                />
                <YAxis
                  yAxisId="right"
                  type="number"
                  tick={{ fontSize: 11 }}
                  tickCount={5}
                  orientation="right"
                  domain={['auto', 'auto']}
                  axisLine={false}
                  tickLine={false}
                />
                <YAxis
                  yAxisId="left"
                  tick={false}
                  orientation="left"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    dy: 30,
                    offset: 40,
                    value: `Value (${tagUnit})`,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 13,
                  }}
                />
                <Tooltip
                  labelFormatter={(val: number) => formatDate(val)}
                  formatter={(val: number) => val}
                />
                <Line
                  isAnimationActive={false}
                  name={AlertFriendlyName[props.alertType.toString()]}
                  unit=""
                  yAxisId="right"
                  type="linear"
                  dot={false}
                  dataKey={props.alertType}
                  stroke={'#2C98F0'}
                  strokeWidth={2}
                />
                <Legend
                  wrapperStyle={{
                    paddingTop: '10px',
                  }}
                />
                {props.alertType === AlertTypes.ESDN2Model && (
                  <ReferenceLine
                    yAxisId="right"
                    y={esdTagData?.n2_thresld}
                    alwaysShow={true}
                    label={({ viewBox }) => {
                      return (
                        <g>
                          <foreignObject
                            x={viewBox.x}
                            y={viewBox.y}
                            width={200}
                            height={100}
                          >
                            <CustomThresholdLabel>
                              threshold (
                              {esdTagData?.n2_thresld + ' ' + tagUnit})
                            </CustomThresholdLabel>
                          </foreignObject>
                        </g>
                      )
                    }}
                    stroke="red"
                    strokeDasharray="3 3"
                  />
                )}
                {props.alertType === AlertTypes.ESDTempModel && (
                  <ReferenceLine
                    yAxisId="right"
                    y={esdTagData?.temp_thresld}
                    alwaysShow={true}
                    label={({ viewBox }) => {
                      return (
                        <g>
                          <foreignObject
                            x={viewBox.x}
                            y={viewBox.y}
                            width={200}
                            height={100}
                          >
                            <CustomThresholdLabel>
                              threshold (
                              {esdTagData?.temp_thresld + ' ' + tagUnit})
                            </CustomThresholdLabel>
                          </foreignObject>
                        </g>
                      )
                    }}
                    stroke="red"
                    strokeDasharray="3 3"
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <Box className={classes.controlsContainer}>
          <div>
            <label className={classes.timeRangeLabel}>Time Range:</label>
            <Select
              style={{ width: '7rem' }}
              native
              defaultValue={timeRangeCandidates[1].timeRangeID}
              onChange={(e) => {
                const selected = parseInt(e.target.value as string)
                const timeRange = timeRangeCandidates.find(
                  (x) => x.timeRangeID === selected
                )!
                setTimeRange(timeRange)
                e.preventDefault()
              }}
              variant="standard"
            >
              {timeRangeCandidates.map((x) => (
                <option value={x.timeRangeID} key={x.timeRangeID}>
                  {x.displayLabel}
                </option>
              ))}
            </Select>
          </div>
        </Box>
      </div>
    </StyledDiv>
  )
})

export default ESDGraph
