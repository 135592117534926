import React from 'react'
import { Card } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '&.MuiPaper-root': {
        padding: '1rem',
        width: '100%',
    }
  }
}))

export const PaddedCard = (props: any) => {
  const classes = useStyles()
  return (
    // eslint-disable-next-line react/prop-types
    <Card className={classes.root}>{props.children}</Card>
  )
}