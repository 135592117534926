import { AnomalyOutputType } from '../../../../shared/types/getEquipmentResponse'

export interface VibrationSummaryGraphState {
  enabledAnomalyOutputType: AnomalyOutputType[];
  lastValues: { type: AnomalyOutputType; value: number }[];
}

export function setGraphStateLastValue(
  graphState: VibrationSummaryGraphState,
  type: AnomalyOutputType,
  value: number
) {
  const newState = { ...graphState }
  const currentIndex = graphState.lastValues.findIndex((a) => a.type === type)
  if (currentIndex < 0) {
    // add this type
    newState.lastValues.push({ type: type, value: value })
  } else {
    // modify value
    newState.lastValues[currentIndex].value = value
  }
  return newState
}

export function getSeverityScoreFromState(
  graphState: VibrationSummaryGraphState,
  type: AnomalyOutputType
) {
  const score = graphState.lastValues.find((a) => a.type === type)
  if (score) {
    return score.value
  } else {
    return null
  }
}
export function changeGraphAnomalyState(
  graphState: VibrationSummaryGraphState,
  type: AnomalyOutputType,
  state: boolean
) {
  const newState = { ...graphState }
  if (state) {
    if (!isAnomalyOutputEnabled(newState, type)) {
      newState.enabledAnomalyOutputType.push(type)
    }
  } else {
    // disbale it
    newState.enabledAnomalyOutputType =
      newState.enabledAnomalyOutputType.filter((t) => t !== type)
  }
  return newState
}

export function isAnomalyOutputEnabled(
  graphState: VibrationSummaryGraphState,
  type: AnomalyOutputType
) {
  return graphState.enabledAnomalyOutputType.includes(type)
}
