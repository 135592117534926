import { useQuery } from 'react-query'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { Eq360GraphFetch } from '../../shared/auth'
import { getEquipmentOPAM } from '../graphql/EQ360Queries'
import GetEquipment from '../../shared/types/getEquipmentResponse'
import { apm } from '@elastic/apm-rum'

export async function fetchEquipmentOpamData(
  msalInstance: IPublicClientApplication,
  floc: string,
  lastXWeek: number
): Promise<GetEquipment> {
  const transaction = apm.startTransaction('usePAMData', 'EQ360')!
  const httpSpan = transaction.startSpan(
    `POST ${process.env.REACT_APP_EQ360_HOST!}`,
    'app'
  )!
  transaction.addLabels({
    floc,
  })

  return new Promise((resolve, reject) =>
    Eq360GraphFetch(
      msalInstance,
      process.env.REACT_APP_EQ360_ID!,
      fetch,
      process.env.REACT_APP_EQ360_HOST!,
      getEquipmentOPAM(
        floc,
        new Date(new Date().setDate(new Date().getDate() - lastXWeek * 7)),
        new Date()
      )
    )
      .then((response) => {
        if (response.getEquipment) {
          resolve(new GetEquipment(response.getEquipment))
        } else {
          apm.captureError(
            new Error(`get Equipment is not in the response for floc ${floc}.`)
          )
          reject('get Equipment is not in the response.')
        }
      })
      .catch((e) => {
        let errorMessage = e
        // handle eq360 custom error
        if (e.length) {
          errorMessage = e[0].message
        }
        apm.captureError(
          new Error(`Opam data fetch failed with status ${errorMessage}`)
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  )
}

const useEquipmentOPAMData = (
  floc: string,
  lastXWeek: number,
  enabled: boolean
) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['EquipmentOPAM', floc, lastXWeek],
    () => fetchEquipmentOpamData(msalInstance, floc, lastXWeek),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 60 * 1000 * 60, // 60min stale time,
      enabled: enabled,
    }
  )
}
export default useEquipmentOPAMData
