import React, { useEffect } from 'react'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { ReactComponent as PermitIcon } from '../../../../icons/permit.svg'
import { ReactComponent as PartsIcon } from '../../../../icons/parts.svg'
import { ReactComponent as PercedureIcon } from '../../../../icons/list.svg'
import TaskListOpsTable from './tasklist-ops-table'
import TaskListPartsTable from './tasklist-parts-table'
import PermitData from './tasklist-permit'
import useGetMaintenancePlanData from './hooks/useGetMaintenancePlan'
import { parseToOperationTableData, parseToPartsTableData } from '../../../../shared/types/tasklistTypes'
import AlertNotification from '../../../../shared/components/alert-notification'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 500,
    },
  } as any)
)

export interface TaskListAccordionProps {
  measuringPointId: string;
  permitTemp: string;
  isoTemp: string;
  plantId: 'AU01' | 'AU21';
}

export default function TaskListAccordion({
  measuringPointId,
  permitTemp,
  isoTemp,
  plantId,
}: TaskListAccordionProps) {
  const classes = useStyles()

  const {
    data: maintenanceItemResponseObj,
    error: maintenanecItemResponseError,
    isLoading: loadingMaintenanceItemResponse,
  } = useGetMaintenancePlanData(measuringPointId)
  const [maintenanceResponseError, setMaintenanceResponseError] =
    React.useState('')

  useEffect(() => {
    if (maintenanecItemResponseError) {
      setMaintenanceResponseError(
        ('Error In Getting Maintenance Item Reaponse from Mulesoft: ' +
          maintenanecItemResponseError) as string
      )
    }
  }, [maintenanecItemResponseError])
  return (
    <div className={classes.root}>
      {maintenanceResponseError && (
        <AlertNotification
          type="error"
          message={maintenanceResponseError}
          onHide={() => {
            setMaintenanceResponseError('')
          }}
        />
      )}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <PercedureIcon style={{ marginRight: '.5rem', height: '1rem' }} />
            Operations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TaskListOpsTable
            opsTableData={parseToOperationTableData(maintenanceItemResponseObj)}
            showError={maintenanecItemResponseError ? true : false}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <PartsIcon style={{ marginRight: '.5rem', height: '1rem' }} />
            Materials
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TaskListPartsTable
            materialTableData={parseToPartsTableData(
              maintenanceItemResponseObj
            )}
            showError={maintenanecItemResponseError ? true : false}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>
            <PermitIcon style={{ marginRight: '.5rem', height: '1rem' }} />
            Permits {'&'} Isolations
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PermitData
            permitId={permitTemp!}
            isoId={isoTemp!}
            plantId={plantId}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
