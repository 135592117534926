import styled from '@emotion/styled'
import { CircularProgress, Box, Select, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { LineChart, CartesianGrid, XAxis, YAxis, Line, Legend, Tooltip, ResponsiveContainer } from 'recharts'

import { GlobalDateFormatter, GlobalTimeFormatterHHMM, useParamQuery } from '../../../../../shared/utility'
import useTSData from '../../hooks/useTSData'
import useEquipmentWithOutMeasurements from '../../../../../services/hooks/useEquipmentWithoutMeasurements'
import useAnomalyMeasureHistoryData from '../../hooks/useAnomalyMeasureHistoryData'
import { AnomalyModelOutputObj } from '../../../../../shared/types/getEquipmentResponse'
import { TimeRange, GetTimeRangeCandidates } from './time-range'

export interface SealPressureGraphProps { }

const useStyles = makeStyles({
  chartWrapper: {
    position: 'relative',
    marginTop: '1rem',
    alignSelf: 'center',
  },
  chartOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    marginTop: '1rem',
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0.5rem',
    margin: '0.5rem 0',
  },
  timeRangeLabel: {
    color: '#666666',
    marginRight: '1rem',
  },
  modalContainer: {
    position: 'relative',
    display: 'inline-block',
    marginTop: '1rem',
  },
})

const TimeRangeContainer = styled(Box)`
  background: rgba(0, 21, 67, 0.08);
  display: inline-block;
  margin-top: -5px;
  select {
    font-weight: 500;
    font-size: 13px;
    text-align:center;
  }
`

const StyledDiv = styled(Box)`
  padding: 1.2rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const timeRangeCandidates = GetTimeRangeCandidates()
const SealPressureGraph = React.memo((props: SealPressureGraphProps) => {
  const classes = useStyles()
  const mySearchQuery = useParamQuery()
  const [timeRange, setTimeRange] = React.useState<TimeRange>(
    timeRangeCandidates[1]
  )
  const floc = mySearchQuery.get('floc')!
  const [phdTag, setPhdTag] = React.useState('')
  const [phdTagName, setPhdTagName] = React.useState('')
  const [phdTagUnit, setPhdTagUnit] = React.useState('kPag')
  const { data, isLoading } = useEquipmentWithOutMeasurements(
    floc,
    timeRangeCandidates[0].startTimestamp,
    timeRangeCandidates[0].endTimestamp,
    true
  )
  const {
    data: expectedSealData,
    isLoading: expectedLoading,
  } = useAnomalyMeasureHistoryData(
    floc,
    timeRange.startTimestamp,
    timeRange.endTimestamp,
    undefined,
    ['losslessSealPressure']
  )

  const {
    data: tsData,
    isLoading: TSDataLoading,
  } = useTSData(
    phdTag,
    timeRange.aggregateUnit,
    timeRange.aggregate,
    Math.floor(timeRange.startTimestamp),
    Math.floor(timeRange.endTimestamp),
    floc
  )

  /**
   * Initialise the chart data with start and end timestamp so the grid gets rendered even if no data is being loaded yet
   */
  const [chartData, setChartData] = React.useState<any[]>([{
    date: timeRange.startTimestamp,
  }, {
    date: timeRange.endTimestamp,
  }])

  const lossLessSealPressureName = 'Loss Less Seal Pressure'
  React.useMemo(() => {
    if (data && data.measurements && data.measurements.length) {
      const tsData = data.measurements.filter((r) => {
        // SEAL SYSTEM, SEAL PRESSURE, are common keys used, and always in caps
        // yes and this is "tehc det"
        return (
          r.name.includes('SEAL SYSTEM') || r.name.includes('SEAL PRESSURE')
        )
      })
      if (tsData.length) {
        setPhdTag(tsData[0].id)
        setPhdTagName(tsData[0].name)
        if (tsData[0].unitOfMeasure) {
          setPhdTagUnit(tsData[0].unitOfMeasure)
        }
      } else {
        //TODO: log error
      }
    }
  }, [data])

  /**
   * Used by the chart X-axis to format the timestamp into a human readable date
   * @param timestamp
   */
  function formatDate(timestamp: number) {
    return `${GlobalDateFormatter.format(
      timestamp
    )} ${GlobalTimeFormatterHHMM.format(timestamp)}`
  }

  React.useMemo(() => {
    if (tsData && tsData.length) {
      const currentTimeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000
      const currentTimeZoneOffsetFromSydney =
        (new Date().getTimezoneOffset() + 600) * 60 * 1000
      let newData = tsData.map((x) => ({
        date: new Date(x.time).getTime() - currentTimeZoneOffset,
        [phdTagName]: x.value.toPrecision(5),
      }))
      let expectedSealPressureGraphData: any[] = []
      if (expectedSealData) {
        const losslessSealValue = expectedSealData.anomalyModelOutputs.filter(
          (d) => d.measureName === 'losslessSealPressure'
        )
        if (losslessSealValue.length) {
          if (timeRange.timeRangeID > 1) {
            // apply aggregation if not 24 hours
            const groupedDate = losslessSealValue.reduce((last, current) => {
              // datePart and
              if (timeRange.timeRangeID > 3) {
                // 3Month onwards,  use daily aggregate
                Object.assign({ ...last, date: current.datePart })
                last[current.datePart] = (last[current.datePart] || []).concat(
                  current
                )
                return last
              } else {
                Object.assign({
                  ...last,
                  date: current.hourPart,
                })
                last[current.hourPart] = (last[current.hourPart] || []).concat(
                  current
                )
                return last
              }
            }, {})
            Object.keys(groupedDate).forEach((date) => {
              const readingsOftheDay: AnomalyModelOutputObj[] = groupedDate[date]
              const dailyAvg =
                readingsOftheDay.reduce(
                  (a, b) => a.valueOf() + (b.valueOf || 0),
                  0
                ) / readingsOftheDay.length

              expectedSealPressureGraphData =
                expectedSealPressureGraphData.concat({
                  date:
                    new Date(parseInt(date)).getTime() -
                    currentTimeZoneOffsetFromSydney, // doge model output data, have to + 600 so it counters the Sydney offset: ;
                  [lossLessSealPressureName]: dailyAvg.toFixed(2),
                })
            })
          } else {
            //24 hours, no data atm
          }
          newData = newData.concat(expectedSealPressureGraphData)
        }
      }
      setChartData(newData)
    }
  }, [tsData, expectedSealData])

  return (
    <StyledDiv>
      <div>
        <Divider />
        <div className={classes.chartWrapper}>
          {(TSDataLoading || isLoading || expectedLoading) && (
            <div className={classes.chartOverlay}>
              <CircularProgress />
            </div>
          )}
          {chartData.length && (
            <ResponsiveContainer width="99%" aspect={4} minWidth="50vw">
              <LineChart
                data={chartData}
                margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
              >
                <CartesianGrid
                  fill="#FCFCFC"
                  vertical={false}
                  strokeDasharray="1 3"
                />
                <XAxis
                  type="number"
                  dataKey="date"
                  tickMargin={10}
                  reversed={false}
                  minTickGap={20}
                  tick={{ fontSize: 11 }} // dx: 10 if we want to move the tick text to the right
                  tickFormatter={formatDate}
                  domain={[
                    timeRange.startTimestamp * 1000,
                    timeRange.endTimestamp * 1000,
                  ]}
                  axisLine={{ stroke: '#C8C8C8' }}
                />
                <YAxis
                  yAxisId="right"
                  type="number"
                  tick={{ fontSize: 11 }}
                  tickCount={5}
                  orientation="right"
                  domain={['dataMin', 'dataMax']}
                  axisLine={false}
                  tickLine={false}
                />
                <YAxis
                  yAxisId="left"
                  tick={false}
                  orientation="left"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    dy: 80,
                    offset: 40,
                    value: `Barrier Seal Pressure (${phdTagUnit})`,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 14,
                    fontWeight:500
                  }}
                />
                <Tooltip
                  labelFormatter={(val: number) => formatDate(val)}
                  formatter={(val: number) => val}
                />
                <Line
                  isAnimationActive={false}
                  name={phdTagName}
                  unit=""
                  yAxisId="right"
                  type="linear"
                  dot={false}
                  dataKey={phdTagName}
                  stroke={'#2C98F0'}
                  strokeWidth={2}
                />
                {expectedSealData?.anomalyModelOutputs.length && (
                  <Line
                    isAnimationActive={false}
                    name={lossLessSealPressureName}
                    unit=""
                    yAxisId="right"
                    type="linear"
                    dot={false}
                    dataKey={lossLessSealPressureName}
                    stroke={'#4054B2'}
                    strokeDasharray="5 5"
                    strokeWidth={1}
                  />
                )}
                <Legend
                  wrapperStyle={{
                    paddingTop: '18px',
                    position:'relative'
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <Box className={classes.controlsContainer}>
          <div>
            <label className={classes.timeRangeLabel}>Time Range:</label>
            <TimeRangeContainer>
              <Select
                style={{ width: '6rem' }}
                native
                defaultValue={timeRangeCandidates[1].timeRangeID}
                onChange={(e) => {
                  const selected = parseInt(e.target.value as string)
                  const timeRange = timeRangeCandidates.find(
                    (x) => x.timeRangeID === selected
                  )!
                  setTimeRange(timeRange)
                  e.preventDefault()
                }}
                variant="standard"
              >
                {timeRangeCandidates.map((x) => (
                  <option value={x.timeRangeID} key={x.timeRangeID}>
                    {x.displayLabel}
                  </option>
                ))}
              </Select>
            </TimeRangeContainer>
          </div>
        </Box>
      </div>
    </StyledDiv>
  )
})

export default SealPressureGraph
