import styled from '@emotion/styled'
import React from 'react'
import Table from 'antd/lib/table/Table'
import { Box } from '@mui/material'

import { ReactComponent as ExternalOpen } from '../../../../../icons/open-external.svg'
import UIOPAMObject from '../../../../../shared/types/UIOpamObject'

export interface OPAMOverviewProps {
  tableData?: UIOPAMObject[];
}

const Root = styled(Box)`
  padding: 0 1.2rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: column;
`

const NoData = styled(Box)`
  opacity: 0.6;
`

const HintSpan = styled.span`
  opacity: 0.8;
  font-size: 1rem;
  svg {
    width: 0.8rem;
    height: 0.8rem;
  }
`

const TableContainer = styled(Box)`
  .ant-pagination-item-active {
    background-color: #E6E7E8;
    border-color: #E6E7E8;
    border-radius: 30px
  }
  .ant-pagination-item-active a{
    color: rgba(0, 0, 0, 0.87);
  }
  .ant-table-thead tr th{
    background-color: #fff
  }
`

const columns = [
  {
    title: 'Task Recorded Date',
    dataIndex: 'recordDateString',
    width: '15%',
    sorter: (a: UIOPAMObject, b: UIOPAMObject) =>
      a.recordDate.diff(b.recordDate),
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
  },
  {
    title: 'Task Detail',
    dataIndex: 'taskDetail',
  },
  {
    title: 'Result',
    dataIndex: 'result',
  },
  {
    title: 'Comment',
    dataIndex: 'comments',
  },
  {
    title: 'Recorded By',
    dataIndex: 'recordedBy',
  },
]

const OPAMOverview = (props: OPAMOverviewProps) => {
  const noData = () => {
    return !(props.tableData && props.tableData.length)
  }
  return (
    <Root>
      {noData() ? (
        <NoData>
          It looks like there is currently no OPAM data for this equipment over
          the last 8 weeks. If you think this is incorrect, please contact IT
          support.
        </NoData>
      ) : (
        <>
        <TableContainer>
          <Table
            style={{ width: '100%' }}
            columns={columns}
            dataSource={props.tableData}
            size="small"
            pagination={{
              pageSize: 5,
              hideOnSinglePage: false,
              showSizeChanger: false,
            }}
            rowKey="uniqueId"
          />
        </TableContainer>
          <HintSpan>
            This data extract is limited to the last 8 weeks. Complete history
            for this equipment can be viewed in
            <a
              id="link"
              href={'https://fuse.app.woodside/assets/PLP/pam/tasks'}
              target="_blank" rel="noreferrer"
            >
              {' '}
              FUSE
              <ExternalOpen />.
            </a>
          </HintSpan>
        </>
      )}
    </Root>
  )
}

export default OPAMOverview
