import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Box, LinearProgress, Tab, Tabs, Theme, Toolbar, Tooltip, Select } from '@mui/material'
import { withStyles, createStyles, makeStyles } from '@mui/styles'
import React, { useMemo, useContext, createContext, useState, useEffect, ChangeEvent } from 'react'
import { apm } from '@elastic/apm-rum'

import { ReactComponent as SortDown } from '../../icons/sort-down.svg'
import { ReactComponent as SortUp } from '../../icons/sort-up.svg'
import { LoggingContext } from '../../shared/providers/spa-logging-provider'
import { AlertDetail, AlertTypes, CancelledAlert, DiagnosedAlert } from '../../shared/types/alert'
import { EquipmentType } from '../../shared/types/equipmentTypes'
import { ConvertToFlocAlerts, FlocAlerts, SortFlocAlerts } from '../../shared/types/flocAlerts'
import { Plant } from '../../shared/types/plants'
import { SortKeyEnum } from '../../shared/types/sortableEntity'
import FilterDrawer from './filter-drawer/FilterDrawer'
import useActiveAlertList from './hooks/useActiveAlertList'
import useResolvedAlertList from './hooks/useResolvedList'
import useCancelledAlertList from './hooks/useSnoozedAlertList'
import { useUserPreference } from './hooks/useSortingPerference'
import { useParamQuery } from '../../shared/utility'
import AppFeedbackProvider, { AppFeedbackContext, FeedbackContext } from '../navigation/global-feedback/feedback-provider'
import { ReactComponent as WoodsideLogo } from '../../icons/woodside.svg'
import AlertNotification from '../../shared/components/alert-notification'
import ActiveTab from './table-tabs/active/active-tab'
import CancelledTab from './table-tabs/cancal/cancel-tab'
import ResolvedTab from './table-tabs/actioned/actioned-tab'

export type FilterSetting = {
  plant: Plant;
  equipmentType: EquipmentType | 'all';
  alertType: AlertTypes | 'all';
};

type DataFilterContextType = {
  filterSetting: FilterSetting;
  alertCounts: Array<{
    alertType: AlertTypes;
    activeCount: number;
    assetName: Plant;
  }>;
  onFilterChange?: () => void;
};

export const DataFilterContext = createContext<DataFilterContextType>({
  filterSetting: {
    plant: Plant.pluto,
    equipmentType: EquipmentType.fan,
    alertType: 'all',
  },
  alertCounts: [],
})

const DrawerWidth = 270

const SortDiv = styled(Box)(
  css`
    margin-left: auto;
    place-self: center;
    margin-right: 1rem;
    span {
      color: #666666;
      margin-right: 1rem;
      font-size: 14px;
      font-weight: 400;
    }
    svg:first-of-type {
      /* Sort up */
      margin-left: 1rem;
    }
    svg:last-of-type {
      /* Sort down */
      margin-left: 0.5rem;
    }
    svg.selected {
      opacity: 0.3;
    }
    svg {
      width: 1rem;
    }
    svg:hover {
      cursor: pointer;
    }
    .MuiInputBase-root {
      background-color: white;
      color: #001543;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px
    }
    .MuiNativeSelect-standard {
      padding: 0.5rem !important;
      padding-right: 1.5rem !important;
    }
  `
)

const PaperSection = styled(Box)`
  width: 98%;
  @media (max-width: 1300px) {
    width: 95%;
  }
  margin-top: 32px;
  h1 {
    font-weight: 400;
  }
  #top-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    button {
      place-self: flex-start;
    }
  }
`

const AlertBoxContainer = styled(Box)`
  min-height: 88vh;
  border: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

const TabHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #e8e8e8;
`

const AntTabs = withStyles({
  root: {},
  indicator: {
    height: '0.15rem',
    backgroundColor: '#00354D',
  },
})(Tabs)

interface StyledTabProps {
  label: string;
}

type SortKeyCandidateType = {
  key: string;
  label: string;
};

const sortKeyCandidates = [{
  key: SortKeyEnum.DATE,
  label: 'Date Raised',
}, {
  key: SortKeyEnum.FLOC,
  label: 'FLOC',
}, {
  key: SortKeyEnum.TYPE,
  label: 'Alert Type',
}, {
  key: SortKeyEnum.SEVERITY,
  label: 'Alert Severity',
}] as SortKeyCandidateType[]

const TabStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      fontWeight: 500,
      fontSize: 15,
      textTransform: 'none',
      minWidth: 160,
      opacity: '0.60',
      color: '#001543',
      '&$selected': {
        opacity: 1,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab {...props} />)

const useStyles = makeStyles({
  root: {
    width: 256,
    '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
      backgroundColor: '#00154314'
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#00154314'
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#001543'
    },
  }
})

const CenteredDiv = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
`

const HorizontallyCenteredDiv = styled(Box)`
  text-align: center;
`

const Root = styled(Box)`
  /* width of drawer */
  margin-left: ${DrawerWidth}px;
  height: 100%;
  min-width: 50rem;
  display: flex;
  justify-content: center;
`

const AnomalyList = () => {
  const classes = useStyles()
  // this create a default filter setting, we will need to load the storage preference as well/
  const filterContext = useContext(DataFilterContext)
  const loggingContext = useContext(LoggingContext)
  const query = useParamQuery()
  const [showFeedback, setShowFeedback] = useState<boolean>(false)

  useEffect(() => {
    setShowFeedback(query.get('showFeedback') ? true : false)
    if (filterContext) {
      filterContext.filterSetting = storedPreference.filter
    }
  }, [])

  // floc alerts are processed AlertDetails so that UI can handle muti-alerts
  const [filteredActiveData, setFilteredActiveData] = useState<
    FlocAlerts[] | undefined
  >()
  // only used to calculate count to feed into filter panel
  const [filteredActiveAlertData, setFilteredActiveAlertData] = useState<
    AlertDetail[] | undefined
  >()
  const [filteredDiagnosisData, setFilteredDiagnosisData] = useState<
    DiagnosedAlert[] | undefined
  >()
  const [filteredCancelledData, setFilteredCancelledData] = useState<
    CancelledAlert[] | undefined
  >()

  // dynamic based on user perference and which tab is currently opened
  const [storedPreference, setStoredPreference] = useUserPreference()
  // 0 1 2 (active, Snooze, actioned)
  const [tabSelectionValue, setTablSelectionValue] = useState<0 | 1 | 2>(
    storedPreference.defaultPage as any
  )

  const applyFilter = () => {
    const filteredAlertDetails = (
      activeData as unknown as AlertDetail[]
    ).filter((row) => {
      return (
        row.plantName === filterContext.filterSetting.plant &&
        (filterContext.filterSetting.equipmentType !== 'all'
          ? row.equipmentType === filterContext.filterSetting.equipmentType // apply filter
          : true) && // not apply filter on equipmentType
        (filterContext.filterSetting.alertType !== 'all'
          ? row.alertType === filterContext.filterSetting.alertType // apply filter
          : true) // not apply filter on alertType
      )
    })
    const filteredCancelledAlerts = cancelledData?.filter((row) => {
      return (
        row.plantName === filterContext.filterSetting.plant &&
        (filterContext.filterSetting.equipmentType !== 'all'
          ? row.equipmentType === filterContext.filterSetting.equipmentType // apply filter
          : true) &&
        (filterContext.filterSetting.alertType !== 'all'
          ? row.alertType === filterContext.filterSetting.alertType // apply filter
          : true) // not apply filter on alertType
      )
    })
    setFilteredActiveAlertData(filteredAlertDetails)
    setFilteredActiveData(
      SortFlocAlerts(
        ConvertToFlocAlerts(filteredAlertDetails),
        storedPreference[tabSelectionValue].sortKey,
        storedPreference[tabSelectionValue].sortDirection
      )
    )
    setFilteredCancelledData(filteredCancelledAlerts!)

    // for diagnosis, filter by Plant
    const filteredDiagnoisedAlerts = (
      diagnosedData as unknown as DiagnosedAlert[]
    ).filter((row) => {
      return (
        row.plantName === filterContext.filterSetting.plant &&
        (filterContext.filterSetting.equipmentType !== 'all'
          ? row.equipmentType === filterContext.filterSetting.equipmentType // apply filter
          : true) &&
        (filterContext.filterSetting.alertType !== 'all'
          ? row.alertType === filterContext.filterSetting.alertType // apply filter
          : true) // not apply filter on alertType
      )
    })
    setFilteredDiagnosisData(filteredDiagnoisedAlerts)
  }

  const { data: activeData, error: activeError, isLoading: activeLoading } = useActiveAlertList()
  const { data: cancelledData, error: cancelledError, isLoading: cancelledLoading } = useCancelledAlertList()
  const { data: diagnosedData, error: diagnosedError, isLoading: resolvedLoading } = useResolvedAlertList()

  const [noDataFlag, setNoDataFlag] = useState(false)
  useMemo(() => {
    // this only shows when there is an issue with down stream api
    if (!activeData && !cancelledData && !activeLoading && !cancelledLoading) {
      setNoDataFlag(true)
    }
  }, [activeData, activeLoading, cancelledData, cancelledLoading])

  // MUST use useMemo, if you use Effect, it will cause very slow code...
  useMemo(() => {
    if (activeData && diagnosedData && cancelledData) {
      applyFilter()
    }
  }, [
    activeData,
    diagnosedData,
    cancelledData,
    tabSelectionValue,
    storedPreference,
  ])

  const handleChange = (
    event: ChangeEvent<any>,
    newTabValue: 0 | 1 | 2
  ) => {
    setTablSelectionValue(newTabValue)
    setStoredPreference({
      ...storedPreference,
      defaultPage: newTabValue,
    })
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const onFilterChange = () => {
    setStoredPreference({
      ...storedPreference,
      filter: filterContext.filterSetting,
    })
    applyFilter()
  }

  const isLoaded = () => {
    return filteredActiveData && cancelledData && diagnosedData
  }

  if (isLoaded()) {
    // set up the counts for the conetxt panel
    loggingContext.log('INFO', 'Landing Page Loaded')
    const alertTypeCounts = filteredActiveAlertData!.reduce((total, value) => {
      total[value.alertType] = (total[value.alertType] || 0) + 1
      return total
    }, {})
    filterContext.alertCounts = Object.keys(alertTypeCounts).map(
      (alertType) => {
        return {
          alertType: alertType as AlertTypes,
          activeCount: alertTypeCounts[alertType],
          assetName: filterContext.filterSetting.plant,
        }
      }
    )
    filterContext.onFilterChange = onFilterChange
  }

  const Feedback = ({
    consumerContext,
  }: {
    consumerContext: FeedbackContext | undefined;
  }) => {
    apm.addLabels({
      componentName: 'external-feedback',
      componentType: 'IconButton',
      interactionType: 'click',
    })
    consumerContext?.openFeedback()
    return <div data-testid="feedbackModalContainer"></div>
  }

  return (
    <div>
      <Toolbar />
      {showFeedback && (
        <AppFeedbackProvider
          callback={() => {
            setShowFeedback(false)
          }}
          onClose={() => {
            setShowFeedback(false)
          }}
        >
          <AppFeedbackContext.Consumer>
            {(consumerContext) => (
              <Feedback consumerContext={consumerContext} />
            )}
          </AppFeedbackContext.Consumer>
        </AppFeedbackProvider>
      )}
      {isLoaded() && <FilterDrawer/>}
      <Root>
        <PaperSection>
          <div id="top-heading">
            <h1>Health Alerts</h1>
          </div>
          {(activeLoading || cancelledLoading || resolvedLoading) && (
            <CenteredDiv data-testid="loadingContainer">
              <HorizontallyCenteredDiv><WoodsideLogo style={{height: 85, width: 85}}/></HorizontallyCenteredDiv>
              <HorizontallyCenteredDiv><span style={{ fontWeight: 400, fontSize: 16, textAlign: 'center', letterSpacing: 0.15, color: 'rgba(0, 0, 0, 0.6)'}}>Preparing Health Alerts...</span></HorizontallyCenteredDiv>
              <HorizontallyCenteredDiv><LinearProgress variant="determinate" value={8} classes={{root: classes.root}} color="primary"/></HorizontallyCenteredDiv>
            </CenteredDiv>
          )}
          {(activeError || cancelledError || diagnosedError) && (
            <AlertNotification
              type="error"
              message={
                ((activeError || cancelledError || diagnosedError) as any)
                  .message
              }
            />
          )}
          {isLoaded() && (
            <AlertBoxContainer data-testid="mainAlertsContainer">
              <TabHeaderContainer>
                <AntTabs
                  value={tabSelectionValue}
                  onChange={handleChange}
                  aria-label="Nav Tabs"
                  indicatorColor='primary'
                  TabIndicatorProps={{ style: { background: '#001543', color: '#001543', minWidth: 160 } }}
                >
                  <TabStyled
                    data-testid="activeTabHeader"
                    style={{ color: '#001543' }}
                    label={`ACTIVE (${filteredActiveData!.length})`}
                    {...0}
                  />
                  <TabStyled
                    data-testid="removedTabHeader"
                    label={`REMOVED (${filteredCancelledData!.length})`}
                    {...a11yProps(1)}
                  />
                  <TabStyled
                    data-testid="actionedTabHeader"
                    label={`ACTIONED (${filteredDiagnosisData!.length})`}
                    {...a11yProps(12)}
                  />
                </AntTabs>
                <SortDiv hidden={tabSelectionValue !== 0}>
                  <span>Sort by:</span>
                  <Select
                    variant="standard"
                    autoWidth={true}
                    data-testid="sortSelect"
                    native
                    id="select"
                    value={storedPreference[tabSelectionValue].sortKey}
                    onChange={(e) => {
                      const newVal = e.target.value as SortKeyEnum
                      setStoredPreference({
                        ...storedPreference,
                        [tabSelectionValue]: {
                          ...storedPreference[tabSelectionValue],
                          sortKey: newVal,
                        },
                      })
                      e.preventDefault()
                    }}
                    inputProps={{
                      name: 'sort',
                      id: 'sort-selector',
                    }}
                    disableUnderline
                  >
                    {sortKeyCandidates.map((option) => {
                      return (
                        <option
                          aria-label={'key'}
                          value={option.key}
                          key={option.key}
                        >
                          {option.label}
                        </option>
                      )
                    })}
                  </Select>
                  <Tooltip title="Ascending" enterDelay={500} disableInteractive>
                    <SortUp
                      data-testid="sortUpButton"
                      className={
                        storedPreference[tabSelectionValue].sortDirection !==
                        'asc'
                          ? 'selected'
                          : ''
                      }
                      onClick={() =>
                        setStoredPreference({
                          ...storedPreference,
                          [tabSelectionValue]: {
                            sortDirection: 'asc',
                            sortKey:
                              storedPreference[tabSelectionValue].sortKey,
                          },
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Descending" enterDelay={500} disableInteractive>
                    <SortDown
                      data-testid="sortDownButton"
                      className={
                        storedPreference[tabSelectionValue].sortDirection !==
                        'desc'
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setStoredPreference({
                          ...storedPreference,
                          [tabSelectionValue]: {
                            sortDirection: 'desc',
                            sortKey:
                              storedPreference[tabSelectionValue].sortKey,
                          },
                        })
                      }}
                    />
                  </Tooltip>
                </SortDiv>
              </TabHeaderContainer>
              <ActiveTab
                input={filteredActiveData}
                value={tabSelectionValue}
                index={0}
              />
              <CancelledTab
                input={filteredCancelledData!}
                value={tabSelectionValue}
                index={1}
              />
              <ResolvedTab
                input={filteredDiagnosisData!}
                value={tabSelectionValue}
                index={2}
              />
            </AlertBoxContainer>
          )}
          {noDataFlag && (
            <h3 style={{ gridRowStart: 3, gridColumn: '4 / 8' }}>
              There seems to be some issue with our server... please contact IT
              Support team to let them know!
            </h3>
          )}
        </PaperSection>
      </Root>
    </div>
  )
}
export default AnomalyList
