import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'

import JobDetailDialogue from './job-detail-popup'
import MaintenanceJob from '../../../../shared/types/maintenanceJob'
import { memo } from 'react'

export interface ContextCardProps {
  maintenanceJobInputData: MaintenanceJob;
}
// pure fucntion - only depend on prop
const ContextCard = memo((props: ContextCardProps) => {
  const CardBox = styled(Box)`
    display: flex;
    min-height: 20%;
    background-color: white;
    padding: 1rem;
    padding-bottom: 0;
    flex-wrap: wrap;
    border-radius: 0.7rem;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.05);
    }
  `

  const HeaderBar = styled(Box)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: 0.7rem;
      opacity: 0.6;
      margin-bottom: 0.5rem;
    }
  `

  const CardTitle = styled.h4`
    font-weight: 500;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0.5rem;
  `

  const StatusText = styled.p`
    font-size: 0.7rem;
    font-weight: 400;
    color: #666666;
  `

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <CardBox
      boxShadow={2}
      onClick={() => {
        // funny - onlick applies to entire component including pop up,
        // so make sure to disable the action if it's already opened it
        if (!open) setOpen(true)
      }}
    >
      <HeaderBar>
        <p>{props.maintenanceJobInputData.floc}</p>
        {props.maintenanceJobInputData.isCurrent ? (
          <p>
            {props.maintenanceJobInputData.isWorkOrder
              ? (props.maintenanceJobInputData.StartDateTimeStamp > new Date()
                  ? 'Starting '
                  : 'Started ') + props.maintenanceJobInputData.StartDate
              : 'Created ' + props.maintenanceJobInputData.identificationDate}
          </p>
        ) : (
          <p>Closed {props.maintenanceJobInputData.closedDate} </p>
        )}
      </HeaderBar>
      <CardTitle>
        {props.maintenanceJobInputData.notificationShortText}
      </CardTitle>
      <StatusText>{props.maintenanceJobInputData.jobStatus}</StatusText>
      <JobDetailDialogue
        handleClose={handleClose}
        open={open}
        inputJobData={props.maintenanceJobInputData}
      />
    </CardBox>
  )
})

export default ContextCard
