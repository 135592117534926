import React from 'react'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import { MsalAuthenticationTemplate, MsalContext } from '@azure/msal-react'
import { Box, createTheme, CssBaseline, Toolbar } from '@mui/material'
import { ThemeProvider } from 'emotion-theming'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import styled from '@emotion/styled'
import { InteractionType } from '@azure/msal-browser'
import { ApmRoute } from '@elastic/apm-rum-react'
import { woodsideBlueTheme } from '@woodside/mui-themes/index.js'

import AppBarComp from './features/navigation/App.Bar'
import AnomalyReview from './features/anomaly-review/component'
import AnomalyList from './features/anomaly-list/component'
import AppSearchProvider from './shared/providers/app-search-provider'
import LogProvider from './shared/providers/spa-logging-provider'
import UserTokenProvider from './shared/providers/user-token-provider'
import FlocContextProvider from './shared/providers/floc-context-provider'

export const theme = createTheme(woodsideBlueTheme, {
  palette: {
    primary: {
      main: '#00354D',
    },
    secondary: {
      main: '#D71638',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h2: {
      fontWeight: 400,
    },
    h5: {
      fontWeight: 400,
      fontsize: 10,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.7rem', // all tooltip 1rem
        },
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#F3F5F7'
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1890ff'
        }
      }
    },
  },
})

export default class App extends React.Component<{}> {
  static contextType = MsalContext
  constructor(props: {}) {
    super(props)
  }

  render() {
    const Root = styled(Box)`
      display: flex;
      flex-grow: 1;
    `

    const AlertListDiv = styled(Box)`
      flex-grow: 1;
    `

    const MainContent = styled(Box)`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    `

    function ErrorComponent({ error }: any) {
      console.log(error)
      return <p>An Error Occurred, please check consolelog</p>
    }

    function LoadingComponent() {
      return <p>Authentication in progress...</p>
    }

    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Router>
          <UserTokenProvider>
            <LogProvider>
              <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <Root>
                    <AppSearchProvider>
                      <FlocContextProvider>
                        <CssBaseline />
                        <AppBarComp />
                        <Switch>
                          <ApmRoute
                            path="/"
                            exact
                            component={() => (
                              <AlertListDiv>
                                <AnomalyList />
                              </AlertListDiv>
                            )}
                          />
                          <ApmRoute
                            path="/floc/detail/"
                            component={() => (
                              <MainContent>
                                <Toolbar />
                                <AnomalyReview />
                              </MainContent>
                            )}
                          />
                          <ApmRoute
                            path="/"
                            component={() => <Redirect to="/" />}
                          />
                        </Switch>
                      </FlocContextProvider>
                    </AppSearchProvider>
                  </Root>
                </ThemeProvider>
              </MuiThemeProvider>
            </LogProvider>
          </UserTokenProvider>
        </Router>
      </MsalAuthenticationTemplate>
    )
  }
}
