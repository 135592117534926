import styled from '@emotion/styled'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment'

import { ReactComponent as ExternalOpen } from '../../../../../../icons/open-external.svg'
import OilStatus from '../status'
import { GlobalDateFormatter } from '../../../../../../shared/utility'
import { OilResultUIDataModel } from './oil-overview'

const TextPair = styled(Box)`
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 400;
    font-size: 0.9rem;
    opacity: ${(props: any) => (props['aria-disabled'] ? '0.6' : '1')};
  }
  h5 {
    opacity: 60%;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }
`

const TextPairWithMarginTop = styled(Box)`
  margin-top: 2rem;
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 400;
    font-size: 0.9rem;
    opacity: ${(props: any) => (props['aria-disabled'] ? '0.6' : '1')};
  }
  h5 {
    opacity: 60%;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }
`

const TextPairFullLength = styled(Box)`
  margin-top: 2rem;
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 400;
    font-size: 0.9rem;
    opacity: ${(props: any) => (props.missing ? '0.6' : '1')};
  }
  h5 {
    opacity: 60%;
    font-weight: 400;
    margin-bottom: 0.4rem;
  }
  a {
    svg {
      width: 0.8rem;
    }
  }
`

const AccordionDetailsStyled = styled(AccordionDetails)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Hack Google */
  padding-bottom: 20px !important; 
`

const TitleDiv = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  place-items: center;
  #title {
    display: flex;
    align-items: center;
    place-items: center;
    gap: 1rem;
    h3 {
      font-weight: 500;
      margin: 0;
    }
    span {
      /* last updated */
      font-size: 0.7rem;
      opacity: 60%;
    }
  }
`

export interface CompartmentAccordionProps {
  data: OilResultUIDataModel;
}

const AccordionStyled = styled(Accordion)`
  width: 100%;
`

const CompartmentAccordion = (props: CompartmentAccordionProps) => {
  return (
    <AccordionStyled data-testid="compartmentRow">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="content"
        id="header"
      >
        <TitleDiv>
          <div id="title">
            <h3>{props.data.compartmentName}</h3>
            <span>
              Last sampled{' '}
              {moment(props.data.lastUpdated).isValid()
                ? GlobalDateFormatter.format(props.data.lastUpdated)
                : 'Unknown'}
            </span>
          </div>
          <OilStatus status={props.data.status} />
        </TitleDiv>
      </AccordionSummary>
      <AccordionDetailsStyled>
        <TextPair>
          <h5>Compartment Type:</h5>
          <span>{props.data.compartmentType}</span>
        </TextPair>
        <TextPair aria-disabled={props.data.fluidType === 'Not available'}>
          <h5>Fluid Type:</h5>
          <span>{props.data.fluidType}</span>
        </TextPair>
        <TextPair aria-disabled={props.data.fluidName === 'Not available'}>
          <h5>Fluid Name:</h5>
          <span>{props.data.fluidName}</span>
        </TextPair>
        <TextPair aria-disabled={props.data.manufacturer === 'Not available'}>
          <h5>Fluid Manufacturer:</h5>
          <span>{props.data.manufacturer}</span>
        </TextPair>
        <TextPair aria-disabled={props.data.grade === 'Not available'}>
          <h5>Fluid Grade:</h5>
          <span>{props.data.grade}</span>
        </TextPair>
        <TextPairWithMarginTop
          aria-disabled={props.data.compartmentCapacity === 'Not available'}
        >
          <h5>Compartment Capacity:</h5>
          <span>{props.data.compartmentCapacity}</span>
        </TextPairWithMarginTop>
        <TextPairWithMarginTop
          aria-disabled={props.data.problemCode === 'Not available'}
        >
          <h5>Problem Code:</h5>
          <span>{props.data.problemCode}</span>
        </TextPairWithMarginTop>
        <TextPairFullLength aria-disabled={props.data.diagnosis === 'Not available'}>
          <h5>Diagnosis:</h5>
          <span>{props.data.diagnosis}</span>
          <br />
          <a id="link" href={props.data.reportLink} target="_blank" rel="noreferrer">
            <ExternalOpen /> Click to view detailed ALS Report
          </a>
        </TextPairFullLength>
      </AccordionDetailsStyled>
    </AccordionStyled>
  )
}

export default CompartmentAccordion
