import { useQuery } from 'react-query'
import { getEquipmentOverviewQueryWithMeasurement } from '../graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../shared/auth'
import EquipmentOverview from '../../shared/types/getOverviewResponse'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'
import moment from 'moment'

async function fetchEquipmentWithMeasurementsByFloc(
  msalInstance: IPublicClientApplication,
  floc: string,
  aggregationType: 'SUM',
  timeBase: 'HOUR' | 'DAY' | 'MIN',
  interval: number, // aggregation intervel
  from: number
): Promise<EquipmentOverview> {
  // return new EquipmentOverview(sampleResponse.getOverview as any);
  const transaction = apm.startTransaction('useEquipmentOverview', 'EQ360')!
  const timeSpanInMonth = Math.ceil(
    moment().diff(moment(from), 'months', true)
  )
  const httpSpan = transaction.startSpan(
    `POST ${process.env.REACT_APP_EQ360_HOST!}`,
    'app'
  )!

  transaction.addLabels({
    measurement: true,
    floc: floc,
    timeBase: timeBase,
    interval: interval,
    timeSpanInMonth: timeSpanInMonth,
  })

  return new Promise((resolve, reject) =>
    Eq360GraphFetch(
      msalInstance,
      process.env.REACT_APP_EQ360_ID!,
      fetch,
      process.env.REACT_APP_EQ360_HOST!,
      getEquipmentOverviewQueryWithMeasurement(
        floc,
        aggregationType,
        timeBase,
        interval,
        from
      )
    )
      .then((response) => {
        if (response.getOverview) {
          resolve(new EquipmentOverview(response.getOverview))
        } else {
          apm.captureError(
            new Error(`get overview is not in the response for floc ${floc}.`)
          )
          reject('get overview is not in the response.')
        }
      })
      .catch((e) => {
        apm.captureError(
          new Error(`Equipment Measurement fetch failed with status ${e}`)
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  )
}

const useEquipmentWithMeasurements = (
  floc: string,
  aggregationType: 'SUM',
  timeBase: 'HOUR' | 'DAY' | 'MIN',
  interval: number, // aggregation intervel
  endTime: number,
  enabled: boolean
) => {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['EquipmentMeasurementsWithValue', floc, endTime],
    () =>
      fetchEquipmentWithMeasurementsByFloc(
        msalInstance,
        floc,
        aggregationType,
        timeBase,
        interval,
        endTime
      ),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 20 * 1000 * 60, // 20 min stale time,
      enabled: enabled,
    }
  )
}
export default useEquipmentWithMeasurements
