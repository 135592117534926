import styled from '@emotion/styled'
import {
  Box,
  CircularProgress,
} from '@mui/material'
import React from 'react'

import ESDGraph from '../esd-ts-graph'
import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EquipmentType } from '../../../../../shared/types/equipmentTypes'
import { useParamQuery } from '../../../../../shared/utility'
import useAnomalyMeasureHistoryData from '../../hooks/useAnomalyMeasureHistoryData'
import { AlertTypes } from '../../../../../shared/types/alert'

const Root = styled(Box)`
  padding: 1em;
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;

  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  #inline {
    a {
      margin: 0;
      display: inline;
    }
  }
`

const Heading = styled(Box)`
  display: flex;
  place-content: space-between;
  #status {
    display: flex;
    flex-direction: row;
    width: 15%;
    min-width: 10rem;
    gap: 0.5rem;
    align-items: flex-start;
    #severity {
      width: 80%;
    }
  }
`

const WaitingProgressComp = styled(CircularProgress)`
  grid-column: 4/12;
`

const Container = styled(Box)`
  background-color: white;
  padding: 0 1em 1em 0em;
`

const from = Math.floor(new Date().setDate(new Date().getDate() - 14) / 1000)
const to = Math.floor(new Date().getTime() / 1000)

const ESDCycleGraph = () => {
  const mySearchQuery = useParamQuery()
  const { error } = useAnomalyMeasureHistoryData(
    mySearchQuery.get('floc')!,
    from,
    to,
    EquipmentType.pump
  )

  const isLoaded = () => true
  return (
    <Root>
      <Heading>
        <h2>
          <FontAwesomeIcon icon={faRotate} style={{ paddingRight: '0.5rem' }} />
          Cycle Monitoring
        </h2>
      </Heading>
      {!isLoaded() && <WaitingProgressComp />}
      {isLoaded() && error && (
        <ErrorDiv data-testid="error-no-access">
          <Box id="red">
            <b>Error:</b> It looks like we’re currently unable to return any
            monitoring information for this equipment. Please contact IT support
            or try again later.
          </Box>
          <Box id="inline">
            You can still continue with the maintenance response through CBM.
          </Box>
        </ErrorDiv>
      )}
      {isLoaded() && !error && (
        <Container boxShadow={0}>
          <ESDGraph alertType={AlertTypes.ESDCycleModel} />
        </Container>
      )}
    </Root>
  )
}
export default ESDCycleGraph
