import React, { useEffect } from 'react'
import { Table, Tooltip } from 'antd'
import { Box } from '@mui/material'
import styled from '@emotion/styled'

import useEquipmentHierarchy from '../../../../services/hooks/useEquipmentHierarchyData'
import MaintenanceJob from '../../../../shared/types/maintenanceJob'
import { useParamQuery, walkFlocTree } from '../../../../shared/utility'
import useEquipmentMaintenanceData from '../../context-panel/hooks/useEquipmentMaintenanceData'

const TableContainer = styled(Box)`
  .ant-pagination-item-active {
    background-color: #E6E7E8;
    border-color: #E6E7E8;
    border-radius: 30px
  }
  .ant-pagination-item-active a{
    color: rgba(0, 0, 0, 0.6);
  }
  .ant-table-thead tr th{
    background-color: #fff
  }
`

const insightTable = [
  {
    title: 'Maintenance Start Date',
    dataIndex: 'StartDate',
  },
  {
    title: 'FLOC',
    dataIndex: 'floc',
  },
  {
    title: 'Work Description',
    dataIndex: 'notificationShortText',
    render: (text: string) => (
      <Tooltip title={text}>
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '20rem',
          }}
        >
          {text}
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Workorder Type',
    dataIndex: 'workorderType',
  },
  {
    title: 'Latest Status',
    dataIndex: 'jobStatus',
  },
  {
    title: 'Latest Priority',
    dataIndex: 'jobPriority',
  },
]

export interface MaintenanceInsightsTableProps {
  filters: any;
}

const MaintenanceInsightsTable = (props: MaintenanceInsightsTableProps) => {
  const [inputData, setInputData] = React.useState([] as MaintenanceJob[])
  const [flocs, setFLocs] = React.useState([] as string[])
  const [filteredInput, setFilteredInput] = React.useState([] as any)
  const mySearchQuery = useParamQuery()
  const { data: flocHierarchyData } = useEquipmentHierarchy( mySearchQuery.get('floc')!)
  const { data: equipmentContextResponse } = useEquipmentMaintenanceData(flocs, 12, flocHierarchyData ? true : false)

  React.useMemo(() => {
    if (Boolean(flocHierarchyData) && flocHierarchyData?.children) {
      setFLocs(walkFlocTree(flocHierarchyData!, []))
    }
  }, [flocHierarchyData])

  const setCurrentJobData = (jobs: MaintenanceJob[]) => {
    jobs = jobs.sort(
      (a, b) => a.StartDateTimeStamp.getTime() - b.StartDateTimeStamp.getTime()
    )
    // only currentone
    jobs = jobs.filter((row) => row.isCurrent)

    setInputData(jobs)
  }
  React.useEffect(() => {
    if (equipmentContextResponse) {
      setCurrentJobData(equipmentContextResponse)
    }
  }, [equipmentContextResponse])

  useEffect(() => {
    // apply filters:
    if (props.filters.pmco && !props.filters.pmrc) {
      setFilteredInput(
        inputData.filter((row) => {
          return row.workorderType && row.isCorrective
        })
      )
    } else if (props.filters.pmrc && !props.filters.pmco) {
      setFilteredInput(
        inputData.filter((row) => {
          return row.workorderType && !row.isCorrective
        })
      )
    } else if (props.filters.pmrc && props.filters.pmco) {
      setFilteredInput(
        inputData.filter((row) => row.workorderType && row.isWorkOrder)
      )
    } else {
      setFilteredInput([])
    }
  }, [props.filters, inputData])

  const locale = {
    emptyText:
      'We weren’t able to find any upcoming maintenance activities against this equipment. If you think this is incorrect, please contact IT support.',
  }

  return (
    <TableContainer>
      <Table
        locale={locale}
        style={{ width: '100%' }}
        columns={insightTable}
        dataSource={filteredInput}
        size="small"
        pagination={{ pageSize: 3, hideOnSinglePage: true }}
        rowKey="randomKey"
      />
    </TableContainer>
  )
}

export default MaintenanceInsightsTable
