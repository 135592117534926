import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { acquireTokenInfoSilent } from '../../shared/auth'
import { ConditionBasedModel } from '../../shared/types/strategyapi'
import { getEquipmentModelActionConditions } from '../graphql/StrategyQueries'
export const fetchStrategyModelActionConditions = async (
  msalInstance: IPublicClientApplication,
  floc: string,
  modelName: string
): Promise<ConditionBasedModel[]> => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        (await acquireTokenInfoSilent(
          msalInstance,
          process.env.REACT_APP_STRATEGY_APPID! + '/.default'
        ))!.accessToken
      }`,
      'Content-Type': 'application/json',
    },
  }
  return new Promise((resolve, reject) =>
    axios
      .post(
        process.env.REACT_APP_STRATEGY_ENDPOINT!,
        JSON.stringify({
          query: getEquipmentModelActionConditions(floc, modelName),
        }),
        config
      )
      .then(function (response: any) {
        const data = response.data.data
        if (Object.keys(data).includes('conditionBasedModel')) {
          resolve(data.conditionBasedModel)
        } else {
          reject('no conditionBasedModel is found for this floc.')
        }
      })
      .catch(function (error: any) {
        console.error(error)
        reject(error)
      })
  )
}

/**
 *
 * @param floc
 * @param eventType - the event type of this anomaly, for fan vibration is AnomalyModelOutput-Vibration
 * @returns Promise of conditionBasedActionConditionProperty
 */
export default function useStrategyModelActionCondition(
  floc?: string | null,
  eventType?: string | null
) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['Model Actions Conditions', floc, eventType],
    () => fetchStrategyModelActionConditions(msalInstance, floc!, eventType!),
    {
      enabled: floc && eventType ? true : false,
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 10 * 1000 * 60,
    }
  )
}
