import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import AnomalyListAlertCard from './anomaly-list-alert-card/components/component'
import { ReactComponent as LeftArrow } from '../../../icons/leftArrow.svg'
import SeverityHistory from './vibration-summary/component'
import { EquipmentType } from '../../../shared/types/equipmentTypes'
import OilInfoCard from './pump-cards/fluid-monitoring/component'
import SealCard from './pump-cards/seal-monitoring/component'
import { EquipmentOverviewContext } from '../../../shared/providers/floc-context-provider'
import ESDTemperatureGraph from './esd-cards/temperature-monitoring/component'
import ESDCycleGraph from './esd-cards/cycle-monitoring/component'
import ESDN2Graph from './esd-cards/n2-monitoring/component'
import { PaddedCard } from '../../../shared/components/padded-card'
import { PaddedTabBox } from '../../../shared/components/padded-box'

import './index.css'

const ButtonStyled = styled(Button)`
  align-self: flex-end;
`

const NavFooter = styled(Box)`
  width: 100%;
  padding: 0rem 0 0rem 0;
  background: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  svg {
    height: 1rem;
  }
  .backBtn.MuiButton-contained {
    background-color: #dce2e5 !important;
    color: #00354d;
  }
`

interface ConditionStepProps {
  handleNextPage: (data: any) => void;
  currentTab: number;
}

const ConditionStep = (props: ConditionStepProps) => {
  const history = useHistory()
  const eqOverview = useContext(EquipmentOverviewContext)

  return (
    <PaddedTabBox hidden={props.currentTab !== 0}>
      <PaddedCard>
        <AnomalyListAlertCard />
      </PaddedCard>
      {eqOverview?.isFan() && (
        <PaddedCard>
          <SeverityHistory equipmentType={EquipmentType.fan} />
        </PaddedCard>
      )}
      {eqOverview?.isESD() && (
        <>
         <PaddedCard>
            <ESDN2Graph />
          </PaddedCard>
          <PaddedCard>
            <ESDTemperatureGraph />
          </PaddedCard>
          <PaddedCard>
            <ESDCycleGraph />
          </PaddedCard>
        </>
      )}
      {eqOverview?.isPump() && (
        <>
          <PaddedCard>
            <SeverityHistory equipmentType={EquipmentType.pump} />
          </PaddedCard>
          <PaddedCard>
            <OilInfoCard />
          </PaddedCard>
          <PaddedCard>
            <SealCard />
          </PaddedCard>
        </>
      )}
      <NavFooter>
        <ButtonStyled
          className="backBtn"
          variant="contained"
          data-testid="submitDiagnosisButton"
          onClick={(e) => {
            history.push('/')
          }}
          startIcon={<LeftArrow />}
        >
          Back to Alerts
        </ButtonStyled>
      </NavFooter>
    </PaddedTabBox>
  )
}

export default ConditionStep
