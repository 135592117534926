import moment from 'moment'
import { getlastUPdatedStringMinsAgo } from '../../../../../../shared/utility'
import { LineStateType } from './run-hour-main'

export interface RunHourPumpOverviewData {
  color: string;
  floc: string;
  actualUtilisationSplit: number;
  cumulativeRunHours: number;
  yearToDayRunHour: number;
  timeOfflineSinceLastActive: string | null;
}
export function allLineLoaded(lineDataState: LineStateType[]): Boolean {
  return Boolean(
    !lineDataState?.filter((r: LineStateType) => r.isLoading).length
  )
}
export function calRunHourPumpOverviewData(
  lines: LineStateType[]
): RunHourPumpOverviewData[] {
  if (allLineLoaded(lines)) {
    let runHourOverView: RunHourPumpOverviewData[]

    const totalcummHourByLine = lines.map((a) => {
      let lifeSum = 0
      let yearTodaySum = 0
      const thisYear = moment().get('year')
      let timeStampSinceLastActive: moment.Moment | null = null
      if (a.data?.response) {
        lifeSum = a.data.response
          .map((a) => a.value)
          .reduce((a, b) => a + b, 0)
        yearTodaySum = a.data.response
          .filter((a) => moment(a.time).get('year') === thisYear)
          .map((a) => a.value)
          .reduce((a, b) => a + b, 0)
        let index = 0

        index = a.data.response.map((a) => a.value > 0).lastIndexOf(true)
        if (index !== a.data.response.length - 1) {
          // if it's not online
          timeStampSinceLastActive = moment(a.data.response[index].time)
        }
      }
      // yearToday run hour

      return {
        color: a.color,
        floc: a.floc,
        lifeSum: lifeSum,
        yearTodaySum: yearTodaySum,
        timeStampSinceLastActive: timeStampSinceLastActive,
      }
    })
    const totalCummHour = totalcummHourByLine
      .map((a) => a.lifeSum)
      .reduce((a, b) => a + b, 0)

     // eslint-disable-next-line prefer-const
     runHourOverView = totalcummHourByLine.map((lineCalculated) => {
      return {
        color: lineCalculated.color,
        floc: lineCalculated.floc,
        actualUtilisationSplit: lineCalculated.lifeSum / totalCummHour,
        cumulativeRunHours: lineCalculated.lifeSum,
        yearToDayRunHour: lineCalculated.yearTodaySum,
        timeOfflineSinceLastActive: lineCalculated.timeStampSinceLastActive
          ? getlastUPdatedStringMinsAgo(
              lineCalculated.timeStampSinceLastActive.unix()
            )
          : null,
      }
    })
    return runHourOverView
  } else {
    return []
  }
}
