import styled from '@emotion/styled'
import {
  DialogContent,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import React from 'react'
import feedbackSuccessImg from '../../../images/feedback-success.png'

export interface FeedbackDialogueSuccessProps {
  open: boolean;
  onClose: () => void;
}
const StyledContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #01c851;
    padding: 4px;
    font-weight: 400;
  }
  p {
    text-align: center;
    color: transparent(0.6);
    font-size: 0.9rem;
  }
`

const FeedbackDialogueSuccess = (props: FeedbackDialogueSuccessProps) => {
  return (
    <Dialog
      aria-labelledby="dialog-feedback-done"
      aria-describedby="dialog-feedback-success"
      open={props.open}
      maxWidth="xs"
      onClose={(e) => {
        props.onClose()
      }}
    >
      <StyledContent>
        <img
          id="dialogue-main-image"
          src={feedbackSuccessImg}
          alt="SuccessImg"
        />
        <h1>Feedback Captured!</h1>
        <DialogContentText id="dialog-feedback-content">
          Thanks for giving us some feedback.
          <br /> Your thoughts have been sent to the team to help improve this
          product.
          <br />
          <br />
        </DialogContentText>
      </StyledContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose()
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackDialogueSuccess
