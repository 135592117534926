import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Box, Divider } from '@mui/material'
import moment from 'moment'
import React from 'react'

import { getKeyFromColor, getlastUPdatedStringMinsAgo, getSAColor, getSAConditionColor } from '../../../../../../shared/utility'

export interface SAToolTipProps {
  inputData: {
    floc: string;
    fanConditionRating: string;
    fanConditionDescription: string;
    fanConditionLastUpdatedUnixTime: number;
    fan: { floc: string; color: string; description: string };
    motor: { floc: string; color: string; description: string };
    starter: {
      floc: string;
      color: string;
      description: string;
    };
    lastUpdatedUnixTime: number;
  };
}

const CardPadding = 0.3
const lastUpdatedRowHeight = 3
const StyledCardPaper = styled(Box)`
  padding: ${CardPadding}rem;
  display: flex;
  flex-direction: column;
  color: black;
  width: 100%;
  gap: 0.5rem;
  font-weight: 400;

  h3 {
    margin-bottom: 0rem;
  }
`


const ConditionText = styled.span(
  css`
    font-size: 0.8rem;
    font-weight: 400;
    #status {
      font-weight: 500;
    }
  `,
  (props: any) => ({
    '#status': {
      color: getSAConditionColor(props.condition).background + ' !important',
      
    },
  })
)

const RowDiv = styled(Box)`
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0;
  div {
    display: flex;
    justify-content: space-between;
  }
`
const LastUpdatedRowDiv = styled(Box)`
  padding: ${CardPadding}rem;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #f5f5f6;
  color: #666666;
  b {
    font-weight: 500;
  }
`
const RowDivFaint = styled(Box)`
  width: 100%;
  font-size: 0.8rem;
  color: #666666;
  font-weight: 400;
`

const PlaceHolder = styled(Box)`
  height: ${lastUpdatedRowHeight - 1}rem;
`

interface LegendProps {
  color: string;
}

const SAToolTip = ({ inputData }: SAToolTipProps) => {
  const Legend = styled(Box)(
    css`
      color: #666666;
    `,
    ({color}: LegendProps) => ({
      '&:before': {
        content: '"\\00b7"',
        color: color
          ? color
          : getSAColor('DATA QUALITY ISSUE').background,

        fontSize: '7rem',
        lineHeight: '1.4rem',
        verticalAlign: 'middle',
      },
    })
  )
  return (
    <StyledCardPaper>
      <h3>{inputData.floc}</h3>
      <ConditionText
        condition={inputData.fanConditionDescription.toLowerCase().trim()}
      >
        Condition {inputData.fanConditionRating} -{' '}
        <span id="status">{inputData.fanConditionDescription}</span>
      </ConditionText>
      <Divider />
      <RowDiv>
        <div>
          {inputData.fan.floc}
          <Legend color={inputData.fan.color}>
            {getKeyFromColor(inputData.fan.color)}
          </Legend>
        </div>
        <RowDivFaint>{inputData.fan.description}</RowDivFaint>
      </RowDiv>
      <Divider />
      <RowDiv>
        <div>
          {inputData.motor.floc}
          <Legend color={inputData.motor.color}>
            {getKeyFromColor(inputData.motor.color)}
          </Legend>
        </div>
        <RowDivFaint>{inputData.motor.description}</RowDivFaint>
      </RowDiv>

      <Divider />
      <RowDiv>
        <div>
          {inputData.starter.floc}
          <Legend color={inputData.starter.color}>
            {getKeyFromColor(inputData.starter.color)}
          </Legend>
        </div>
        <RowDivFaint>{inputData.starter.description}</RowDivFaint>
      </RowDiv>
      <PlaceHolder />
      <LastUpdatedRowDiv>
        <div>
          Machine Condition last updated{' '}
          <b>
            {inputData.fanConditionLastUpdatedUnixTime > 0
              ? Math.ceil(
                  moment
                    .duration(
                      moment().diff(
                        moment.unix(inputData.fanConditionLastUpdatedUnixTime)
                      )
                    )
                    .asHours()
                ) + ' hours ago'
              : ' unknown'}
          </b>
          .
        </div>
        <div>
          Situational Awareness statuses last updated{' '}
          <b>
            {inputData.lastUpdatedUnixTime > 0
              ? getlastUPdatedStringMinsAgo(inputData.lastUpdatedUnixTime)
              : 'unknown'}
          </b>
          .
        </div>
      </LastUpdatedRowDiv>
    </StyledCardPaper>
  )
}
export default SAToolTip