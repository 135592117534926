import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { AuthenticationResult } from '@azure/msal-browser'

import { EquipmentHierarchyObject } from './types/equipmentHierachy'
import { SortableEntity, SortDir, SortKeyEnum } from './types/sortableEntity'
import { ConditionBasedActionCondition } from './types/strategyapi'
import { getISSOWPlantNameFromFloc } from './types/plants'
import { EquipmentType } from './types/equipmentTypes'

export const GlobalDateFormatter = new Intl.DateTimeFormat('en-AU', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
})

export const GlobalTimeFormatter = new Intl.DateTimeFormat('en-AU', {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
})

export const GlobalTimeFormatterHHMM = new Intl.DateTimeFormat('en-AU', {
  hour: 'numeric',
  minute: 'numeric'
})

export function toTitleCase(str: string) {
  if (!str) {
    return str
  }
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function getISSOWEnvBasedonFloc(
  floc: string,
  plantId?: 'AU01' | 'AU21'
): string {
  if (getISSOWPlantNameFromFloc(floc) === 'AU01' || plantId === 'AU01') {
    return process.env.REACT_APP_A_PERMIT_URL_KGP!
  } else {
    return process.env.REACT_APP_A_PERMIT_URL_PLU!
  }
}

export function getlastUPdatedStringMinsAgo(unix: number) {
  const diff = moment.duration(moment().diff(moment.unix(unix)))
  if (diff.asMinutes() < 120) {
    return `${Math.ceil(diff.asMinutes())} mins ago`
  } else if (diff.asHours() < 24) {
    return `${Math.ceil(diff.asHours())} hours ago`
  }
  return `${Math.ceil(diff.asDays())} day${
    Math.ceil(diff.asDays()) === 1 ? '' : 's'
  } ago`
}

export const walkFlocTree = (
  node: EquipmentHierarchyObject,
  root: string[]
) => {
  // 'nodes to shout out about'
  if (node.floc) {
    root.push(node.floc)
  }
  for (let i = 0; i < node.children.length; i++) {
    walkFlocTree(node.children[i], root)
  }
  return root // note that this is not a tail recussion, thus stack memory could be bad if too large
}

export function useParamQuery() {
  return new URLSearchParams(useLocation().search)
}

export const getFlatFlocTreeWithDesc = (
  node: EquipmentHierarchyObject,
  root: [{ floc: string; desc: string }]
) => {
  // 'nodes to shout out about'
  root.push({ floc: node.floc, desc: node.attributes.service })
  for (let i = 0; i < node.children.length; i++) {
    getFlatFlocTreeWithDesc(node.children[i], root)
  }
  return root // note that this is not a tail recussion, thus stack memory could be bad if too large
}

export function getDescByFloc(
  floc: string,
  flocWithDesc: [{ floc: string; desc: string }]
) {
  const find = flocWithDesc.filter((row) => row.floc === floc)
  if (find.length) {
    return find[0].desc
  } else {
    return 'Unknown'
  }
}

const reA = /[^a-zA-Z]/g
const reN = /[^0-9]/g
export function compareAlphaNumeric(a: string, b: string) {
  const aA = a.replace(reA, '')
  const bA = b.replace(reA, '')
  if (aA === bA) {
    const aN = parseInt(a.replace(reN, ''), 10)
    const bN = parseInt(b.replace(reN, ''), 10)
    return aN === bN ? 0 : aN > bN ? 1 : -1
  } else {
    return aA > bA ? 1 : -1
  }
}

export function checkUserCanActionOnAnomaly(
  token: AuthenticationResult | undefined | null
) {
  if (!token) {
    return false
  }

  const idToken = token.idTokenClaims as any
  if (idToken.roles && idToken.roles.length) {
    if ((idToken.roles).includes('Anomaly.Action')) {
      return true
    }
  }
  return false
}

/**
 * A common sorting algorithm to sort a list of objects by a property.
 * It accepts an optional boolean property to tell the algorithm to use an alphanumeric function to compare the properties. E.g. for floc
 * @param elList The list of objects to sort
 * @param sortKey The name of the property to sort on
 * @param sortDir The direction of sorting
 * @param compareFn An optional flag to tell to use an alphanumeric compare
 */
export function sortByObjectProperty<T extends SortableEntity>(
  elList: T[],
  sortKey: SortKeyEnum,
  sortDir: SortDir,
  useAlphaNumbericCompare = false
): T[] {
  const sortDirFactor = sortDir === 'asc' ? 1 : -1
  return elList.sort((a, b) => {
    const valA = a.getSortableField(sortKey)
    const valB = b.getSortableField(sortKey)
    return (
      sortDirFactor *
      (useAlphaNumbericCompare
        ? compareAlphaNumeric(valA, valB)
        : valA > valB
        ? 1
        : -1)
    )
  })
}

/**
 * Parse verified_secondary_email into WopID
 * @param token the token response from useResourcingToken, AuthenticationResult is the type
 * @returns WopID if found, or email truncated to 11 char max to suit SAP
 */
export function getWopIdOREmail(token?: AuthenticationResult) {
  let response = 'unknown'
  if (token) {
    if (token.account && token.account.idTokenClaims) {
      if (
        Object.keys(token.account.idTokenClaims).includes(
          'verified_secondary_email'
        )
      ) {
        // have wopid email
        const emails: string[] = (token.account.idTokenClaims as any)
          .verified_secondary_email
        // woodsideenergy.mail.onmicrosoft.com
        const email = emails.filter((e) =>
          e.includes('woodsideenergy.mail.onmicrosoft.com')
        )
        if (email.length && email[0].toUpperCase().startsWith('W')) {
          // found it
          response = email[0].split('@')[0]
        } else {
          console.debug(
            'can\'t infer wop from email, debug token: ',
            (token.account.idTokenClaims as any).verified_secondary_email
          )
          response = token.account.username
        }
      } else {
        console.debug(
          'can\'t find verified_secondary_email, debug token: ',
          token.account.idTokenClaims
        )
        response = token.account!.username
      }
    } else {
      response = token.account!.username
    }
  }

  return response.slice(0, 12)
}

export function getSAConditionColor(
  conditionText:
    | 'unacceptable'
    | 'unsatisfactory'
    | 'satisfactory'
    | 'good'
    | string
): { background: string; text: string } {
  switch (conditionText?.toLowerCase()?.trim()) {
    case 'unacceptable':
      return { background: '#D71638', text: 'white' }
    case 'unsatisfactory':
      return { background: '#EF6C00', text: 'white' }
    case 'satisfactory':
      return { background: '#FFA726', text: 'white' }
    case 'good':
      return { background: '#B85828', text: 'white' }
    default:
      return { background: '#ADADAE', text: 'black' }
  }
}

export enum SAMeasurementKeys {
  IndividualStatus = 'MEASUREMENT_SITUATIONAL_AWARENESS_STATUS', // indivdiual (K,KM,Filter)
  MachineCondition = 'MEASUREMENT_SITUATIONAL_AWARENESS_MACHINE_CONDITION', // AML
  GroupStatus = 'MEASUREMENT_SITUATIONAL_AWARENESS_GROUP_STATUS', // group level (K Group)
}

// Link to color explainations
// https://confluence.woodside.com.au/display/NCDS/SA+-+Front-end+Mock-up
enum SAColorDefinition {
  'EQUIPMENT OK' = '#A1F395',
  'EQUIPMENT HAS FAULTS' = '#FFD480',
  'DATA QUALITY ISSUE' = '#9C27B0',
  'OUT OF SERVICE' = '#F44336',
  'ONLINE WITH FAULTS' = '#FFBB34',
  'ONLINE ACTIVE PERMIT' = '#FFEB3B',
  'EQUIPMENT HAS ACTIVE PERMIT' = '#B2B2B4',
  'ONLINE' = '#01C851',
  'ONLINE EMERGENCY USE ONLY' = '#FB8C00',
  'STANDBY (EMERGENCY USE ONLY)' = '#BCE5F8',
  'STANDBY WITH FAULTS' = '#84C1DC',
  'STANDBY ACTIVE PERMIT' = '#388EB5',
  'STANDBY' = '#005F8A',
  'EQUIPMENT ISOLATED' = '#004869',
  'BREAKDOWN NOTIFICATION' = '#F7776E',
}

export function getSAColor(
  saText: string
): { background: string; text: string } {
  switch (saText?.toLowerCase()?.trim()) {
    case 'equipment ok':
      return { background: '#A1F395', text: 'white' }
    case 'equipment has faults':
      return { background: '#FFD480', text: 'rgba(0, 0, 0, 0.87)' }
    case 'data quality issue':
      return { background: '#9C27B0', text: 'white' }
    case 'out of service':
      return { background: '#F44336', text: 'white' }
    case 'online with faults':
      return { background: '#FFBB34', text: 'white' }
    case 'online active permit':
      return { background: '#FFEB3B', text: 'rgba(0, 0, 0, 0.87)' }
    case 'equipment has active permit':
      return { background: '#B2B2B4', text: 'rgba(0, 0, 0, 0.87)' }
    case 'online':
      return { background: '#01C851', text: 'white' }
    case 'online emergency use only':
      return { background: '#FB8C00', text: 'white' }
    case 'standby (emergency use only)':
      return { background: '#BCE5F8', text: 'rgba(0, 0, 0, 0.87)' }
    case 'standby with faults':
      return { background: '#84C1DC', text: 'rgba(0, 0, 0, 0.87)' }
    case 'standby active permit':
      return { background: '#388EB5', text: 'rgba(0, 0, 0, 0.87)' }
    case 'standby':
      return { background: '#005F8A', text: 'white' }
    case 'equipment isolated':
      return { background: '#004869', text: 'white' }
    case 'breakdown notification':
      return { background: '#F7776E', text: 'white' }
    default:
      return { background: '#A1F395', text: 'white' }
  }
}

export const getKeyFromColor = (colorValue: string) => {
  return toTitleCase(
    Object.keys(SAColorDefinition).filter((key) => {
      return (
        `${SAColorDefinition[key as keyof typeof SAColorDefinition]}` ===
        colorValue
      )
    })[0]
  )
}

// Global Banding Colors
export const ChartBandingColors = [
  'rgba(244, 67, 54, 0.2)', // highest
  'rgba(247, 122, 55, 0.2)',
  'rgba(249, 177, 57, 0.2)',
  'rgba(255, 229, 0, 0.2)',
  'white', // lowest
]

const TableBandingColors = [
  ['#D71638', 'Very High'], // highest
  ['#E46962', 'High'],
  ['#F58220', 'Medium'],
  ['#FFB400', 'Low'],
  ['#6FCA73', 'OK'], // lowest
]

export function parseBandingInfo(
  conditionBasedActionConditions: ConditionBasedActionCondition[]
) {
  const alertConfigs = conditionBasedActionConditions.filter(
    (c) => c.conditionName === 'VibrationSmartAlerting'
  )

  if (alertConfigs.length) {
    const limits = alertConfigs[0].conditionBasedActionConditionProperty.filter(
      (property) =>
        property.propertyName.startsWith('band') &&
        property.propertyName.endsWith('Limit')
    )
    //1, 0.8, 0.65, 0.5, 0.4 example response
    return limits.map((r) => parseFloat(r.propertyValue)).sort((a, b) => b - a)
  } else return null
}

// [color,text]
export function getBandingColor(bandsArray?: number[], value?: any): string[] {
  // index = 0
  if (!value) {
    return ['#D9D9DC', 'Unknown']
  }
  if (!bandsArray) {
    // use default
    return getBandingColor([0.25, 0.5, 0.75, 0.9, 1], value)
  }
  // who ever build javascript decide to write non-pure function sort is pure evil
  const bandsSorted = [...bandsArray].sort((a, b) => a - b)
  try {
    for (let i = 0; i < bandsSorted.length; i++) {
      const upperBandValue = bandsSorted[i]
      if (value < upperBandValue) {
        return TableBandingColors[TableBandingColors.length - 1 - i]
      }
    }
    return ['#D9D9DC', 'Unknown']
  } catch (e) {
    // use default
    return getBandingColor([0.25, 0.5, 0.75, 0.9, 1], value)
  }
}

export function getUIEquipmentTypeFromFuseEquipmentType(
  fuseEqType?: string
): EquipmentType {
  let type = EquipmentType.fan
  if (fuseEqType) {
    const fuseEqTypeCaps = fuseEqType?.toUpperCase()
    if (
      fuseEqTypeCaps.includes('CENTRIFUGAL') ||
      fuseEqTypeCaps.includes('PUMP')
    ) {
      type = EquipmentType.pump
    } else if (fuseEqTypeCaps === 'FAN' || fuseEqTypeCaps === 'MOTOR, AC') {
      type = EquipmentType.fan
    } else if (fuseEqTypeCaps.indexOf('FILTER') >= 0) {
      type = EquipmentType.filter
    } else if (fuseEqTypeCaps === 'PACKAGED EQUIPMENT' || fuseEqTypeCaps === 'ESD') {
      // ESD is currently hardcoded in AlertEngine due to missing integration with EQ360 
      type = EquipmentType.esd
    }
  }
  return type
}

export const getInitials = (fullName: string): string => {
  if (!fullName) {
    return fullName
  }
  const names = fullName.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()
  if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}