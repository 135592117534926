import styled from '@emotion/styled'
import { Box, Collapse, Theme, Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'
import React, { memo, useEffect, useState } from 'react'

import { ItemLatestMeasurementsResponse, Measurement } from '../../../../../../shared/types/getOverviewResponse'
import { getDescByFloc, getKeyFromColor, getSAColor, SAMeasurementKeys } from '../../../../../../shared/utility'
import { useWindowSize } from '../../../../context-panel/floc-panel/useWindowResize'
import SAToolTip, { SAToolTipProps } from './tooltip'

interface ExpandableProps {
  expanded: boolean;
}

// use to tell whether this child from getItemLatestMeasurements is a motor or a starter, so that tooltip can show the correct data
enum KeyWordInDescToMatchComponent {
  Motor = 'MOTOR,', //Description if it's motor: "MOTOR,PROPANE CONDENSOR FAN,1E1441KXX", we match on this
  STARTER = 'START,', //Description if it's STARTER: "START,FIN FAN MOTOR,1E1441KMXX", we match on this
}

const CONUnknownString = 'Cond * - Unknown'

const PaperScrollable = styled(Box)`
  overflow: auto;
  border: 1px solid #b2b2b4;
  border-radius: 0.8rem;
  padding: 0.4rem;
  margin-bottom: 0.4rem;
`

const CircleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  place-content: ${(props: any) => (props['data-scroll'] ? 'unset' : 'center')};
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  max-height: 180px;
  a {
    color: white;
  }
`

const FillerRight = styled(Box)`
  /*See https://www.brunildo.org/test/overscrollback.html for this known issue*/
  flex: 1 0 1;
  width: 0.4rem;
  height: 40px;
`
interface CirlceMetaData {
  // group status
  color: string; //'#01C851';
  textColor: string;
  condition: { value: string; timestamp: number } | string; //{ value: 'ONLINE'; timestamp: 1603940400 };
  display: string; //'K1';
  floc: string; //'AU21.1E1441K1';
  order: number; // '1';
  originalData: ItemLatestMeasurementsResponse;
  toolTipData: SAToolTipProps['inputData'];
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    minWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[1],
  },
}))(Tooltip)

export interface SituationAwarenessViewProps extends ExpandableProps {
  inputData: ItemLatestMeasurementsResponse;
  finFanDeckLabel: string;
  flocMetaDataForDesc: [{ floc: string; desc: string }];
}

const SituationAwarenessView = memo((props: SituationAwarenessViewProps) => {
  const [fanData, setFanData] = useState<ItemLatestMeasurementsResponse[]>()
  const [circleInputData, setCircleInputData] = useState(
    [] as CirlceMetaData[]
  )
  const [legend, setLegend] = useState(null as any)
  const [scroll, setScroll] = useState(false)
  const ref = React.createRef<any>()
  const windowSize = useWindowSize()
  React.useLayoutEffect(() => {
    if (ref.current.clientWidth < ref.current.scrollWidth) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }, [ref.current, windowSize, circleInputData])

  interface CircleProps {
    textColor?: string;
    color?: string;
  }

  const Circle: any = styled(Box)`
    flex: 1 0 1;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: ${({color}: CircleProps) =>
      color ? color : getSAColor('DATA QUALITY ISSUE').background};
    margin: 0.4rem;
    z-index: 10;
    transition: 300ms;
    a {
      color: ${({textColor}: CircleProps) =>
        textColor
          ? textColor
          : getSAColor('DATA QUALITY ISSUE').text};
    }
    text-align: center;
    &:hover {
      font-size: 110%;
      transition: 300ms;
      font-weight: bold;
      box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.2);
    }
    padding: 10px 0 0 1px;
  `

  const HeaderTitle = styled.span`
    display: block;
    width: fit-content;
    color: #666666;
    height: fit-content;
    position: relative;
    bottom: -0.7rem;
    font-size: 16px;
    z-index: 1;
    text-align: ${(props: ExpandableProps) =>
      props.expanded ? 'center' : 'left'};
    background: white;
    margin: ${(props: ExpandableProps) =>
      props.expanded ? 'auto auto' : '0 0 1rem 0'};
  `

  const LegendFlexBox = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    flex-wrap: wrap;
    justify-content: start;
    height: ${(props: ExpandableProps) => (props.expanded ? 'auto' : 'auto')};
    padding-top: 0.3rem;
    width: 100%;
  `

  interface LegendProps extends ExpandableProps {
    color: string;
  }

  const Legend = styled(Box)`
    &:before {
      margin-left: ${(props: LegendProps) =>
        props.expanded ? '0' : '-0.5rem'};
      content: '\\00b7';
      color: ${({color}: LegendProps) =>
        color ? color : getSAColor('DATA QUALITY ISSUE').background};
      font-size: 8rem;
      line-height: 1rem;
      vertical-align: middle;
    }
  `
  const cleanInput = (
    fanLevelFlocData: ItemLatestMeasurementsResponse[],
    finFanDeckFloc: string
  ): CirlceMetaData[] => {
    if (finFanDeckFloc.indexOf('.') < 0) {
      // deck must be in format of AUxx.xxxxx
      throw 'Fin Fan Deck SA\'s Floc must include \'AU.\' in front of the floc'
    }
    const cleanedData = [] as CirlceMetaData[]
    const flocTester = new RegExp('K[0-9]+$')

    for (const dataPoint of fanLevelFlocData) {
      let groupLevelData = dataPoint.measurements.filter(
        (flocKmeasurement) =>
          flocKmeasurement.key === SAMeasurementKeys.GroupStatus
      )
      // if not found, go search the children
      if (!groupLevelData.length) {
        const childMeasures: any = dataPoint.children.map((c) => [
          ...c.measurements,
        ])
        const flatChild = [].concat(...childMeasures)

        groupLevelData = flatChild.filter(
          (flocKmeasurement: Measurement) =>
            flocKmeasurement.key === SAMeasurementKeys.GroupStatus
        )
      }
      const firstCondition =
        groupLevelData.length && groupLevelData[0].values.length
          ? groupLevelData[0].values[0].value
          : null

      const fanLevelStatusData = dataPoint.measurements.filter(
        (flocKmeasurement) =>
          flocKmeasurement.key === SAMeasurementKeys.IndividualStatus
      )

      const fanLevelMachineConditionData = dataPoint.measurements.filter(
        (flocKmeasurement) =>
          flocKmeasurement.key === SAMeasurementKeys.MachineCondition
      )
      let motorDataPoint: ItemLatestMeasurementsResponse[] | null = null
      let starterDataPoint: ItemLatestMeasurementsResponse[] | null = null
      if (dataPoint.children.length) {
        motorDataPoint = dataPoint.children.filter((child) => {
          const desc = getDescByFloc(child.floc, props.flocMetaDataForDesc)
          return desc && desc.startsWith(KeyWordInDescToMatchComponent.Motor)
        })
        starterDataPoint = dataPoint.children.filter((child) => {
          const desc = getDescByFloc(child.floc, props.flocMetaDataForDesc)

          return desc && desc.startsWith(KeyWordInDescToMatchComponent.STARTER)
        })
      }
      const motorLevelStatusData = motorDataPoint?.length
        ? motorDataPoint[0].measurements.filter(
            (flocKmeasurement) =>
              flocKmeasurement.key === SAMeasurementKeys.IndividualStatus
          )
        : null

      const StarterData = starterDataPoint?.length
        ? starterDataPoint[0].measurements.filter(
            (flocKmeasurement) =>
              flocKmeasurement.key === SAMeasurementKeys.IndividualStatus
          )
        : null

      const fullConditionString =
        fanLevelMachineConditionData.length &&
        fanLevelMachineConditionData[0].values
          ? fanLevelMachineConditionData[0].values[0].value
          : CONUnknownString // default to unknown
      const toolTipData: SAToolTipProps['inputData'] = {
        floc: dataPoint.floc,
        fanConditionRating: fullConditionString.split('-')[0].split(' ')[1],
        fanConditionDescription: fullConditionString.split('-')[1],
        fanConditionLastUpdatedUnixTime:
          fullConditionString !== CONUnknownString
            ? fanLevelMachineConditionData[0].values[0].timestamp
            : -1,
        lastUpdatedUnixTime: firstCondition
          ? groupLevelData[0].values[0].timestamp
          : -1,
        fan: {
          floc: dataPoint.floc,
          color:
            fanLevelStatusData.length && fanLevelStatusData[0].values
              ? getSAColor(fanLevelStatusData[0].values[0].value).background
              : getSAColor('Data Quality Issue').background,
          description: getDescByFloc(dataPoint.floc, props.flocMetaDataForDesc),
        },
        motor: {
          floc: motorDataPoint?.length ? motorDataPoint[0].floc : 'Unknown',
          color:
            motorLevelStatusData &&
            motorLevelStatusData.length &&
            motorLevelStatusData[0].values
              ? getSAColor(motorLevelStatusData[0].values[0].value).background
              : getSAColor('Data Quality Issue').background,
          description: getDescByFloc(
            motorDataPoint?.length ? motorDataPoint[0].floc : '',
            props.flocMetaDataForDesc
          ),
        },
        starter: {
          floc: starterDataPoint?.length ? starterDataPoint[0].floc : 'Unknown',
          color:
            StarterData && StarterData.length && StarterData[0].values
              ? getSAColor(StarterData[0].values[0].value).background
              : getSAColor('Data Quality Issue').background,
          description: getDescByFloc(
            starterDataPoint?.length ? starterDataPoint[0].floc : '',
            props.flocMetaDataForDesc
          ),
        },
      }

      const newCircle = {
        floc: `${dataPoint.floc}`,
        display: `${flocTester.exec(dataPoint.floc)![0]}`,
        condition: firstCondition ? firstCondition : 'No Value',
        color: firstCondition
          ? getSAColor(firstCondition).background
          : getSAColor('Data Quality Issue').background,
        textColor: firstCondition
          ? getSAColor(firstCondition).text
          : getSAColor('Data Quality Issue').text,
        order: parseInt(flocTester.exec(dataPoint.floc)![0].split('K')[1]),
        originalData: dataPoint,
        toolTipData: toolTipData,
      } as CirlceMetaData
      if (firstCondition && !getSAColor(firstCondition)) {
        newCircle.color = getSAColor('Data Quality Issue').background
      }

      cleanedData.push(newCircle)
    }

    return cleanedData.sort((a: any, b: any) => a.order - b.order)
  }

  // prepare the SA map count
  useEffect(() => {
    if (props.inputData) {
      const flocTester = new RegExp('K[0-9]+$')
      const flocs = props.inputData.children.filter((floc: any) => {
        return flocTester.test(floc.floc)
      })
      setFanData(flocs)
    }
  }, [props.inputData])

  // prepare the SA map
  useEffect(() => {
    if (fanData) {
      setCircleInputData(cleanInput(fanData, props.finFanDeckLabel))
    }
  }, [fanData])

  // prepare the SA map lengend
  useEffect(() => {
    //code for build legend
    if (circleInputData) {
      const legend = circleInputData
        .map((row) => row.color)
        .reduce((total, value) => {
          total[value] = (total[value] || 0) + 1
          return total
        }, {})
      const sortedLegend: any = []
      for (const row in legend) {
        sortedLegend.push([row, legend[row]])
      }
      sortedLegend.sort(function (a: any, b: any) {
        return b[1] - a[1]
      })
      setLegend(sortedLegend)
    }
  }, [circleInputData])

  return (
    <>
      <HeaderTitle expanded={props.expanded}>
        {props.finFanDeckLabel} {' Bank Summary'}
      </HeaderTitle>
      <Collapse timeout={500} in={props.expanded}>
        <PaperScrollable ref={ref}>
          <CircleContainer data-scroll={scroll}>
            {circleInputData.map((value, index) => {
              return (
                <HtmlTooltip
                  enterDelay={500}
                  arrow
                  key={value.floc}
                  title={<SAToolTip inputData={value.toolTipData} />}
                >
                  <Circle
                    color={value.color}
                    textColor={value.textColor}
                    key={value.floc}
                  >
                    <a
                      key={value.floc}
                      href={`/floc/detail?floc=${value.floc}`}
                      target="_blank"
                      style={{ textDecoration: 'none' }} rel="noreferrer"
                    >
                      {value.display}
                    </a>
                  </Circle>
                </HtmlTooltip>
              )
            })}
            {/* Can add check for scrolling then disable this filler */}
            <FillerRight />
          </CircleContainer>
        </PaperScrollable>
      </Collapse>
      <LegendFlexBox expanded={props.expanded}>
        {legend
          && legend.map(
              (row: string[], index: string | number | null | undefined) => (
                <Legend color={row[0]} key={index} expanded={props.expanded}>
                  <span>
                    {row[1]} {getKeyFromColor(row[0])}
                  </span>
                </Legend>
              )
            )}
      </LegendFlexBox>
    </>
  )
})

export default SituationAwarenessView
