import styled from '@emotion/styled'
import React, { useEffect, useState, useMemo, ChangeEvent } from 'react'
import { Box, Link, Tab, Tabs, Theme, Tooltip } from '@mui/material'
import { withStyles, createStyles, makeStyles } from '@mui/styles'

import useEquipmentOverview from '../../services/hooks/useEquipmentOverview'
import { ReactComponent as WarningIcon } from '../../icons/exclamation-triangle-filled.svg'
import { checkUserCanActionOnAnomaly, useParamQuery } from '../../shared/utility'
import ContextPanel from './context-panel/component'
import { ReactComponent as AirPlaneIcon } from '../../icons/plane.svg'
import { ReactComponent as HeartIcon } from '../../icons/heart.svg'
import { ReactComponent as ContextIcon } from '../../icons/contextIcon.svg'
import { useUserTokenContext } from '../../shared/providers/user-token-provider'
import { useLoggingContext } from '../../shared/providers/spa-logging-provider'
import { EquipmentOverviewContext } from '../../shared/providers/floc-context-provider'
import InvalidFloc from './invalid-floc/invalid-floc'
import ContextStep from './context-tab/component'
import ResponseStep from './response-tab/component'
import ConditionStep from './condition-tab/component'
import { PaddedCard } from '../../shared/components/padded-card'
import { PaddedTabBox } from '../../shared/components/padded-box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
    },
    mainContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)

function a11yProps(index: any) {
  return {
    id: `floc-review-tab-${index}`,
    'aria-controls': `floc-review-tabpanel-${index}`,
  }
}

const TabStyled = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 500,
    fontSize: 15,
    minWidth: 160,
    opacity: '0.60',
    color: '#001543',
    '&$selected': {
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  wrapper:{
    display: 'block',
  },
  selected: {
    color: '#001543'
  },
}))(Tab)

const TabsStyled = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .12)',
  },
  indicator: {
    height: '0.15rem',
    backgroundColor: '#00354D',
    marginLeft: '15px',
  },
})(Tabs)

const NoAccessToolTip = styled(Box)`
  font-size: 0.7rem;
  a {
    color: white;
    text-decoration: underline;
  }
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;

  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  #inline {
    a {
      margin: 0;
      display: inline;
    }
  }
`

const AnomalyReview = () => {
  const classes = useStyles()
  const query = useParamQuery()
  const adhoc = query.get('adhoc')
  const floc = query.get('floc')
  const [tabSelectionValue, setTablSelectionValue] = useState<0 | 1 | 2>(adhoc! === 'true' ? 2 : 0)
  const tokenContext = useUserTokenContext()
  const loggingContext = useLoggingContext()

  const handleChange = (
    event: ChangeEvent<any>,
    newTabValue: 0 | 1 | 2
  ) => {
    setTablSelectionValue(newTabValue)
  }

  const { data: eqOverviewData, error: eqOverviewError, isLoading: eqOverviewLoading } = useEquipmentOverview(floc!)

  useEffect(() => {
    if (adhoc) {
      setTablSelectionValue(2)
    }
  }, [adhoc])

  useMemo(() => {
    loggingContext.log('INFO', 'At Anomaly Page ' + tabSelectionValue)
  }, [tabSelectionValue])

  return (
    <Box className={classes.root}>
      <EquipmentOverviewContext.Provider value={eqOverviewData}>
        <ContextPanel />
        <Box className={classes.mainContent} data-testid="detailStep">
          <TabsStyled
            value={tabSelectionValue}
            onChange={handleChange}
            aria-label="Nav Tabs"
          >
            <TabStyled
              label={'CONDITION'}
              icon={<HeartIcon style={{ height: '1rem' }} />}
              iconPosition="start"
              {...a11yProps(0)}
              wrapped
            />
            <TabStyled
              disabled={eqOverviewData?.isESD()}
              label={'CONTEXT'}
              icon={<ContextIcon style={{ height: '1rem' }} />}
              iconPosition="start"
              {...a11yProps(1)}
            />
            <TabStyled
              icon={
                checkUserCanActionOnAnomaly(tokenContext) ? (
                  <AirPlaneIcon style={{ height: '1rem' }} />
                ) : (
                  <Tooltip
                    enterDelay={300}
                    title={
                      <NoAccessToolTip>
                        <WarningIcon
                          style={{
                            width: '0.8rem',
                            marginRight: '2px',
                            color: '#FFEB3B',
                          }}
                        />
                        You do not have access to submit maintenance responses.
                        Request access{' '}
                        <Link
                          href={'https://ssm-saviyntcloud.woodside.com.au/ECM'}
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </Link>
                        .
                      </NoAccessToolTip>
                    }
                  >
                    <AirPlaneIcon style={{ height: '1rem'}} />
                  </Tooltip>
                )
              }
              label={'RESPONSE'}
              disabled={!checkUserCanActionOnAnomaly(tokenContext)}
              {...a11yProps(2)}
              iconPosition="start"
            />
          </TabsStyled>
          {!eqOverviewLoading && (
            <Box height="100%">
              {eqOverviewError && (
                <InvalidFloc
                  floc={floc!}
                  isValid={false}
                  isOnboarded={false}
                />
              )}
              {eqOverviewData && (
                <>
                  <ConditionStep
                    data-testid="conditionStep"
                    handleNextPage={() => {
                      setTablSelectionValue(1)
                    }}
                    currentTab={tabSelectionValue}
                  />
                  <ContextStep
                    data-testid="contextStep"
                    handleNextPage={() => {
                      setTablSelectionValue(2)
                    }}
                    currentTab={tabSelectionValue}
                  />
                  {process.env.REACT_APP_ECC_DECOMMISSIONING === 'false' && (
                    <ResponseStep currentTab={tabSelectionValue} />
                  )}
                  {process.env.REACT_APP_ECC_DECOMMISSIONING === 'true' && (
                    <PaddedTabBox hidden={tabSelectionValue !== 2}>
                      <PaddedCard>
                        <ErrorDiv data-testid="ecc-decommissioning">
                          <Box id="inline">
                            We are migrating CBM to SAP 4 Hana and the ability to create Maintenance Requests will not be available until this is complete. In the meantime, please create a Work Order directly in SAP.
                          </Box>
                        </ErrorDiv>
                      </PaddedCard>
                    </PaddedTabBox>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </EquipmentOverviewContext.Provider>
    </Box>
  )
}

export default AnomalyReview
