import React from 'react'
import { Dialog, IconButton, TextField, Theme, Typography, Divider, Box } from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'

import MaintenanceJob from '../../../../shared/types/maintenanceJob'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(2),
    },
    // For card headings
    body1: {
      fontSize: '2rem',
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    // TODO investigate if we can center it instead of overflow, in MDPI screen it looks bad
  },
}))(MuiDialogContent)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

const TextFieldStyled = withStyles((theme: Theme) => ({
  root: {},
}))(TextField)

// Starting of class -------------------
export interface DialogueProps {
  handleClose: () => void;
  inputJobData: MaintenanceJob;
  open: boolean;
}

export interface DialogueState {}

class JobDetailDialogue extends React.Component<DialogueProps, DialogueState> {
  constructor(props: DialogueProps) {
    super(props)
    this.state = {}
  }
  Textp = styled.p`
    font-size: 1em;
  `
  ShortTextP = styled.p`
    font-size: 1rem;
    width: 100%;
    padding-bottom: 0.5rem;
    @media (max-height: 1000px) {
      padding-bottom: 0.2rem;
    }
  `
  HeadingH4 = styled.h4`
    font-size: 1em;
    font-weight: 400;
    opacity:0.7;
    width: 100%;
    @media (max-height: 1000px) {
      margin-bottom: 0.1rem;
    }
  `
  FlexContentContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0.5em;
    margin-top: 1rem;
  `
  TextPair = styled(Box)`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
  `

  getMaintenanceExecutionSection() {
    const isHistorical = this.props.inputJobData.closedDate ? true : false
    if (!isHistorical) {
      return (
        <>
          <this.TextPair>
            <this.HeadingH4>Latest Status:</this.HeadingH4>
            <this.Textp>{this.props.inputJobData.jobStatus}</this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Latest Priority:</this.HeadingH4>
            <this.Textp>
              {this.props.inputJobData.jobPriority
                ? this.props.inputJobData.jobPriority
                : 'N/A'}
            </this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Maintenance Basic Start Date:</this.HeadingH4>
            <this.Textp>{this.props.inputJobData.StartDate}</this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Maintenance Basic End Date:</this.HeadingH4>
            <this.Textp>
              {this.props.inputJobData.woBasicEndDate
                ? this.props.inputJobData.woBasicEndDate
                : 'N/A'}
            </this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Work Order Type:</this.HeadingH4>
            <this.Textp>
              {this.props.inputJobData.workorderType
                ? this.props.inputJobData.workorderType
                : 'N/A'}
            </this.Textp>
          </this.TextPair>
        </>
      )
    } else {
      return (
        <>
          <this.TextPair>
            <this.HeadingH4>Latest Status:</this.HeadingH4>
            <this.Textp>{this.props.inputJobData.jobStatus}</this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Final Confirmation Date:</this.HeadingH4>
            <this.Textp>{this.props.inputJobData.closedDate!}</this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Planned Start Date:</this.HeadingH4>
            <this.Textp>{this.props.inputJobData.StartDate}</this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Planned End Date:</this.HeadingH4>
            <this.Textp>
              {this.props.inputJobData.woBasicEndDate
                ? this.props.inputJobData.woBasicEndDate
                : 'N/A'}
            </this.Textp>
          </this.TextPair>
          <this.TextPair>
            <this.HeadingH4>Work Order Type:</this.HeadingH4>
            <this.Textp>
              {this.props.inputJobData.workorderType
                ? this.props.inputJobData.workorderType
                : 'N/A'}
            </this.Textp>
          </this.TextPair>
        </>
      )
    }
  }

  render() {
    const notiLink = `${process.env.REACT_APP_SAP_WEBGUI}?~transaction=*IW23%20RIWO00-QMNUM=${this.props.inputJobData.notificationId}`
    const woLink = `${process.env.REACT_APP_SAP_WEBGUI}?~transaction=*IW33%20CAUFVD-AUFNR=${this.props.inputJobData.workorderId}`
    return (
      <DialogStyled
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle onClose={this.props.handleClose} id="form-dialog-title">
          {this.props.inputJobData.notificationShortText}
        </DialogTitle>
        <DialogContent>
          <this.FlexContentContainer>
            <this.TextPair>
              <this.HeadingH4>FLOC:</this.HeadingH4>
              <this.Textp>{this.props.inputJobData.floc}</this.Textp>
            </this.TextPair>
            <this.TextPair>
              <this.HeadingH4>Identification Date:</this.HeadingH4>
              <this.Textp>
                {this.props.inputJobData.identificationDate}
              </this.Textp>
            </this.TextPair>
          </this.FlexContentContainer>
          <Divider />
          <this.FlexContentContainer>
            {this.getMaintenanceExecutionSection()}
          </this.FlexContentContainer>
          <Divider />
          <this.FlexContentContainer>
            <this.TextPair>
              <this.HeadingH4>Notification No.:</this.HeadingH4>
              <this.Textp>
                {this.props.inputJobData.notificationId ? (
                  <a href={notiLink} target="_blank" rel="noreferrer">
                    {this.props.inputJobData.notificationId}
                  </a>
                ) : (
                  'N/A'
                )}
              </this.Textp>
            </this.TextPair>
            <this.TextPair>
              <this.HeadingH4>Work Order No.:</this.HeadingH4>
              <this.Textp>
                {this.props.inputJobData.workorderId ? (
                  <a href={woLink} target="_blank" rel="noreferrer">
                    {this.props.inputJobData.workorderId}
                  </a>
                ) : (
                  'N/A'
                )}
              </this.Textp>
            </this.TextPair>
            <this.HeadingH4>Notification Long Text:</this.HeadingH4>
            <TextFieldStyled
              InputProps={{
                readOnly: true,
              }}
              value={
                this.props.inputJobData.notificationLongText
                  ? this.props.inputJobData.notificationLongText.replace(
                      /(\r\n|\n|\r)+/gm,
                      '\n'
                    )
                  : 'No data to show'
              }
              fullWidth
              multiline={true}
              maxRows={8}
            />
          </this.FlexContentContainer>
        </DialogContent>
      </DialogStyled>
    )
  }
}

export default JobDetailDialogue
