import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apm } from '@elastic/apm-rum'

import useActiveAlertList from '../../features/anomaly-list/hooks/useActiveAlertList'
import useEquipmentOverview from '../../services/hooks/useEquipmentOverview'
import { useParamQuery } from '../utility'
import useCBMEquipmentGroup from '../../services/hooks/useCBMEquipmentGroup'

interface DetailAlertGuardProps {
  children: any;
  isValidCallback?: (valid: boolean, currentFloc?: string) => boolean | void;
}

const ValidFlocGuard = ({ children, isValidCallback }: DetailAlertGuardProps) => {
  const query = useParamQuery()
  const queryFloc = query.get('floc')
  const [floc, setFloc] = useState(queryFloc)
  const myHistory = useHistory()
  const { data: eq360Response, isLoading: eq360Loading } = useEquipmentOverview(floc!)

  const { data: flocHeadData } = useCBMEquipmentGroup(queryFloc!)
  const { data: alertLists } = useActiveAlertList()

  useEffect(() => {
    if (flocHeadData) {
      // Boolean("") is false, hence this code works here.
      // Park below function due to prod is missing permission
      // let flocHead = eq360Response.getCBMAttribute(CBMAttributes.FlocGroupHead);
      const flocHead = flocHeadData
      if (flocHead !== queryFloc! && flocHead) {
        let adhoc = ''
        if (query.get('adhoc')) {
          adhoc = '&adhoc=true'
        }
        apm.addLabels({
          componentName: 'FlocGuard',
          componentType: 'Guard',
          interactionType: 'redirection',
          fromFloc: queryFloc ?? '',
          toFloc: flocHead,
        })
        myHistory.push(`/floc/detail?floc=${flocHead}${adhoc}`)
        // need error handling to prevent infinity loop?
      }
    }
  }, [flocHeadData, queryFloc])

  useEffect(() => {
    if (!queryFloc) {
      myHistory.push('/')
    } else {
      setFloc(queryFloc)
    }
    //check it's a valid floc
    if (eq360Response?.FLOC) {
      if (!alertLists) {
        // wait alertlist to be loaded
        return
      }
      // possibly a search instead of from landing page
      // valid, assumption (tech debt) on alertList always faster than EQ360
      isValidCallback && isValidCallback(true, floc!)
    } else {
      // fake news!
      if (!eq360Loading) {
        apm.addLabels({
          componentName: 'FlocGuard',
          componentType: 'Guard',
          interactionType: 'Invalid',
          floc: queryFloc ?? '',
        })
      }
      isValidCallback && isValidCallback(false, floc!)
    }
  }, [queryFloc, eq360Response])

  return <> {children}</>
}

export default ValidFlocGuard
