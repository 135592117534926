import styled from '@emotion/styled'
import { Theme, FormControl, InputLabel, Select, MenuItem, CircularProgress, SelectChangeEvent, Box, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

import DiagnosisResponseType from '../../../../shared/types/diagnosis'
import { ReactComponent as InfoIcon } from '../../../../icons/information.svg'
import HelpTooltip from '../../../../shared/components/tooltip-help'
import { useParamQuery } from '../../../../shared/utility'
import FailureModesData from '../../../../shared/types/strategyAPITypes'

const SelectionSection = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  #subTitle {
    display: flex;
    flex: 1 0 100%;
  }
`

const useMenuStyles = makeStyles({
  smallFont: {
    fontSize: '0.8rem',
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstFormControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      flex: '1 0 30%',
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flex: '1 0 60%',
    },
    formControlLast: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      flex: '1 0 90%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

const WaitingProgressComp = styled(CircularProgress)`
  margin: 1rem;
`

const WarningText = styled(Box)`
  margin-top: 0.5rem;
  color: #0064CE;
  font-weight: 500;
  svg {
    height: 0.8rem;
    margin-right: 0.3rem;
  }
`

interface DropDownGroupProps {
  onChangecallBackFunc: (newData: DiagnosisResponseType) => void;
  failureModesResponses: FailureModesData[]
}

const DropDownGroup = ({ onChangecallBackFunc, failureModesResponses }: DropDownGroupProps) => {
  const query = useParamQuery()
  const floc = query.get('floc')
  const classes = useStyles()
  const menuClasses = useMenuStyles()
  const [unit, setUnit] = useState('')
  const [failureComponent, setFailureComponent] = useState('')
  const [failureMode, setFailureMode] = useState('')
  const [unitSelectionCandidates, setUnitSelectionCandidates] = useState<string[]>([])
  const [componentSelectionCandidates, setComponentSelectionCandidates] = useState<string[]>([])
  const [failureModeCandidates, setFailureModeCandidates] = useState<string[]>([])

  const buildComponentSelectionOptions = (unitVal: string) => {
    setComponentSelectionCandidates(
      Array.from(
        new Set(
          failureModesResponses!
            .filter((item) => item.equipmentType === unitVal)
            .map((item) => item.component)
        ).values()
      )
    )
  }

  const handleUnitSelection = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value
    setFailureComponent('')
    setFailureMode('')
    setUnit(newValue)
    buildComponentSelectionOptions(newValue)
  }

  const buildFailureModeOptions = (unitVal: string, componentVal: string) => {
    setFailureModeCandidates(
      Array.from(
        new Set(
          failureModesResponses!
            .filter(
              (item) =>
                item.component === componentVal &&
                item.equipmentType === unitVal
            )
            .map((item) => item.failureMode)
        ).values()
      )
    )
  }

  const handleComponentSelection = (event: SelectChangeEvent<string>) => {
    const newComponentVal = event.target.value
    setFailureComponent(newComponentVal)
    setFailureMode('')
    buildFailureModeOptions(unit, newComponentVal)
  }

  const handleFailureModeSelection = (event: SelectChangeEvent<string>) => {
    const newFailureMode = event.target.value
    setFailureMode(newFailureMode)
  }

  useEffect(() => {
    setUnitSelectionCandidates(
      Array.from(
        new Set(
          failureModesResponses.map((item) => item.equipmentType)
        ).values()
      )
    )
  }, [])

  useEffect(() => {
    let candidateResponses = null
    if (failureMode) {
      candidateResponses = failureModesResponses!.filter(
        (row) =>
          row.failureMode === failureMode &&
          row.equipmentType === unit &&
          row.component === failureComponent
      )
    }
    const data = {
      drivenFloc: floc,
      equipmentType: unit,
      failureMode: failureMode,
      component: failureComponent,
      maintenancePlanCode: candidateResponses
        ? candidateResponses[0].maintenancePlanCode
        : '',
      measuringPointId: candidateResponses
        ? candidateResponses[0].measuringPointId
        : '',
      masterRelationshipTemplatesToCreate: candidateResponses
        ? candidateResponses[0].masterRelationshipTemplatesToCreate
        : '',
      templateToApply: candidateResponses
        ? candidateResponses[0].templateToApply
        : '',
      response: candidateResponses ? candidateResponses[0].response : '',
      taskListCode: candidateResponses
        ? candidateResponses[0].taskListCode
        : '',
    } as DiagnosisResponseType
    onChangecallBackFunc(data)
  }, [failureMode, unit, failureComponent])

  return (
    <SelectionSection>
      <Box id="subTitle">
        <Typography variant='subtitle1'>Identify the Problem</Typography>
        <HelpTooltip title="If the failure mode is unknown or can’t be determined please select 'Failure mode unclear at time of diagnosis'" />
      </Box>
      <FormControl
        variant="outlined"
        className={classes.firstFormControl}
      >
        <InputLabel
          id="select-outlined-label-unit"
          classes={{ root: menuClasses.smallFont }}
        >
          Unit
        </InputLabel>
        <Select
          data-testid="unitSelect"
          labelId="select-outlined-label0-unit"
          id="select-outlined-unit"
          value={unit}
          onChange={handleUnitSelection}
          label="Unit"
          inputProps={{ style: { fontSize: 10 } }}
          variant="standard"
        >
          {unitSelectionCandidates.map((value, index) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        disabled={unit === '' || unit === null}
      >
        <InputLabel
          id="select-outlined-label-failureComp"
          classes={{ root: menuClasses.smallFont }}
        >
          Component
        </InputLabel>
        <Select
          data-testid="failureComponentSelect"
          labelId="select-outlined-label-failureComp"
          id="select-outlined-failureComp"
          value={failureComponent}
          onChange={handleComponentSelection}
          label="Component"
          variant="standard"
        >
          {componentSelectionCandidates.map((value, index) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl
        variant="outlined"
        className={classes.formControlLast}
        disabled={
          failureComponent === '' ||
          failureComponent === null
        }
      >
        <InputLabel
          id="select-outlined-label-failure-mode"
          classes={{ root: menuClasses.smallFont }}
        >
          Failure Mode
        </InputLabel>
        <Select
          data-testid="failureModeSelect"
          labelId="select-outlined-label-failure-mode"
          id="select-outlined-failure-mode"
          value={failureMode}
          onChange={handleFailureModeSelection}
          label="Failure Mode"
          displayEmpty={true}
          variant="standard"
        >
          {failureModeCandidates.map((value, index) => {
            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            )
          })}
        </Select>
        <WarningText
          hidden={
            !(failureMode &&
                failureMode.toLowerCase().includes('failure mode unclear'))
          }
        >
          <InfoIcon />
          Please ensure you only progress with this option if you can not
          determine the Failure Mode.
        </WarningText>
      </FormControl>
    </SelectionSection>
  )
}

export default DropDownGroup
