/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Box, CircularProgress, Divider } from '@mui/material'

import usePermitData from './hooks/usePermitData'
import AlertNotification from '../../../../shared/components/alert-notification'
import {
  ISSOWStrategyTypes,
  ParseStrategyDataRules,
} from '../../../../shared/types/strategyRule'
import { getISSOWEnvBasedonFloc } from '../../../../shared/utility'

const PermitDataRoot = styled(Box)`
  width: 100%;
  padding: 1rem;
`

const TextPair = styled(Box)`
  flex: 1 0 20%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  h5 {
    font-weight: 400;
    opacity: 0.7;
    font-size: 0.9rem;
  }
  span {
  }
`

const TextPairLong = styled(Box)`
  flex: 1 0 40%;
  display: flex;
  flex-direction: column;
  h5 {
    font-weight: 400;
    opacity: 0.7;
    font-size: 0.9rem;
  }
  span {
    font-weight: 400;
  }
`

const DataRow = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  margin-bottom: 1rem;
  margin-left: 0.6rem;
`

const DataRowColumnOriented = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-left: 0.6rem;
  h5 {
    flex: 1 0 20%;
    font-weight: 400;
    opacity: 0.7;
    font-size: 0.9rem;
  }
  span {
    flex: 1 0 20%;
    margin-top: 0.5rem;
  }
  #long {
    flex: 1 0 40%;
  }
`

const EmptyDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;
  #black {
  }
  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }
  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`

export interface PermitDataProps {
  permitId: string;
  isoId: string;
  plantId: 'AU01' | 'AU21';
}

const CircularProgressComp = styled(CircularProgress)`
  margin-bottom: 1rem;
`

const PermitData = (props: PermitDataProps) => {
  const [permitErrorMsg, setPermitErrorMsg] = React.useState('')
  const {
    data: permitData,
    error: permitError,
    isLoading: permitLoading,
  } = usePermitData(props.permitId, props.plantId)
  const {
    data: isolationResp,
    error: isoError,
    isLoading: isoLoading,
  } = usePermitData(props.isoId, props.plantId)

  useEffect(() => {
    if (isoError || permitError) {
      setPermitErrorMsg(isoError as string)
      if (permitError) {
        // only show one error, if permitErrored, then show permit
        setPermitErrorMsg(permitError as string)
      }
    }
  }, [permitError, isoError])

  const showPermitError = () => {
    return (
      permitError &&
      ParseStrategyDataRules(props.permitId) === ISSOWStrategyTypes.Templated
    )
  }
  const showIssowError = () => {
    return (
      isoError &&
      ParseStrategyDataRules(props.isoId) === ISSOWStrategyTypes.Templated
    )
  }
  const showBothErrorLogic = () => {
    const isLoading = isoLoading || permitLoading
    return showPermitError() && showIssowError() && !isLoading
  }

  const PermitComponent = () => {
    const permitDataExist = Boolean(permitData && permitData.length)
    return (
      <>
        {showPermitError() ? (
          <EmptyDiv data-testid="permitError">
            <div id="red">
              <b>Error:</b>It looks like we’re currently unable to show any
              permit data for this response.
            </div>
            <div>
              Either there is a connection issue or the ISSOW API is currently
              unavailable. You can still continue with the diagnosis and
              response submission.
            </div>
          </EmptyDiv>
        ) : (
          <>
            {permitDataExist ? (
              <DataRow>
                <TextPair>
                  <h5>Permit Template No:</h5>
                  <a
                    data-testid="successPermitId"
                    href={`https://${getISSOWEnvBasedonFloc(
                      '',
                      props.plantId
                    )}.wde.woodside.com.au/Permit/General/Default.aspx?PermitID=${
                      permitData[0].permitId
                    }`}
                    target="_blank" rel="noreferrer"
                  >
                    {props.permitId}
                  </a>
                </TextPair>
                <TextPairLong>
                  <h5>Permit Initiate State:</h5>
                  {permitData[0].initialPermitStateNameForTemplateCreate}
                </TextPairLong>
                <TextPairLong>
                  <h5>Permit Short Description:</h5>
                  {permitData[0].title}
                </TextPairLong>
              </DataRow>
            ) : (
              <EmptyDiv>
                {ParseStrategyDataRules(props.permitId) ===
                ISSOWStrategyTypes.LRP && (
                  <div id="black" data-testid="noPermitRequired">
                    This task is performed under an LRP.
                  </div>
                )}
                {ParseStrategyDataRules(props.permitId) ===
                ISSOWStrategyTypes.Empty && (
                  <div id="black" data-testid="permitEmpty">
                    No permits have been defined for this response. If you think
                    this is incorrect, please contact IT support.
                  </div>
                )}
              </EmptyDiv>
            )}
          </>
        )}
      </>
    )
  }

  const IsoComponent = () => {
    return (
      <>
        {showIssowError() ? (
          <EmptyDiv data-testid="isoError">
            <div id="red">
              <b>Error:</b> It looks like we’re currently unable to show any
              isolations data for this response.
            </div>
            <div>
              Either there is a connection issue or the ISSOW API is currently
              unavailable. You can still continue with the diagnosis and
              response submission.
            </div>
          </EmptyDiv>
        ) : (
          <>
            {isolationResp && isolationResp.length ? (
              <DataRowColumnOriented>
                <h5>Isolation Template No:</h5>
                <h5 id="long">Isolation Short Description:</h5>
                <h5 id="long">Primary Plant Item (PPI):</h5>
                {isolationResp.map((issowResp: any, index: number) => (
                  <React.Fragment key={index}>
                    <span>
                      <a
                        key={index}
                        data-testid="successIsoId"
                        href={`https://${getISSOWEnvBasedonFloc(
                          '',
                          props.plantId
                        )}.wde.woodside.com.au/Permit/General/Default.aspx?PermitID=${
                          issowResp.permitId
                        }`}
                        target="_blank" rel="noreferrer"
                      >
                        {issowResp.displayName}
                      </a>
                    </span>
                    <span id="long">{issowResp.title}</span>
                    <span id="long">{issowResp.plantItemDisplayValue}</span>
                  </React.Fragment>
                ))}
              </DataRowColumnOriented>
            ) : (
              <EmptyDiv>
                {ParseStrategyDataRules(props.isoId) ===
                ISSOWStrategyTypes.OwnIsolations && (
                  <div id="black" data-testid="isoNotRequired">
                    This task is performed under an LRP with own isolations.
                  </div>
                )}
                {ParseStrategyDataRules(props.isoId) ===
                ISSOWStrategyTypes.Empty && (
                  <div id="black" data-testid="isoEmpty">
                    No isolations have been defined for this response. If you
                    think this is incorrect, please contact IT support.
                  </div>
                )}
              </EmptyDiv>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      {permitErrorMsg && (
        <AlertNotification
          type="error"
          message={permitErrorMsg}
          onHide={() => {
            setPermitErrorMsg('')
          }}
        />
      )}
      {showBothErrorLogic() ? (
        <EmptyDiv data-testid="bothError">
          <div id="red">
            <b>Error:</b> It looks like we’re currently unable to show any
            permit or isolations data for this response.
          </div>
          <div>
            Either there is a connection issue or the ISSOW API is currently
            unavailable. You can still continue with the diagnosis and response
            submission.
          </div>
        </EmptyDiv>
      ) : (
        <PermitDataRoot>
          {!permitLoading ? (
            PermitComponent()
          ) : (
            <CircularProgressComp data-testid="loadingPermit" size={'2rem'} />
          )}
          <br />
          <Divider />
          <br />
          {!isoLoading ? (
            // loaded, but may be error
            IsoComponent()
          ) : (
            <CircularProgressComp data-testid="loadingIso" size={'2rem'} />
          )}
        </PermitDataRoot>
      )}
    </>
  )
}

export default PermitData
