import styled from '@emotion/styled'
import { Box, CircularProgress } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useState, useEffect } from 'react'

import { ReactComponent as EyeIcon } from '../../../../../icons/eye.svg'
import useEquipmentHierarchy from '../../../../../services/hooks/useEquipmentHierarchyData'
import AlertNotification from '../../../../../shared/components/alert-notification'
import { ItemLatestMeasurementsResponse } from '../../../../../shared/types/getOverviewResponse'
import { getFlatFlocTreeWithDesc, getlastUPdatedStringMinsAgo as getlastUpdatedStringMinsAgo } from '../../../../../shared/utility'
import useSAData from '../../hooks/useSAData'
import SituationAwarenessPumpView from './situation-map/component'
import { PaddedCard } from '../../../../../shared/components/padded-card'

const Heading = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

const HeadingTitle = styled.h3`
  margin-left: 0.1rem;
  margin-bottom: 0;
  flex: 1 auto;
`

const WaitingProgressComp = styled(CircularProgress)`
  margin-top: 1rem;
`

const LastUpdatedDiv = styled(Box)`
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
`

interface SituationAwarenessCardPumpProps {
  showFullLengthCallBack: (showFullLength: boolean) => void;
  currentFloc: string;
}

const SituationAwarenessCardPump = (props: SituationAwarenessCardPumpProps) => {
  //if browser height > 900, then default to true
  const [expanded, setExpanded] = useState<boolean>(false)
  const [parentFloc, setParentFloc] = useState('')
  const [lastUpdatedUnix, setLastUpdatedUnix] = useState(0)
  const [flatFlocTree, setFlatFlocTree] =
    useState<[{ floc: string; desc: string }]>()
  // get current selection's hierarchy
  const {
    status: flocHierarchyStatus,
    data: flocHierarchyData,
    error: flocHierarchyError,
    isLoading: flocHierarchyLoading,
  } = useEquipmentHierarchy(props.currentFloc)
  const {
    status: flocHierahcyParentStatus,
    data: flocHierarchyParentData,
    error: flocHierarchyParentError,
    isLoading: flocHierarchyParentLoading,
  } = useEquipmentHierarchy(parentFloc)
  // get SA data from the hierarchy
  const {
    status: saStatus,
    data: saResponse,
    error: saError,
    isLoading,
  } = useSAData(parentFloc || '', 3)
  const [filterdSAData, setFilteredSAData] =
    useState<ItemLatestMeasurementsResponse[]>()
  useEffect(() => {
    if (window.innerHeight > 700) {
      setExpanded(true) // default open if it has more than this height
    }

    if (saResponse && saResponse.children) {
      const flocP = props.currentFloc.substr(0, props.currentFloc.length - 1)
      const flocTester = new RegExp(flocP + '.')
      const groupPumps = saResponse.children.filter((child) => {
        return flocTester.test(child.floc)
      })
      setFilteredSAData(groupPumps)

      props.showFullLengthCallBack(true)
    }
  }, [saResponse])

  useEffect(() => {
    if (flocHierarchyData && flocHierarchyData.parentEquipmentTagname) {
      setParentFloc(flocHierarchyData.parentEquipmentTagname)
    }
  }, [flocHierarchyData])
  useEffect(() => {
    // check parent hierarchy response exist and it's an exch (deck)
    if (flocHierarchyParentData && flocHierarchyParentData.children) {
      setFlatFlocTree(
        getFlatFlocTreeWithDesc(flocHierarchyParentData, [{}] as any)
      )
    }
  }, [flocHierarchyParentData])

  useEffect(() => {
    // Calculate last updated by get first groupStatus it can find, then use that
    if (saResponse && saResponse.children?.length) {
      const groupStatus = saResponse.children.find((row) => {
        return row.measurements.find(
          (m) => m.key === 'MEASUREMENT_SITUATIONAL_AWARENESS_GROUP_STATUS'
        )
      })
      if (groupStatus) {
        const lastUpdatedUnix = groupStatus.measurements.find(
          (m) => m.key === 'MEASUREMENT_SITUATIONAL_AWARENESS_GROUP_STATUS'
        )!.values[0].timestamp
        setLastUpdatedUnix(lastUpdatedUnix)
      }
    }
  }, [saResponse])

  const loading = () => {
    return flocHierarchyLoading || isLoading || flocHierarchyParentLoading
  }

  const hasError = () => {
    return (
      !loading() && (flocHierarchyError || flocHierarchyParentError || saError)
    )
  }

  const hasData = () => {
    return saResponse && flocHierarchyData && flocHierarchyParentData
  }

  return (
    <PaddedCard>
      <Heading
        role="button"
        style={{ cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
      >
        <HeadingTitle>
          <EyeIcon style={{ marginRight: '.5rem', height: '1rem' }} />
          Situational Awareness
        </HeadingTitle>
        <LastUpdatedDiv>
          {lastUpdatedUnix && `Last updated ${getlastUpdatedStringMinsAgo(lastUpdatedUnix)}`}
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </LastUpdatedDiv>
        <span />
      </Heading>
      {loading() && <WaitingProgressComp />}
      {hasError() && (
        <AlertNotification
          type="error"
          message={
            (((flocHierarchyError || flocHierarchyParentError) &&
              'An error has occurred while fetching the floc hierarchy. Please come back later :) ') ||
              (saError &&
                'An error has occurred while fetching the SA data')) as string
          }
        />
      )}
      {saResponse && saResponse.equipmentTagname && flatFlocTree && (
        <SituationAwarenessPumpView
          flocMetaDataForDesc={flatFlocTree}
          expanded={expanded}
          inputData={filterdSAData!}
          PumpDeckLabel={
            (Boolean(filterdSAData) && filterdSAData!.length) > 1
              ? props.currentFloc.substring(0, props.currentFloc.length - 1)
              : props.currentFloc
          }
        />
      )}
      {!loading() && !hasData() && 'No data to show at this time :)'}
    </PaddedCard>
  )
}
export default SituationAwarenessCardPump
