import React, { useContext, useState } from 'react'
import { Button, Dialog, IconButton, Theme, Typography } from '@mui/material'
import { withStyles, createStyles, WithStyles } from '@mui/styles'
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent from '@mui/material/DialogContent'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import SearchTextBox from './search'
import { DataFilterContext } from '../../anomaly-list/component'
import { mapPlantToFlocPrefix } from '../../../shared/types/plants'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      height: 0,
      width: 500,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
    },
    body1: {
      fontSize: '25px',
    },
    questionText: {
      fontSize: '20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (e: any) => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        align="left"
        display="inline"
        variant="body1"
        className={classes.body1}
      >
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    opacity: '90%',
    fontSize: '1rem',
    fontWeight: 400,
    marginLeft: '5px',
    marginTop: '2rem',
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogStyled = withStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}))(Dialog)

interface AppSearchDialogueProps {
  handleClose: (e: any) => void;
  open: boolean;
  adHoc: boolean;
}

const SearchIconStyled = styled(SearchIcon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`

const ButtonStyled = styled(Button)`
  align-self: flex-end;
`

const AppSearchDialogue = (props: AppSearchDialogueProps) => {
  const filterContext = useContext(DataFilterContext)
  const searchHandler = (floc: string) => {
    setSearchInput(floc.toUpperCase())
  }
  const [searchInput, setSearchInput] = useState('')
  const history = useHistory()
  return (
    <DialogStyled
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle onClose={props.handleClose} id="form-dialog-title">
        <SearchIconStyled /> FLOC Search
      </DialogTitle>

      <DialogContent>
        Search for a FLOC number to view equipment information. <br />
        <br />
        <SearchTextBox
          flocChangeHandler={searchHandler}
          handleClose={props.handleClose}
          adHoc={props.adHoc}
        />
      </DialogContent>
      <DialogActions>
        <ButtonStyled data-testid="closeDialogBtn" onClick={props.handleClose}>
          Close
        </ButtonStyled>
        <ButtonStyled
          data-testid="submitDialogBtn"
          variant="contained"
          color="primary"
          onClick={(e) => {
            history.push(
              `/floc/detail?floc=${!searchInput.includes('.') ? mapPlantToFlocPrefix[filterContext.filterSetting.plant] + '.' : ''}${searchInput}${
                props.adHoc ? '&adhoc=true' : ''
              }`
            )
            props.handleClose(e)
          }}
        >
          Search
        </ButtonStyled>
      </DialogActions>
    </DialogStyled>
  )
}

export default AppSearchDialogue
