import { useQuery } from 'react-query'
import axios from 'axios'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

import {
  AlertSnoozedResponse,
  convertToSnoozedAlerts,
} from '../../../shared/types/alert'
import { acquireTokenInfoSilent } from '../../../shared/auth'

const fetchSnoozedAlerts = async (
  msalInstance: IPublicClientApplication,
  floc?: string | null
) => {
  try {
    const tokenResponse = await acquireTokenInfoSilent(
      msalInstance,
      process.env.REACT_APP_AAD_CLIENTID! + '/.default'
    )
    const accessToken = tokenResponse!.accessToken
    const url = process.env.REACT_APP_CBM_ENDPOINT + '/anomalies?' + 'state=Cancelled' + (floc ? `&floc=${floc}` : '')
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}`},
    })
    let responseProcessed: AlertSnoozedResponse[] = response.data
    if (floc && responseProcessed) {
      responseProcessed = responseProcessed.filter(
        (row) => row.drivenFloc === floc
      )
    }
    return convertToSnoozedAlerts(responseProcessed)
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default function useSnoozedAlertList(filterFloc?: string | null) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['Snoozed', filterFloc],
    () => fetchSnoozedAlerts(msalInstance, filterFloc),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 10 * 1000 * 60, // 2min stale time, unless alert raised during this 10 minutes or reload page, it will use this as fast chache
      // retryDelay: 1000,
    }
  )
}
