import React from 'react'
import styled from '@emotion/styled'
import { Table } from 'antd'
import { Box } from '@mui/material'

import { DiagnosedAlert } from '../../../../../shared/types/alert'

const columns = [
  {
    title: 'Actioned On',
    dataIndex: 'dateResolved',
    width: 150,
  },
  {
    title: 'Alert Type',
    dataIndex: 'alertTypeNiceName',
  },
  {
    title: 'Actioned By',
    dataIndex: 'resolvedBy',
  },
  {
    title: 'Identified Unit',
    dataIndex: 'unit',
  },
  {
    title: 'Identified Component',
    dataIndex: 'component',
  },
  {
    title: 'Identified Failure Mode',
    dataIndex: 'failureMode',
  },
]
export interface ActionListTableTabProps {
  alerts: DiagnosedAlert[] | undefined;
  value: number; // current selection, not really used
  index: number;
}

const EmptyBox = styled(Box)`
  display: grid;
  place-items: left;
  opacity: 60%;
  margin-top: 1rem;
`

const ActionListTableTab = (props: ActionListTableTabProps) => {
  const { value, index, ...other } = props
  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {props.alerts && props.alerts.length && (
        <Table
          style={{ width: '100%' }}
          columns={columns}
          dataSource={props.alerts}
          size="small"
          pagination={{ pageSize: 3, hideOnSinglePage: true }}
          rowKey="id"
        />
      )}
      {!props.alerts || !props.alerts.length && (
        <EmptyBox>
          <p>
            There have been no alerts actioned yet for this equipment.
            Diagnosing an active alert will place it here as actioned.
          </p>
        </EmptyBox>
      )}
    </div>
  )
}

export default ActionListTableTab
