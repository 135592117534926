import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useQuery } from 'react-query'
import { getTSData } from '../../../../services/graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../../../shared/auth'
import { TimeSeries } from '../../../../shared/types/timeseries'

async function fetchTSData(
  msalInstance: IPublicClientApplication,
  phdTag: string,
  timeBase: 'DAY' | 'HOUR' | 'MIN',
  interval: number,
  startTimeStamp: number,
  endTimestamp: number,
  floc: string,
  aggregationType?: 'SUM' | 'AVG'
) {
  const result = await Eq360GraphFetch(
    msalInstance,
    process.env.REACT_APP_EQ360_ID!,
    fetch,
    process.env.REACT_APP_EQ360_HOST!,
    getTSData(
      phdTag,
      timeBase,
      interval,
      startTimeStamp,
      endTimestamp,
      floc,
      aggregationType
    )
  )

  return result.getTimeseriesData.map(
    (x: any) => new TimeSeries(x)
  ) as TimeSeries[]
}

const useTSData = (
  phdTag: string,
  timeBase: 'DAY' | 'HOUR' | 'MIN',
  interval: number,
  startTimeStamp: number,
  endTimestamp: number,
  floc: string,
  aggregationType?: 'SUM' | 'AVG'
) => {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    [
      'TSData',
      phdTag,
      timeBase,
      interval,
      aggregationType,
      startTimeStamp,
      endTimestamp,
      floc
    ],
    () =>
      fetchTSData(
        msalInstance,
        phdTag,
        timeBase,
        interval,
        startTimeStamp,
        endTimestamp,
        floc,
        aggregationType
      ),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 15 * 1000 * 60, // 15min stale time,
      enabled: Boolean(phdTag),
    }
  )
}

export default useTSData
