import { useQuery } from 'react-query'
import axios from 'axios'
import { acquireTokenInfoSilent } from '../../shared/auth'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

const fetchUserIdentityData = async (
  msalInstance: IPublicClientApplication,
  wopId?: string
) => {
  // TODO: dev have limited data, call prod instead
  // Need MuleSoft team to set up cors for prod
  // verified_secondary_email is an easier solution
  const response = await axios.get(
    process.env.REACT_APP_IDENTITY_MULE_URL + `identities?wopId=${wopId}`,
    {
      headers: {
        client_id: process.env.REACT_APP_IDENTITY_TRACKAPPID,
        client_secret: '',
        Authorization: `Bearer ${await (await acquireTokenInfoSilent(
          msalInstance,
          process.env.REACT_APP_IDENTITY_MULE_ID! + '/.default'
        ))!.accessToken}`,
      },
    }
  )

  return response.data
}

export default function useIdentityProfileData(wopId?: string) {
  const { instance: msalInstance } = useMsal()
  return useQuery(
    ['identityProfile', wopId],
    () => fetchUserIdentityData(msalInstance, wopId),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 10 * 1000 * 60, // 10 min stale time, unless alert raised during this 10 minutes or reload page, it will use this as fast chache
      // retryDelay: 1000,
      enabled: false,
    }
  )
}
