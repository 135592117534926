import React from 'react'
import { Table } from 'antd'
import styled from '@emotion/styled'
import { CircularProgress, Box } from '@mui/material'

import { OperationTableData } from '../../../../shared/types/tasklistTypes'

const operationColumns = [
  {
    title: 'Operation No.',
    dataIndex: 'opNo',
  },
  {
    title: 'Operation Description',
    dataIndex: 'opDesc',
  },
  {
    title: 'Work Center',
    dataIndex: 'workCenter',
  },
  {
    title: 'Planner Group',
    dataIndex: 'plGroup',
  },
  {
    title: 'No. of People',
    dataIndex: 'noPeople',
  },
  {
    title: 'Work (Hrs)',
    dataIndex: 'workHours',
  },
  {
    title: 'Duration (Hrs)',
    dataIndex: 'durationHours',
  },
]

const CircularProgressComp = styled(CircularProgress)`
  margin-bottom: 1rem;
`

const ErrorDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: #666666;
  #red {
    color: #f44336;
    b {
      font-weight: 500;
    }
  }

  a {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`

export interface TaskListTableProps {
  opsTableData: undefined | OperationTableData[];
  showError: boolean;
}

const TaskListOpsTable = (props: TaskListTableProps) => {
  return (
    <>
      {props.showError ? (
        <ErrorDiv>
          <div id="red">
            <b>Error:</b> It looks like we’re currently unable to show any
            maintenance planning data for this response.
          </div>
          <div>
            Either there is a connection issue or the SAP API is currently
            unavailable. You can still continue with the diagnosis and response
            submission.
          </div>
        </ErrorDiv>
      ) : (
        <>
          {!props.opsTableData ? (
            <CircularProgressComp />
          ) : (
            <Table
              style={{ width: '100%' }}
              columns={operationColumns}
              dataSource={props.opsTableData}
              size="small"
              pagination={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default TaskListOpsTable
