import { useQuery } from 'react-query'

import { getEquipmentHierarchy } from '../graphql/EQ360Queries'
import { Eq360GraphFetch } from '../../shared/auth'
import { EquipmentHierarchyObject } from '../../shared/types/equipmentHierachy'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { apm } from '@elastic/apm-rum'

export async function fetchEquipmentHierarchyByFloc(
  msalInstance: IPublicClientApplication,
  floc: string,
  asset?: string
): Promise<EquipmentHierarchyObject> {
  const transaction = apm.startTransaction('useEquipmentHierarchy', 'EQ360')!

  const httpSpan = transaction.startSpan(
    `POST ${process.env.REACT_APP_EQ360_HOST!}`,
    'app'
  )!
  transaction.addLabels({
    floc,
  })
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    Eq360GraphFetch(
      msalInstance,
      process.env.REACT_APP_EQ360_ID!,
      fetch,
      process.env.REACT_APP_EQ360_HOST!,
      getEquipmentHierarchy(floc, asset)
    )
      .then((result) => {
        resolve(result.getEquipmentHierarchyV2 as EquipmentHierarchyObject)
      })
      .catch((e: [{ messsage: string }]) => {
        apm.captureError(
          new Error(`Equipment hierarchy error: ${e[0].messsage}`)
        )
        reject(e)
      })
      .finally(() => {
        httpSpan.end()
        transaction.end()
      })
  })
}

const useEquipmentHierarchy = (
  floc: string,
) => {
  const { instance: msalInstance } = useMsal()

  return useQuery(
    ['EquipmentHierarchy', floc, undefined],
    () => fetchEquipmentHierarchyByFloc(msalInstance, floc!),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 30 * 1000 * 60, // 30min stale time,
      enabled: floc ? true : false,
    }
  )
}

export default useEquipmentHierarchy
