import { IPublicClientApplication, LogLevel } from '@azure/msal-browser'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { Configuration } from '@azure/msal-browser'
import { ProtocolMode } from '@azure/msal-browser'

import { PerferenceStorageKeyName as PreferenceStorageKeyName } from '../../features/anomaly-list/hooks/useSortingPerference'

const hostname = window.location.hostname
export const msalConfig: Configuration = {
  auth:
    // eslint-disable-next-line no-constant-condition
    hostname === 'localhost' || true
      ? {
          clientId: process.env.REACT_APP_AAD_CLIENTID!,
          authority:
            'https://login.microsoftonline.com/a3299bba-ade6-4965-b011-bada8d1d9558',
          redirectUri: window.location.origin,
          protocolMode: ProtocolMode.AAD,
        }
      : {
          clientId: process.env.REACT_APP_AAD_CLIENTID!,
          navigateToLoginRequestUrl: true,
          authority: `https://${hostname}`,
          knownAuthorities: [`https://${hostname}`],
          protocolMode: ProtocolMode.OIDC,
          redirectUri: `https://${hostname}/postlogin`,
        },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message);
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}

export async function acquireTokenInfoSilent(
  msalInstance: IPublicClientApplication,
  resource: string
) {
  const accounts = msalInstance.getAllAccounts()

  const request = {
    scopes: [resource],
    account: accounts[0],
  }

  if (!request.account) {
    return
  }

  try {
    return await msalInstance.acquireTokenSilent(request)
  } catch (e) {
    console.log('Failed to acquire token with request and error:')
    console.error(e)
    if (
      e['errorCode'].includes('no_tokens_found') ||
      e['errorMessage'].includes('no_tokens_found') ||
      e['errorMessage'].includes(
        'A silent sign-in request was sent but none of the currently signed in user(s) match the requested login hint'
      )
    ) {
      console.log('Attempting to reload.')
      const myItem = localStorage.getItem(PreferenceStorageKeyName)
      localStorage.clear()
      if (myItem) {
        localStorage.setItem(PreferenceStorageKeyName, myItem)
      }
      window.location.reload()
    }
    return null
  }
}

export async function Eq360GraphFetch(
  msalInstance: IPublicClientApplication,
  resource: string,
  fetch: (input: string, init: any) => Promise<any>,
  url: string,
  graphQuery: string
): Promise<any> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    await fetch(url, {
      headers: {
        Authorization: (await acquireTokenInfoSilent(
          msalInstance,
          resource + '/.default'
        ))!.accessToken,
      },
      method: 'POST',
      body: JSON.stringify({ query: graphQuery }),
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const { data, errors } = await resp.json()
          if (errors) {
            reject(errors)
          }
          resolve(data)
        } else {
          const waitedResponse = await resp.json()
          reject('Error when calling api:' + waitedResponse)
        }
      })
      .catch((e) => {
        reject('Error when reaching api' + e)
      })
  })
}

axios.interceptors.request.use(async (config) => {
  config.headers = config.headers || {}
  config.headers['X-Correlation-ID'] = uuidv4()
  return config
})
