import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    'flex-wrap': 'wrap',
    padding: '1rem',
    'justify-content': 'space-between',
    gap: '1rem',
    width: '100%',
  }
}))

export const PaddedTabBox = ({hidden, children}: any) => {
  const classes = useStyles()
  return (
      // eslint-disable-next-line react/prop-types
      <Box className={classes.root} hidden={hidden}>{children}</Box>
  )
}