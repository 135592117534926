import styled from '@emotion/styled'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

import { ReactComponent as QuestionMarkIcon } from '../../icons/question-mark.svg'

interface TooltipHelpProps {
  title: string;
}

const Root = styled(Box)`
  margin-top: 0.1rem;
  svg {
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
    color: #0064CE;
  }
`

const HelpTooltip = ({ title }: TooltipHelpProps) => {
  return (
    <Root>
      <Tooltip title={title} disableInteractive>
        <QuestionMarkIcon style={{ width: '18px' }}/>
      </Tooltip>
    </Root>
  )
}

export default HelpTooltip
