import React from 'react'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
      marginTop: 0,
      marginLeft: theme.spacing(2),
    },
  })
)

export interface InaccurateResponseCheckboxesGroupProps {
  checkBoxCallback: ({}: any) => void;
}

export default function InaccurateResponseCheckboxesGroup(
  props: InaccurateResponseCheckboxesGroupProps
) {
  const classes = useStyles()
  const [checkboxStates, setCheckboxStates] = React.useState({
    operationsErrorObserved: false,
    materialsErrorObserved: false,
    permitErrorObserved: false,
  })

  React.useEffect(() => {
    props.checkBoxCallback(checkboxStates)
  }, [checkboxStates])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxStates({
      ...checkboxStates,
      [event.target.name]: event.target.checked,
    })
  }

  const {
    operationsErrorObserved: operationsErrorObserved,
    materialsErrorObserved: materialsErrorObserved,
    permitErrorObserved: permitErrorObserved,
  } = checkboxStates

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl} variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={operationsErrorObserved}
                onChange={handleChange}
                name="operationsErrorObserved"
              />
            }
            label="Operations"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={materialsErrorObserved}
                onChange={handleChange}
                name="materialsErrorObserved"
              />
            }
            label="Materials"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permitErrorObserved}
                onChange={handleChange}
                name="permitErrorObserved"
              />
            }
            label="Permit"
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}
