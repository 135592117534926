import { Button, Grid, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'
import { Link } from 'react-router-dom'

import coneWarningImg from '../../../images/cone-warning.png'
import { useAppSearchContext } from '../../../shared/providers/app-search-provider'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    contentPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textContent: {
      '& *': {
        color: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'center',
      },
    },
    coneImg: {
      width: '100%',
      maxWidth: '15rem',
      margin: '0 auto',
    },
    mainTitle: {
      margin: '1rem 0',
      fontWeight: 500,
      fontSize: '2rem',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'left',
      marginTop: '2rem',
      '& > *': {
        marginRight: '1rem',
      },
    },
  })
)

export type InvalidFlocPropsType = {
  floc: string;
  isValid: boolean;
  isOnboarded: boolean;
};

function InvalidFloc(props: InvalidFlocPropsType) {
  const classes = useStyles()
  const { openFlocSearch } = useAppSearchContext()
  return (
    <Grid
      className={classes.root}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid className={classes.contentPanel} item xs={12} sm={8} md={4}>
        <img className={classes.coneImg} src={coneWarningImg} />
        <div className={classes.textContent}>
          <Typography className={classes.mainTitle} variant="h4" component="h3">
            {!props.isValid && (
              <span>Oh no! We couldn&apos;t find {props.floc}.</span>
            )}
            {props.isValid && !props.isOnboarded && (
              <span>Oh no! {props.floc} isn&apos;t available yet.</span>
            )}
          </Typography>
          <p>
            {!props.isValid && (
              <span>
                It looks like the FLOC you just searched for doesn’t exist.{' '}
                <br />
                You may have spelt it incorrectly.
              </span>
            )}
            {props.isValid && !props.isOnboarded && (
              <span>
                It looks like the FLOC you just searched for hasn’t been
                onboarded to the CBM Application. New equipment types will be
                added in the future. You can try again later.
              </span>
            )}
          </p>
        </div>
        <div className={classes.buttonContainer}>
          <Button component={Link} to={'/'}>
            Return to all alerts
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openFlocSearch()}
          >
            Search another
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default InvalidFloc
