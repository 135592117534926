import styled from '@emotion/styled'
import { Box, Divider, TextField, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as DiagnosisIcon } from '../../../../icons/diagnosis.svg'
import DiagnosisResponseType, { UIInternalDiagnosisStateType } from '../../../../shared/types/diagnosis'
import { PaddedCard } from '../../../../shared/components/padded-card'
import ValidFlocGuard from '../../../../shared/components/ValidFlocGuard'
import DropDownGroup from './dropdowns'
import DurationPicker from './duration'
import FailureModesData from '../../../../shared/types/strategyAPITypes'

const OptionalSpan = styled(Box)`
  display: inline;
  color: rgba(0,0,0, .6)
`

interface DiagnosisCardProps {
  diagnosisState: UIInternalDiagnosisStateType;
  stateChangeCallback: (newData: UIInternalDiagnosisStateType) => void;
  failureModesResponses: FailureModesData[]
}

const DiagnosisCard = (props: DiagnosisCardProps) => {
  const { diagnosisState: state, stateChangeCallback: setState, failureModesResponses } = props

  const handleDropdownchange = (data: DiagnosisResponseType) => {
    setState({
      ...state,
      diagnosisResponseType: new DiagnosisResponseType({
        ...state.diagnosisResponseType,
        ...data,
      }),
    })
  }

  return (
    <PaddedCard>
      <Typography variant='h2'>
        <DiagnosisIcon style={{ marginRight: '.5rem', marginBottom: '.5rem', height: '1rem' }} />Diagnosis
      </Typography>
      <ValidFlocGuard>
        <DropDownGroup onChangecallBackFunc={handleDropdownchange} failureModesResponses={failureModesResponses} />
        <Divider style={{ margin: '1.5rem 0' }} />
        <Typography variant='subtitle1'>
          Describe the problem in detail <OptionalSpan>(Optional)</OptionalSpan>
        </Typography>
        <TextField
          data-testid="workLongTextArea"
          value={state.diagnosisResponseType.workLongText}
          fullWidth
          multiline={true}
          maxRows={8}
          minRows={4}
          onChange={(e) => {
            props.stateChangeCallback({
              ...state,
              diagnosisResponseType: new DiagnosisResponseType({
                ...state.diagnosisResponseType,
                workLongText: e.target.value,
              }),
            })
          }}
          inputProps={{ maxLength: 1000 }}
          helperText={`${
            state.diagnosisResponseType.workLongText
              ? state.diagnosisResponseType.workLongText.length
              : 0
          }/1000`}
        />
        <Divider style={{ margin: '1.5rem 0' }} />
        <DurationPicker
          stateChangeCallback={(newState) => {
            setState({
              ...state,
              timeUnit: newState.timeUnit,
              timeValue: newState.timeValue,
              diagnosisResponseType: new DiagnosisResponseType({
                ...state.diagnosisResponseType,
                equipmentAlreadyBrokeFlg: newState.breakDown,
                estimatedRiskEndDate: newState.estimatedRiskEndDate,
              }),
            })
          }}
        />
      </ValidFlocGuard>
    </PaddedCard>
  )
}

export default DiagnosisCard
