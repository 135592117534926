import styled from '@emotion/styled'
import React from 'react'
import { Box } from '@mui/material'

import CompartmentAccordion from './compartment-result'

export interface OilResultUIDataModel {
  compartmentName: string;
  compartmentType: string;
  lastUpdated: Date;
  status: string;
  fluidName: string;
  manufacturer: string;
  grade: string;
  compartmentCapacity: string;
  problemCode: string;
  diagnosis: string;
  reportLink: string;
  fluidType: string;
}
export interface OilOverviewTabProps {
  value: number;
  index: number;
  data: OilResultUIDataModel[];
}
const StyledDiv = styled(Box)`
  padding: 1.2rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: column;
`
const NoData = styled(Box)`
  opacity: 0.6;
`

const OilOverviewTab = (props: OilOverviewTabProps) => {
  return (
    <StyledDiv hidden={props.index !== props.value}>
      {props.data.map((row, idx) => {
        return <CompartmentAccordion key={idx} data={row} />
      })}
      {!props.data.length && (
        <NoData>
          It looks like there are currently no ALS samples available for this
          equipment. If you think this is incorrect, please contact IT support.
        </NoData>
      )}
    </StyledDiv>
  )
}

export default OilOverviewTab
