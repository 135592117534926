import moment from 'moment'
import { GlobalDateFormatter } from '../utility'

enum NotiSYSStateMapping {
  ATCO = 'All Tasks Completed',
  NOCO = 'Notification Completed',
  NOPR = 'Notification in Process',
  ORAS = 'Order Assigned',
  OSNO = 'Outstanding Notification',
  OSTS = 'Outstanding Task(s) Exist(s)',
}
enum NotiUserStateMapping {
  APPR = 'Approved',
  MRTS = 'Material Return to Stock',
  ENGQ = 'Engineering Query',
  NAAP = 'Notification Awaiting Approval',
  NCAN = 'Notification Cancelled',
  NINS = 'Insufficient Information',
  NTBC = 'Notification to be Closed',
  RREQ = 'Regulatory Requirement',
  TINT = 'Technical Integrity',
}
export enum WOSYSStateMapping {
  CLSD = 'Closed',
  CNF = 'Confirmed',
  CRTD = 'Created',
  ESTC = 'Estimated Costs',
  GMPS = 'Goods Movement Posted',
  MACM = 'Material Committed',
  MANC = 'Material Availability Not Checked',
  NMAT = 'No Material Components',
  NTUP = 'Dates Are Not Updated',
  PCNF = 'Partially Confirmed',
  PRC = 'Pre-Costed',
  PRT = 'Printed',
  REL = 'Released',
  SETC = 'Settlement Rule Created',
  TECO = 'Technically Completed',
}
export enum WOUserStateMapping {
  ALMM = 'Alarm Management',
  APPR = 'Work Order Approved',
  BDMA = 'Building Maintenance',
  EEOS = 'Essential Equip Out of Service',
  DAPP = 'Deviation Approved',
  DSPT = 'Dispatched',
  ENGQ = 'Engineering Query',
  DEVR = 'Deviation Required',
  DVRJ = 'Deviation Rejected',
  EOMA = 'Extraordinary Maintenance',
  EXEC = 'Ready to Execute',
  MAGN = 'Generic Material Used',
  MAMA = 'Major Maintenance',
  MAOK = 'Material Confirmed',
  MARF = 'Refurbishable Material Exists',
  MAST = 'Material Staged',
  MAXX = 'Material Shortage',
  MOC = 'Management of Change',
  MRTS = 'Material Return to Stock',
  NINS = 'Insufficient Information',
  OPSD = 'Opportune Shutdown Work',
  PLWO = 'Planned Work Order',
  RAWO = 'Resource Approval of Work Order',
  RREQ = 'Regulatory Requirement',
  RRWO = 'Re-Resource Work Order',
  RTEC = 'Ready for TECO',
  SCHD = 'Scheduled for Execution',
  SDMA = 'Shutdown Maintenance',
  SCAR = 'Scope Change Request',
  STDG = 'Standing Order',
  TINT = 'Technical Integrity',
  WAAP = 'Work Order Awaiting Approval',
  WCAN = 'Work Order Cancelled',
  WINP = 'Work In Progress',
  WRHA = 'Work Requires Higher Approval',
}
export default class MaintenanceJob {
  closedDate?: string | null
  closedDateTimeStamp?: Date
  isWorkOrder: boolean
  isCurrent: boolean
  isCancelled: boolean
  floc: string
  jobStatus: string
  jobPriority: string
  StartDate: string
  StartDateTimeStamp: Date
  woBasicEndDate?: string
  woBasicEndDateTimeStamp?: Date
  identifiedBy: string
  identificationDate: string
  identificationDateTimeStamp: Date
  notificationShortText: string
  notificationLongText: string
  notificationId: string
  workorderId: string
  workorderType: string
  get isCorrective() {
    // need figure out what we want to do with CBM
    const isFromCBM =
      this.notificationLongText &&
      (this.notificationLongText.includes('(CBM_SERVICE)') ||
        this.notificationLongText.includes('CBM REQUEST INFOR'))
    return (
      (this.workorderType &&
        this.workorderType.toLowerCase().includes('corrective')) ||
      isFromCBM
    )
  }
  workorderSysStatus?: string
  notiSysStatus?: string
  randomKey: number // for table keys
  constructor(maintenanceResponse: MaintenanceDataResponse) {
    this.randomKey = Math.floor(Date.now() * Math.random())
    this.floc = maintenanceResponse.floc
    // const dateFormatter = new Intl.DateTimeFormat('en-AU', {
    //   year: 'numeric',
    //   month: 'short',
    //   day: 'numeric',
    // });
    const isWorkOrder = maintenanceResponse.workOrderNumber ? true : false
    this.isWorkOrder = isWorkOrder
    this.identifiedBy = 'TBC'
    // sample date 2012-04-12
    // set noti status
    this.notiSysStatus =
      maintenanceResponse.notificationCurrentSystemStatusCode

    if (this.isWorkOrder) {
      if (maintenanceResponse.workOrderMaxConfirmationDate) {
        this.closedDateTimeStamp = moment(
          maintenanceResponse.workOrderMaxConfirmationDate
        ).toDate()
        this.closedDate = GlobalDateFormatter.format(this.closedDateTimeStamp)
      }

      this.jobStatus =
        WOUserStateMapping[maintenanceResponse.workOrderCurrentUserStatusCode] +
        ': ' +
        WOSYSStateMapping[maintenanceResponse.workOrderCurrentSystemStatusCode]

      this.StartDateTimeStamp = moment(
        maintenanceResponse.workOrderBasicStartDate
      ).toDate()
      this.StartDate = GlobalDateFormatter.format(this.StartDateTimeStamp)

      if (maintenanceResponse.workOrderBasicEndDate) {
        this.woBasicEndDateTimeStamp = moment(
          maintenanceResponse.workOrderBasicEndDate
        ).toDate()
        this.woBasicEndDate = GlobalDateFormatter.format(
          this.woBasicEndDateTimeStamp
        )
      }
      this.workorderSysStatus =
        maintenanceResponse.workOrderCurrentSystemStatusCode
      this.workorderType = maintenanceResponse.workOrderTypeText
    } else {
      // it's a noti

      this.jobStatus =
        NotiUserStateMapping[
          maintenanceResponse.notificationCurrentUserStatusCode
        ] +
        ': ' +
        NotiSYSStateMapping[
          maintenanceResponse.notificationCurrentSystemStatusCode
        ]

      this.StartDateTimeStamp = moment(
        maintenanceResponse.notificationCreatedDate
      ).toDate()
      this.StartDate = GlobalDateFormatter.format(this.StartDateTimeStamp)
    }
    this.identificationDateTimeStamp = moment(
      maintenanceResponse.notificationCreatedDate
    ).toDate()
    this.identificationDate = GlobalDateFormatter.format(
      this.identificationDateTimeStamp
    )
    this.jobPriority = maintenanceResponse.notificationPriorityIndicator
    this.notificationShortText = maintenanceResponse.notificationShortText
    // clean long text
    if (maintenanceResponse.notificationLongText) {
      this.notificationLongText = maintenanceResponse.notificationLongText
        .replace(/\*/gi, '\n') // replace all *
        .replace(/\//gi, '\n') // replace all /
        .replace('\n', '') // this will only replace first \n  which is first line
    }
    this.notificationId = maintenanceResponse.notificationNumber.replace(
      /^0+/,
      ''
    )
    this.workorderId = maintenanceResponse.workOrderNumber

    // set isCurrent based on state
    if (this.isWorkOrder) {
      this.isCurrent =
        this.workorderSysStatus !== 'TECO' &&
        this.workorderSysStatus !== 'CLSD' &&
        maintenanceResponse.notificationCurrentUserStatusCode !== 'NTBC'
    } else {
      this.isCurrent = this.notiSysStatus !== 'NOCO'
    }

    // set isCancelled
    if (
      maintenanceResponse.notificationCurrentUserStatusCode === 'NCAN' ||
      maintenanceResponse.workOrderCurrentUserStatusCode === 'WCAN'
    ) {
      this.isCancelled = true
    }

    // Current Woodside Lifecycle for Jobs for:
    // Noti-USER: NAAP -> APPR -> NTBC
    // Noti-Sys: OSNO -> ORAS -> NOCO
    // WO-USER: WAPP ->WHRA -> APPR -> SCHD -> RTEC
    // WO-SYS: CRTD -> REL -> CNF/PCNF -> TECO ->CLSD
  }
}
export class MaintenanceDataResponse {
  floc: string
  notificationLongText?: string
  notificationShortText: string
  notificationCurrentSystemStatusCode: string
  notificationCurrentSystemStatusSequenceNumber: string
  notificationCurrentUserStatusCode: string
  notificationCurrentUserStatusSequenceNumber: string
  notificationCreatedDate: string
  notificationPriorityIndicator: string
  notificationPriorityTypeCode: string
  notificationNumber: string
  workOrderCreatedDate: string
  workOrderNumber: string
  workOrderText: string
  workOrderTypeText: string
  workOrderMaxConfirmationDate: string
  workOrderBasicEndDate: string
  workOrderBasicStartDate: string
  workOrderCurrentSystemStatusCode: string
  workOrderCurrentUserStatusCode: string
}

export function convertToMaintenanceJob(
  response: MaintenanceDataResponse[],
  lastXMonth: number
): MaintenanceJob[] {
  if (!response) {
    return [] as MaintenanceJob[]
  }
  const maintenanceJobs: MaintenanceJob[] = []
  response.forEach((maintenanceDataResponse) => {
    const notiCreateDate = moment(
      maintenanceDataResponse.notificationCreatedDate
    )
    const today = moment(new Date())
    if (today.diff(notiCreateDate, 'month') <= lastXMonth) {
      maintenanceJobs.push(new MaintenanceJob(maintenanceDataResponse))
    }
  })
  return maintenanceJobs
}

// sample data
export const maintenanceSampleInput1 = {
  closedDate: '10/11/2020',
  floc: 'AU21.1E1441K20',
  jobStatus: 'Notification Approved',
  jobPriority: '2',
  StartDate: '10/11/2020',
  woBasicEndDate: '14/11/2020',
  identifiedBy: 'Tony Stark',
  identificationDate: '10/11/2020',
  notificationId: '20374590',
  notificationShortText: 'Repair fin fan diffusers.',
  notificationLongText:
    '03.09.2015 07:39:06 John Hunt (W34346) \n Belt riding high on bull gear. belt approx 5mm overhung. \n Requires EIC, \n Strap bull gear, remove belt, align bull gear with drive pulley. \n Check condtion of pulleys, \n Change belt and tension \n 06.09.2015 09:56:06 John Hunt (W34346) \n M3 notification raised #11077116 \n 30.09.2015 06:00:56 Jacob Foley (WOPFVG) \n \n Isolated fan. \n \n Removed belt and pulley and replaced with new. \n Realigned bull wheel to drive pulley. \n \n De-isoalted and checked tension with strobe. \n \n Job complete.' +
    '\nBah, Bah, a black Sheep,Have you any wool?Yes old mate i have Three bags full, Two for my master, One for my dame, None for the little boy That cries in the lane',
  workorderId: 'wo1234',
  workorderType: 'PMCO',
} as MaintenanceJob
export const maintenanceSampleInput2 = {
  floc: 'AU21.1E1441K20',
  jobStatus: 'Notification Approved',
  jobPriority: '2',
  StartDate: '10/11/2020',
  woBasicEndDate: '14/11/2020',
  identifiedBy: 'Tony Stark',
  identificationDate: '10/11/2020',
  notificationId: '20374590',
  notificationShortText: 'Repair fin fan diffusers.',
  notificationLongText:
    '03.09.2015 07:39:06 John Hunt (W34346) \n Belt riding high on bull gear. belt approx 5mm overhung. \n Requires EIC, \n Strap bull gear, remove belt, align bull gear with drive pulley. \n Check condtion of pulleys, \n Change belt and tension \n 06.09.2015 09:56:06 John Hunt (W34346) \n M3 notification raised #11077116 \n 30.09.2015 06:00:56 Jacob Foley (WOPFVG) \n \n Isolated fan. \n \n Removed belt and pulley and replaced with new. \n Realigned bull wheel to drive pulley. \n \n De-isoalted and checked tension with strobe. \n \n Job complete.' +
    '\nBah, Bah, a black Sheep,Have you any wool?Yes old mate i have Three bags full, Two for my master, One for my dame, None for the little boy That cries in the lane',
  workorderId: 'wo1234',
  workorderType: 'PMCO',
} as MaintenanceJob
