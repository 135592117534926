import styled from '@emotion/styled'
import { CircularProgress, Box, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React, { useContext } from 'react'
import { LineChart, CartesianGrid, XAxis, YAxis, Line, Legend, Tooltip, ResponsiveContainer } from 'recharts'
import { atom, useRecoilState } from 'recoil'

import { EquipmentOverviewContext, useFlocContext } from '../../../../../../shared/providers/floc-context-provider'
import { LoggingContext } from '../../../../../../shared/providers/spa-logging-provider'
import { TimeSeries } from '../../../../../../shared/types/timeseries'
import { useParamQuery } from '../../../../../../shared/utility'
import PumpRunHourOverView from './overview'
import RunHourLine from './run-hour-line'

const useStyles = makeStyles({
  chartWrapper: {
    position: 'relative',
    alignSelf: 'center',
  },
  chartOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    marginTop: '1rem',
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0.5rem',
    margin: '0.5rem 0',
  },
  timeRangeLabel: {
    color: '#666666',
    marginRight: '1rem',
  },
  modalContainer: {
    position: 'relative',
    display: 'inline-block',
    marginTop: '1rem',
  },
})

type TimeRange = {
  displayLabel: string;
  aggregate: number;
  aggregateUnit: 'DAY' | 'HOUR' | 'MIN';
  timeRangeID: number;
  startTimestamp: number;
  endTimestamp: number;
};

const StyledDiv = styled(Box)`
  padding: ${(props: any) => (props['data-visible'] ? '1.2rem' : '0')};
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  height: ${(props: any) => (props['data-visible'] ? '100%' : '0')};
  flex-direction: column;
  visibility: ${(props: any) => (props['data-visible'] ? 'visible' : 'hidden')};
`

const NoData = styled(Box)`
  opacity: 0.6;
`

const TimeRangeContainer = styled(Box)`
  background: rgba(0, 21, 67, 0.08);
  display: inline-block;
  margin-top: -5px;
  select {
    font-weight: 500;
    font-size: 13px;
    text-align:center;
  }
`

export interface LineStateType {
  isLoading: boolean;
  floc: string;
  data: { response: TimeSeries[] | null; error: any } | null;
  color: string;
}

export const lineDataStateRecoil = atom<LineStateType[]>({
  key: 'floc', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
})

const colorPalette = ['#6B9AFF', '#D71638', '#FF8E50', '#2E7D32', '#9C27B0']
const today = new Date()

const RunHourGraphTab = () => {
  const classes = useStyles()
  const flocContext = useFlocContext()
  const eqOverview = useContext(EquipmentOverviewContext)
  const loggingContext = useContext(LoggingContext)
  const mySearchQuery = useParamQuery()

  const timeRangeCandidates: TimeRange[] = [{
    timeRangeID: 2,
    displayLabel: '1 Year',
    aggregate: 2,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setFullYear(today.getFullYear() - 1) / 1000
    ),
  }, {
    timeRangeID: 3,
    displayLabel: '2 Year',
    aggregate: 4,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setFullYear(today.getFullYear() - 2) / 1000
    ),
  }, {
    timeRangeID: 4,
    displayLabel: '5 Year',
    aggregate: 7,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: Math.floor(
      new Date(today).setFullYear(today.getFullYear() - 5) / 1000
    ),
  }, {
    timeRangeID: 5,
    displayLabel: 'Since Start',
    aggregate: 14,
    aggregateUnit: 'DAY',
    endTimestamp: Math.floor(today.getTime() / 1000),
    startTimestamp: 1325380670, // 20120101
  }]

  const [timeRange, setTimeRange] = React.useState<TimeRange>(
    timeRangeCandidates[0]
  )
  const [surrondingLoading, setSurrondingLoading] = React.useState(false)
  const [lineDataState, setLineDataState] = useRecoilState(lineDataStateRecoil)
  const [chartData, setChartData] = React.useState<any[]>([{
    date: timeRange.startTimestamp,
  }, {
    date: timeRange.endTimestamp,
  }])

  const noData = () => {
    return (
      !lineDataState?.filter((r) => r.isLoading).length && chartData.length < 3
    )
  }

  React.useEffect(() => {
    const currentFloc = mySearchQuery.get('floc')!
    setSurrondingLoading(true)
    flocContext
      .getSurroundingEquipments(currentFloc, eqOverview!.serviceName())
      .then((flocs) => {
        const state: any = []
        flocs.forEach((floc, idx) => {
          state.push({
            isLoading: true,
            floc,
            data: null,
            color: colorPalette[idx % colorPalette.length],
          })
        })
        setLineDataState(state)
      })
      .finally(() => {
        setSurrondingLoading(false)
      })
  }, [flocContext])

  React.useMemo(() => {
    if (
      lineDataState &&
      lineDataState.length &&
      lineDataState.filter((l) => !l.isLoading).length > 0
    ) {
      let graphData: any = []
      // first construct lines
      lineDataState.forEach((line) => {
        if (line.data && line.data.error) {
          loggingContext.log(
            'WARNING',
            `${line.floc}, does not have run hours data with eq360 query start time ${timeRange.startTimestamp}. Error message: ${line.data.error}`
          )
          return
        }
        // good to go
        if (line.data && line.data.response) {
          try {
            const tsData = line.data!.response! as TimeSeries[]
            let sum = 0
            // lines
            graphData = graphData.concat(
              tsData
                .map((x) => {
                  sum += x.value
                  return {
                    date: x.time, //new Date(x.time * 1000).getTime(),
                    [line.floc]: sum,
                  }
                })
            )
            // secondly, merge them if dates are same
            const mergedGraphData: any = []
            graphData.forEach(function (item: any) {
              const existing = mergedGraphData.filter(function (v: any, i: any) {
                return v.date === item.date // if they have same date, can optimise this into same day.
              })
              if (existing.length) {
                const existingIndex = mergedGraphData.indexOf(existing[0])
                mergedGraphData[existingIndex] = {
                  ...mergedGraphData[existingIndex],
                  ...item,
                }
              } else {
                mergedGraphData.push(item)
              }
            })
            // order by date
            setChartData(
              mergedGraphData.sort((a: any, b: any) =>
                moment(a.date).toDate() < moment(b.date).toDate() ? 1 : -1
              )
            )
          } catch (exception) {
            loggingContext.log(
              'ERROR',
              `${line.floc}, error reading run hour responses. Error message: ${exception}`
            )
          }
        }
      })
    }
  }, [lineDataState])

  /**
   * Used by the chart X-axis to format the timestamp into a human readable date
   * @param timestamp
   */
  function formatDate(timestamp: number) {
    const d = new Date(timestamp)
    const ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${da} ${mo} ${ye}`
  }
  return (
    <StyledDiv data-visible={true}>
      {noData() && !surrondingLoading && (
        <NoData>
          It looks like there is currently no run hour information available for
          this equipment. If you think this is incorrect, please contact IT
          support.
        </NoData>
      )}
      {surrondingLoading && <CircularProgress />}
      {lineDataState &&
        lineDataState.map((line, idx) => {
          return (
            <RunHourLine
              key={idx}
              floc={line.floc}
              timebase={timeRange.aggregateUnit}
              interval={timeRange.aggregate}
              startTimestamp={timeRange.startTimestamp}
            />
          )
        })}

      <div hidden={noData() || surrondingLoading}>
        <div className={classes.chartWrapper}>
          {lineDataState && lineDataState.filter((r) => r.isLoading).length && (
            <div className={classes.chartOverlay}>
              <CircularProgress />
            </div>
          )}
          {chartData.length && (
            <ResponsiveContainer width="99%" aspect={5} minWidth="50vw">
              <LineChart
                data={chartData}
                margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
              >
                <CartesianGrid
                  fill="#FCFCFC"
                  vertical={false}
                  strokeDasharray="1 3"
                />
                <XAxis
                  type="number"
                  dataKey="date"
                  tickMargin={15}
                  reversed={false}
                  tickCount={10}
                  minTickGap={30}
                  tick={{ fontSize: 11 }} // dx: 10 if we want to move the tick text to the right
                  tickFormatter={formatDate}
                  domain={[
                    timeRange.startTimestamp * 1000, // start date
                    timeRange.endTimestamp * 1000,
                  ]}
                  axisLine={{ stroke: '#C8C8C8' }}
                />
                <YAxis
                  yAxisId="right"
                  type="number"
                  tick={{ fontSize: 13 }}
                  tickCount={6}
                  orientation="right"
                  domain={['dataMin', 'dataMax']}
                  axisLine={false}
                  tickFormatter={(value: number) => {
                    return Math.ceil(value).toLocaleString('en')
                  }}
                  tickLine={true}
                  scale={'linear'}
                />
                <YAxis
                  yAxisId="left"
                  tick={false}
                  orientation="left"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    dy: 60,
                    offset: 40,
                    value: 'Cumulative Run Hours',
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 13,
                  }}
                />
                <Tooltip
                  labelFormatter={(val: number) => formatDate(val)}
                  formatter={(val: number) => val}
                />
                {lineDataState &&
                  lineDataState.map((line, idx) => {
                    if (!line.isLoading && line.data) {
                      return (
                        <Line
                          key={idx}
                          isAnimationActive={false}
                          name={line.floc}
                          unit=""
                          yAxisId="right"
                          type="linear"
                          dot={false}
                          dataKey={line.floc}
                          stroke={line.color}
                          strokeWidth={2}
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                <Legend
                  wrapperStyle={{
                    paddingTop: '18px',
                    position: 'relative'
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
        <Box className={classes.controlsContainer}>
        <label className={classes.timeRangeLabel}>Time Range:</label>
          <TimeRangeContainer>
            <Select
              style={{ width: '6rem' }}
              native
              defaultValue={timeRangeCandidates[0].timeRangeID}
              onChange={(e) => {
                const selected = parseInt(e.target.value as string)
                const timeRange = timeRangeCandidates.find(
                  (x) => x.timeRangeID === selected
                )!
                setTimeRange(timeRange)
                setLineDataState(
                  lineDataState?.map((d) => ({ ...d, isLoading: true }))
                )
                e.preventDefault()
              }}
              variant="standard"
            >
              {timeRangeCandidates.map((x) => (
                <option value={x.timeRangeID} key={x.timeRangeID}>
                  {x.displayLabel}
                </option>
              ))}
            </Select>
          </TimeRangeContainer>
        </Box>
        <PumpRunHourOverView />
      </div>
    </StyledDiv>
  )
}

export default RunHourGraphTab
