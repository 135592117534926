export type SortDir = 'asc' | 'desc';

export enum SortKeyEnum {
  DATE = 'date',
  FLOC = 'floc',
  TYPE = 'alertType',
  SEVERITY = 'severity',
}

export interface SortableEntity {
  getSortableField(sortKey: SortKeyEnum): any;
}
