import styled from '@emotion/styled'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { useRecoilState } from 'recoil'

import { lineDataStateRecoil } from './run-hour-main'
import { calRunHourPumpOverviewData, RunHourPumpOverviewData } from './overviewHelpers'

export interface PumpRunHourOverViewTabProps {}

const Root = styled(Box)`
  padding: 0 1.2rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  width: 100%;
  flex-direction: column;
`

const ColorBox = styled(Box)`
  background-color: ${(props) => props.color};
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  margin-right: 0.5rem;
`

const NoData = styled(Box)`
  opacity: 0.6;
`

const TableCellHead = styled(TableCell)`
  padding: 2rem 0;
`

const PumpRunHourOverView = (props: PumpRunHourOverViewTabProps) => {
  const [lineDataState, setLineDataState] = useRecoilState(lineDataStateRecoil)
  const [runHourOverviewData, setRunHourOverviewData] =
    React.useState<RunHourPumpOverviewData[]>()

  const isLoaded = () => {
    return Boolean(!lineDataState?.filter((r) => r.isLoading).length)
  }
  const noData = () => {
    return (
      isLoaded() &&
      (!runHourOverviewData || !runHourOverviewData?.length)
    )
  }
  React.useEffect(() => {
    if (isLoaded()) {
      setRunHourOverviewData(calRunHourPumpOverviewData(lineDataState))
    }
  }, [lineDataState])
  return (
    <Root hidden={!isLoaded() && !runHourOverviewData?.length}>
      {noData() ? (
        <NoData>
          It looks like there is currently no contextual run hours information
          available for this equipment. If you think this is incorrect, please
          contact IT support.
        </NoData>
      ) : (
        <>
          <Table aria-label="floc select">
            <TableHead>
              <TableRow>
                <TableCellHead>FLOC</TableCellHead>
                <TableCellHead>Actual Utilisation Split</TableCellHead>
                <TableCellHead>Cumulative Run Hours</TableCellHead>
                <TableCellHead>Year-to-date Run Hours</TableCellHead>
                <TableCellHead>Last Online</TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {runHourOverviewData?.map((row) => {
                return (
                  <TableRow key={row.floc}>
                    <TableCell style={{ display: 'flex' }}>
                      <ColorBox color={row.color} />
                      {row.floc}
                    </TableCell>
                    <TableCell>
                      {(row.actualUtilisationSplit * 100).toFixed(0)}%
                    </TableCell>
                    <TableCell>
                      {parseFloat(
                        row.cumulativeRunHours.toFixed(0)
                      ).toLocaleString('en')}{' '}
                      hours
                    </TableCell>
                    <TableCell>
                      {parseFloat(
                        row.yearToDayRunHour.toFixed(0)
                      ).toLocaleString('en')}{' '}
                      hours
                    </TableCell>
                    <TableCell>
                      {row.timeOfflineSinceLastActive ?? (
                        <i style={{ opacity: 0.7 }}>Equipment is Online</i>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </>
      )}
    </Root>
  )
}

export default PumpRunHourOverView
